import { createDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'

const notificationForCreatedAgreement = async (
  usersForSendNotification,
  userWhichCreatedAgreement,
  buyingCaseData
) => {
  for (const user of usersForSendNotification) {
    if (user.id !== userWhichCreatedAgreement.id) {
      const bodyForNotification = {
        userId: user.id,
        text: `${userWhichCreatedAgreement.firstName} ${userWhichCreatedAgreement.secondName} created agreement in ${buyingCaseData.name} buying case`,
        senderAvatarURL: ' ',
        title: 'Created agreement'
      }
      await createDocument(COLLECTIONS.NOTIFICATIONS, bodyForNotification)
    }
  }
}
export default notificationForCreatedAgreement
