import {
  BuyingCaseShow,
  BuyingCasesAll,
  BuyingCaseInviteAttendeesEdit,
  BuyingCaseEdit,
  BuyingCaseShowBuyerAnswers
} from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'BuyingCasesAll',
    path: ROUTE_PATHS.BUYING_CASES_ALL,
    exact: true,
    component: BuyingCasesAll
  },
  {
    name: 'BuyingCaseInviteAttendeesEdit',
    path: ROUTE_PATHS.BUYING_CASE_INVITE_ATTENDEES_EDIT,
    exact: true,
    component: BuyingCaseInviteAttendeesEdit
  },
  {
    name: 'BuyingCaseEdit',
    path: ROUTE_PATHS.BUYING_CASE_EDIT,
    exact: true,
    component: BuyingCaseEdit
  },
  {
    name: 'BuyingCaseShow',
    path: ROUTE_PATHS.BUYING_CASE_SHOW,
    exact: true,
    component: BuyingCaseShow
  },
  {
    name: 'BuyingCaseShowBuyerAnswers',
    path: ROUTE_PATHS.BUYING_CASE_SHOW_BUYER_ANSWERS,
    exact: true,
    component: BuyingCaseShowBuyerAnswers
  }
]
