import React from 'react'
import { Empty } from 'antd'
import { Row, Col, PageWrapper } from '@qonsoll/react-design'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useHomeButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { BuyingCaseList } from 'domains/BuyingCase/components'
import { COLLECTIONS } from '~/app/constants'
import { useBuyingCaseContext } from '~/app/contexts/Cache/hooks'
import useCache from '~/hooks/cache/useCache'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'
import _ from 'lodash'

function BuyingCasesAll() {
  // [ADDITIONAL HOOKS]
  useHomeButton()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const { state, dispatch } = useBuyingCaseContext()
  useCache(
    getCollectionRef(COLLECTIONS.BUYING_CASES).orderBy('id', 'asc').limit(10),
    state,
    dispatch
  )

  // [CLEAN FUNCTIONS]

  // TODO add search mechanism for cached list
  // !!!
  // const onBuyingCaseSearch = (event) => {
  //   const filteredBuyingCases = collectionData.filter((item) =>
  //     item.name.toLowerCase().startsWith(event.target.value.toLowerCase())
  //   )
  //   setBuyingCasesData(filteredBuyingCases)
  // }

  return (
    <PageWrapper
      headingProps={{
        title: t('Buying cases'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      isBottomSticky>
      {!_.isEmpty(state?.cachedCollectionData) ? (
        <Row noGutters>
          {/* <Col mb="24px">
              <Input
                placeholder={t('Enter name here')}
                allowClear
                onChange={onBuyingCaseSearch}
              />
            </Col> */}
          <Col cw={12}>
            <InfiniteCachedList
              limit={15}
              collectionName={COLLECTIONS.BUYING_CASES}
              cache={state}
              setCache={dispatch}
              order={{ field: 'id', type: 'asc' }}>
              {(buyingCases) => <BuyingCaseList data={buyingCases} />}
            </InfiniteCachedList>
          </Col>
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

BuyingCasesAll.propTypes = {}

export default BuyingCasesAll
