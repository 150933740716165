import { useReducer, useEffect, useState, useCallback } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { message } from 'antd'
import { writeLog } from 'helpers'
import {
  getCollectionRef,
  getTimestamp,
  updateDocument
} from 'services/Firebase/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import auth from 'services/Firebase/auth'
import SessionContext from '../../context'
import SessionReducer from '../../SessionReducer'
import { COLLECTIONS } from 'app/constants'

const LOGOUT_FLAG = `${process.env.REACT_APP_APPLICATION_NAME}_logout`
const FIRST_LOGIN_FLAG = `${process.env.REACT_APP_APPLICATION_NAME}_firstLogin`

const SessionProvider = (props) => {
  const { children, ...rest } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [user] = useAuthState(auth)
  const [userData, userDataLoading] = useDocumentData(
    user && getCollectionRef(COLLECTIONS.USERS).doc(user.uid)
  )

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(SessionReducer, null)
  const [sessionLoading, setSessionLoading] = useState(true)

  // [CLEAR_FUNCTIONS]
  const activateUser = useCallback(async () => {
    await updateDocument(COLLECTIONS.USERS, userData.id, {
      isActivated: true
    })
    await writeLog(
      `{trigerrer} accepted invitation to the app`,
      {
        trigerrer: userData.id,
        date: getTimestamp().fromDate(new Date())
      },
      COLLECTIONS.ACTIVITIES
    )
  }, [userData])

  const addDevice = useCallback(async () => {
    const getUserDevices = await getCollectionRef(COLLECTIONS.USER_DEVICES)
      .doc(userData.id)
      .get()
    if (getUserDevices.data()) {
      const userDevicesData = getUserDevices.data()
      const currentDeviceId = localStorage.getItem('DeviceID')
      if (
        currentDeviceId &&
        !userDevicesData?.deviceIds.includes(currentDeviceId)
      ) {
        const arrayWithNewDevice = [
          ...userDevicesData.deviceIds,
          currentDeviceId
        ]
        await updateDocument(
          COLLECTIONS.USER_DEVICES,
          userData.id,
          {
            deviceIds: arrayWithNewDevice
          },
          { merge: true }
        )
      }
    } else {
      const currentDeviceId = localStorage.getItem('DeviceID')
      if (currentDeviceId) {
        await updateDocument(COLLECTIONS.USER_DEVICES, userData.id, {
          id: userData.id,
          deviceIds: [currentDeviceId]
        })
      }
    }
  }, [userData])

  // [USE_EFFECTS]
  useEffect(() => {
    const isFirstLogin = !JSON.parse(localStorage.getItem(FIRST_LOGIN_FLAG))
    const isLogout = JSON.parse(localStorage.getItem(LOGOUT_FLAG))

    if (user && userData && userData?.role !== 'ADMIN') {
      auth.signOut()
      message.error(
        t('Wrong credential or missing access rights to application')
      )
      return
    } else if (!user) {
      localStorage.setItem(LOGOUT_FLAG, 'true')
    }
    if (isFirstLogin) {
      localStorage.setItem(LOGOUT_FLAG, 'true')
      localStorage.setItem(FIRST_LOGIN_FLAG, 'true')
    }
    if (isLogout) {
      setSessionLoading(false)
    }
    if (!userDataLoading && state) {
      setSessionLoading(false)
      localStorage.setItem(LOGOUT_FLAG, 'false')
    }
    if (!userDataLoading) {
      dispatch({
        type: 'SET_DATA',
        data: userData
      })
    }
    userData && addDevice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, state, userDataLoading])

  useEffect(() => {
    if (userData && !userData.isActivated) {
      activateUser()
    }
  }, [userData, activateUser])

  return (
    <SessionContext.Provider
      value={{
        state,
        dispatch,
        sessionLoading: sessionLoading || userDataLoading
      }}
      {...rest}>
      {children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
