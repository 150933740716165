import { List, Empty } from 'antd'
import { useHistory } from 'react-router-dom'
import { Button, Col, PageWrapper, Row } from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { getCollectionRef } from 'services/Firebase/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { COLLECTIONS } from 'app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { BuyingCaseTypeListItem } from '../../components'
import { useState } from 'react'
import useBuyingTypesContext from '~/app/contexts/Cache/hooks/useBuyingTypesContext'
import useCache from '~/hooks/cache/useCache'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'
import _ from 'lodash'
import { PageLoading } from '~/app/components'

function BuyingCaseTypeAll() {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const { state, dispatch } = useBuyingTypesContext()
  useCache(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
      .orderBy('id', 'asc')
      .limit(20),
    state,
    dispatch
  )

  // [CLEAN_FUNCTIONS]
  const goToBuyingCaseTypeCreate = () => {
    history.push(ROUTE_PATHS.BUYING_CASE_TYPE_CREATE)
  }

  return (
    <PageLoading loading={loading} loadingText={t('Deleting buying case type')}>
      <PageWrapper
        headingProps={{
          title: t('Buying case types'),
          titleSize: titleSizes,
          marginBottom: 24
        }}
        onBack={goBack}
        action={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={goToBuyingCaseTypeCreate}
          />
        }>
        {!_.isEmpty(state?.cachedCollectionData) ? (
          <InfiniteCachedList
            limit={15}
            collectionName={COLLECTIONS.BUYING_CASE_TYPES}
            cache={state}
            setCache={dispatch}
            order={{ field: 'id', type: 'asc' }}>
            {(buyingCaseTypes) => (
              <List
                dataSource={buyingCaseTypes}
                renderItem={(item) => (
                  <BuyingCaseTypeListItem {...item} setLoading={setLoading} />
                )}
              />
            )}
          </InfiniteCachedList>
        ) : (
          <Row height="100%">
            <Col v="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          </Row>
        )}
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseTypeAll
