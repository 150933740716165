import {
  PartnerCreate,
  PartnerProfile,
  PartnersAll,
  PartnerEdit
} from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'PartnerCreate',
    path: ROUTE_PATHS.PARTNER_CREATE,
    exact: true,
    component: PartnerCreate
  },
  {
    name: 'PartnerEdit',
    path: ROUTE_PATHS.PARTNER_EDIT,
    exact: true,
    component: PartnerEdit
  },
  {
    name: 'PartnerProfile',
    path: ROUTE_PATHS.PARTNER_PROFILE,
    exact: true,
    component: PartnerProfile
  },
  {
    name: 'PartnersAll',
    path: ROUTE_PATHS.PARTNERS_ALL,
    exact: true,
    component: PartnersAll
  }
]
