import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { createDocument, getCollectionRef } from '~/services/Firebase/firestore'
import { PartnerForm } from 'domains/Partner/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { createContactPerson } from 'domains/Partner/helpers'
import React, { useCallback, useEffect, useState } from 'react'
import { PageLoading } from '~/app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useCollectionData } from '~/hooks/firestore'
import { deleteURL } from '~/services/Firebase/storage'

function PartnerCreate() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [offersTypes, loadingOffersType] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFER_TYPES)
  )
  // [COMPONENT STATE HOOKS]
  const [loading, setLoading] = useState(false)
  const [diffImage, setDiffImage] = useState([''])

  // [CLEAN_FUNCTIONS]
  const onPartnerCreate = async (values, type) => {
    const { logoURL, partnerName, partnerDescription, contactPeople } = values
    setLoading(true)
    try {
      //pop the last url for not remove from storage last upload image
      setDiffImage(...diffImage?.pop())
      const partner = await createDocument(COLLECTIONS.PARTNERS, {
        logoURL,
        partnerName,
        partnerDescription,
        offerTypeId: type,
        isActivated: false
      })
      if (contactPeople?.length > 0) {
        for (let item of contactPeople) {
          await createContactPerson(item, partner.id, t)
        }
      }
      message.success(t('Successfully created company and invited contacts'))
      history.push(ROUTE_PATHS.PARTNERS_ALL)
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during partner creation'))
    }
    setLoading(false)
  }

  const checkIfUserSaveNewAvatar = useCallback(async () => {
    if (Array.isArray(diffImage)) {
      for (const image of diffImage) {
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })
      }
    }
  }, [diffImage])

  const goBack = useBackButton({
    returnCallback: backButtonVisibility,
    callback: checkIfUserSaveNewAvatar
  })

  // [USE_EFFECTS]
  useEffect(() => {
    return () => {
      checkIfUserSaveNewAvatar()
    }
  }, [diffImage])

  return (
    <PageLoading loading={loading || loadingOffersType}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Invite partner'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <PartnerForm
                diffImage={diffImage}
                setDiffImage={setDiffImage}
                onFinish={onPartnerCreate}
                offersTypes={offersTypes}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default PartnerCreate
