import React from 'react'
import { List } from 'antd'

import { UserAdvancedView } from 'domains/User/components'

function UserList(props) {
  const { data } = props

  return (
    <List
      dataSource={data}
      renderItem={(item, index) => (
        <UserAdvancedView
          withCall
          phone={item?.phone}
          name={`${item.firstName} ${item.secondName}`}
          avatarURL={item?.avatarURL}
          id={item.id}
          key={index}
        />
      )}
      style={{ width: 'inherit' }}
    />
  )
}

export default UserList
