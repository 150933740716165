import { UserShow, UserEdit } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'UserShow',
    path: ROUTE_PATHS.USER_SHOW,
    exact: true,
    component: UserShow
  },
  {
    name: 'UserEdit',
    path: ROUTE_PATHS.USER_EDIT,
    exact: true,
    component: UserEdit
  }
]
