import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Row, Col, Card, Text } from '@qonsoll/react-design'

function AgreementList(props) {
  const { data } = props

  return (
    <List
      size="small"
      dataSource={data}
      renderItem={(item, index) => (
        <Card
          key={index}
          style={{ cursor: 'pointer' }}
          mb={3}
          bordered={false}
          shadowless>
          <Row noGutters h="center" v="center">
            <Col minWidth={0}>
              <Text isEllipsis>{item.name}</Text>
              {/** change to correct field name of buyer name field */}
            </Col>
            <Col cw="auto" ml={3}>
              <RightOutlined
                style={{
                  fontSize: 'var(--ql-font-size-h3)',
                  color: 'var(--text-color-secondary)'
                }}
              />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

AgreementList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}

export default AgreementList
