import { useHistory } from 'react-router-dom'
import { Row, Col, PageWrapper, Button } from '@qonsoll/react-design'
import { Empty } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { PartnerList } from 'domains/Partner/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { getCollectionRef } from 'services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'
import { useTranslations } from 'app/contexts/Translation/hooks'
import usePartnersContext from '~/app/contexts/Cache/hooks/usePartnersContext'
import useCache from '~/hooks/cache/useCache'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'
import _ from 'lodash'

function PartnersAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const { state, dispatch } = usePartnersContext()
  useCache(
    getCollectionRef(COLLECTIONS.PARTNERS).orderBy('id', 'asc').limit(20),
    state,
    dispatch
  )

  // [CLEAN FUNCTIONS]
  const onAddPartner = () => {
    history.push(ROUTE_PATHS.PARTNER_CREATE)
  }

  // TODO add search mechanism for cached list
  // !!!
  // const onPartnerSearch = (event) => {
  //   const filteredPartners = collectionData.filter((item) =>
  //     item.partnerName
  //       .toLowerCase()
  //       .startsWith(event.target.value.toLowerCase())
  //   )
  //   setPartnersData(filteredPartners)
  // }

  return (
    <PageWrapper
      headingProps={{
        title: t('Partners'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}
      action={
        <Button type="primary" icon={<PlusOutlined />} onClick={onAddPartner} />
      }>
      {!_.isEmpty(state?.cachedCollectionData) ? (
        <Row noGutters>
          {/* <Col mb="24px">
            <Input
              ref={searchInput}
              placeholder={t('Enter name here')}
              allowClear
              onChange={onPartnerSearch}
            />
          </Col> */}
          <Col cw={12}>
            <InfiniteCachedList
              limit={15}
              collectionName={COLLECTIONS.PARTNERS}
              cache={state}
              setCache={dispatch}
              order={{ field: 'id', type: 'asc' }}>
              {(partners) => <PartnerList data={partners} />}
            </InfiniteCachedList>
          </Col>
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default PartnersAll
