import PropTypes from 'prop-types'
import { Remove as RemoveButton } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

const Remove = (props) => {
  const { tooltip, ...rest } = props
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const showTooltip = useResponsiveBreakpoints({
    sm: false,
    md: false,
    lg: true,
    xl: true,
    xxl: true
  })
  // [COMPUTED_PROPERTIES]
  const translatedCancelLabel = t('No, keep')
  const translatedConfirmLabel = t('Yes, remove')
  const translatedQuestion = t('Are you sure you want to remove')
  const translatedItemName = t('it')
  const translatedTooltip = t('Remove' || tooltip)
  return (
    <RemoveButton
      cancelLabel={translatedCancelLabel}
      confirmLabel={translatedConfirmLabel}
      question={translatedQuestion}
      itemName={translatedItemName}
      tooltip={showTooltip && translatedTooltip}
      {...rest}
    />
  )
}

Remove.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'text',
    'link',
    'dashed',
    'ghost'
  ]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  iconLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  iconRight: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  shape: PropTypes.oneOf(['default', 'circle', 'round']),
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'leftTop',
    'leftBottom',
    'rightTop',
    'rightBottom'
  ]),
  question: PropTypes.string,
  onSubmit: PropTypes.func,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  popconfirmPlacement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'leftTop',
    'leftBottom',
    'rightTop',
    'rightBottom'
  ]),
  itemName: PropTypes.string
}

export default Remove
