import _ from 'lodash'

const CacheReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA': {
      return { ...state, cachedCollectionData: action.payload }
    }
    case 'ADD_DOC': {
      if (!state?.cachedCollectionData?.[action.payload.id]) {
        state.cachedCollectionData[action.payload.id] = action.payload
      }
      return {
        ...state,
        cachedCollectionData: { ...state.cachedCollectionData }
      }
    }
    case 'MODIFY_DOC': {
      if (state?.cachedCollectionData?.[action.payload.id]) {
        state.cachedCollectionData[action.payload.id] = action.payload
      }
      return {
        ...state,
        cachedCollectionData: { ...state.cachedCollectionData }
      }
    }
    case 'REMOVE_DOC': {
      if (state?.cachedCollectionData?.[action.payload]) {
        const withDeletedElem = _.omit(state?.cachedCollectionData, [
          action.payload
        ])
        return {
          ...state,
          cachedCollectionData: withDeletedElem
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}

export default CacheReducer
