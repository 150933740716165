import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import {
  deleteDocument,
  getCollectionRef,
  updateDocument
} from '~/services/Firebase/firestore'
import { PageLoading, Remove } from '~/app/components'
import { OfferTypeForm } from 'domains/OfferType/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'
import firebase from '~/services/Firebase/init'
import { useOfferTypesContext } from '~/app/contexts/Cache/hooks'

function OfferTypeCreate() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const history = useHistory()
  const { id } = useParams()
  const [offerTypeData, offerTypeDataLoading] = useDocumentDataOnce(
    getCollectionRef(COLLECTIONS.OFFER_TYPES).doc(id)
  )
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const { dispatch } = useOfferTypesContext()

  // [COMPONENT STATE HOOKS]
  const [submitLoading, setSubmitLoading] = useState(false)

  // [CLEAN FUNCTIONS]
  const onFinish = async (values) => {
    setSubmitLoading(true)
    try {
      await updateDocument(COLLECTIONS.OFFER_TYPES, id, values)
      message.success(t('Offer type was edited successfully'))
      history.push(ROUTE_PATHS.OFFER_TYPE_ALL)
    } catch (error) {
      message.error(t('Error occurred during offer type edit'))
      console.log(error.message)
    }
    setSubmitLoading(false)
  }

  const onCancel = () => {
    history.goBack()
  }

  const onRemove = async () => {
    setSubmitLoading(true)
    try {
      const functionSetNullToOfferTypeIdFiled = firebase
        .functions()
        .httpsCallable('setNullToOfferTypeIdField', { timeout: 0 })

      const functionSendNotificationToPartners = firebase
        .functions()
        .httpsCallable('createNotificationToAllPartnersWithChangedOfferType', {
          timeout: 0
        })

      //send notification for all users which are connected to partner where offerTypeId === removed id
      await functionSendNotificationToPartners({ id })

      //set null to offerTypeId field in offers and partners collections, where offerTypeId === removed id
      await functionSetNullToOfferTypeIdFiled({ id })

      //delete offerType from DB
      await deleteDocument(COLLECTIONS.OFFER_TYPES, id)

      message.success(t('Offer type was deleted successfully'))
      history.push(ROUTE_PATHS.OFFER_TYPE_ALL)
      dispatch({ type: 'REMOVE_DOC', payload: id })
    } catch (error) {
      message.error(t("Offer type wasn't deleted"))
    }
    setSubmitLoading(false)
  }

  return (
    <PageLoading loading={offerTypeDataLoading || submitLoading}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Edit offer type'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        action={[
          <Remove
            type="text"
            onSubmit={onRemove}
            itemName={t('offer type')}
            icon={<DeleteOutlined />}
          />
        ]}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <OfferTypeForm
                initialValues={offerTypeData}
                onFinish={onFinish}
                edit
                onCancel={onCancel}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default OfferTypeCreate
