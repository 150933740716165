import { useContext } from 'react'
import SessionContext from '../context'

const useSessionContext = () => {
  const context = useContext(SessionContext)

  if (context === undefined) {
    throw new Error(
      'useSessionContext must be used within a useSessionContext.Provider'
    )
  }

  return context
}

export default useSessionContext
