import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  RedoOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined
} from '@ant-design/icons'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Box, Button } from '@qonsoll/react-design'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const TOOLBAR_STYLES = {
  padding: 'var( --padding-xs) var( --padding-sm)',
  display: 'flex',
  flexWrap: 'wrap',
  border: 'none',
  borderRadius:
    'var(--ql-border-radius-default) var(--ql-border-radius-default) 0 0 ',
  borderBottom:
    'var(--ql-border-width-default) var(--ql-border-style-default) var(--dark-transparent-6)',
  background: 'var(--white-transparent-3)'
}

const INLINE_OPTIONS_CONFIG = {
  bold: <BoldOutlined />,
  italic: <ItalicOutlined />,
  underline: <UnderlineOutlined />,
  strikethrough: <StrikethroughOutlined />
}
const TEXT_ALIGN_OPTIONS_CONFIG = {
  left: <AlignLeftOutlined />,
  center: <AlignCenterOutlined />,
  right: <AlignRightOutlined />
}
const HISTORY_OPTIONS_CONFIG = {
  undo: <UndoOutlined />,
  redo: <RedoOutlined />
}

const TextEditInlineBlock = (props) => {
  const { config, onChange, currentState } = props
  return (
    <Box mx={-1} pr={2}>
      {config.options.map((option) => (
        <Button
          mx={1}
          size="small"
          type={currentState[option] ? 'primary' : 'text'}
          onClick={() => onChange(option, !currentState[option])}
          icon={INLINE_OPTIONS_CONFIG[option]}
        />
      ))}
    </Box>
  )
}
const TextEditTextAlignBlock = (props) => {
  const { config, onChange, currentState } = props
  return (
    <Box mx={-1} px={2}>
      {config.options.map((option) => (
        <Button
          mx={1}
          size="small"
          type={currentState[option] ? 'primary' : 'text'}
          onClick={() => onChange(option)}
          icon={TEXT_ALIGN_OPTIONS_CONFIG[option]}
        />
      ))}
    </Box>
  )
}
const TextEditHistoryBlock = (props) => {
  const { config, onChange, currentState } = props
  return (
    <Box mx={-1} pl={2}>
      {config.options.map((option) => (
        <Button
          disabled={currentState[`${option}Disabled`]}
          mx={1}
          size="small"
          type={currentState[option] ? 'primary' : 'text'}
          onClick={() => onChange(option)}
          icon={HISTORY_OPTIONS_CONFIG[option]}
        />
      ))}
    </Box>
  )
}
const TextEditBlockTypeSelect = (props) => {
  const { config, onChange, currentState } = props
  // console.log(props)

  const value = config.options.find(
    (option) => option === currentState.blockType
  )
  return (
    <Box px={2}>
      <Select
        value={value}
        size="small"
        options={config.options.map((option) => ({
          label: option,
          value: option
        }))}
        style={{ minWidth: '120px' }}
        onChange={onChange}
      />
    </Box>
  )
}

const TextEditor = (props) => {
  const { wrapperProps, onChange, state } = props

  // [COMPONENT_STATE_HOOKS]
  const [editorState, setEditorState] = useState(
    () => state ?? EditorState.createWithText('')
  )

  // [USE_EFFECTS]
  useEffect(() => {
    onChange?.(editorState)
  }, [editorState])

  return (
    <Box
      borderRadius="var(--ql-border-radius-16)"
      bg="var(--white-transparent-5)"
      width="100%"
      height="inherit"
      flex={1}
      {...wrapperProps}>
      <Editor
        stripPastedStyles
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={setEditorState}
        toolbarStyle={TOOLBAR_STYLES}
        toolbar={{
          options: ['inline', 'blockType', 'textAlign', 'history'],
          inline: {
            component: TextEditInlineBlock,
            options: Object.keys(INLINE_OPTIONS_CONFIG)
          },
          blockType: {
            component: TextEditBlockTypeSelect
          },
          textAlign: {
            component: TextEditTextAlignBlock,
            options: Object.keys(TEXT_ALIGN_OPTIONS_CONFIG)
          },
          history: {
            component: TextEditHistoryBlock,
            options: Object.keys(HISTORY_OPTIONS_CONFIG)
          }
        }}
      />
    </Box>
  )
}

TextEditor.propTypes = {
  wrapperProps: PropTypes.object,
  onChange: PropTypes.func,
  state: PropTypes.object
}

export default TextEditor
