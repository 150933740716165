import { useState, useEffect } from 'react'
import _ from 'lodash'

const useCache = (collection, cache, setCache) => {
  // [COMPONENT STATE HOOKS]
  const [data, setData] = useState([])

  // [ADDITIONAL_HOOKS]
  useEffect(() => {
    const unsubscribe = collection.onSnapshot((snapshot) => {
      if (_.isEmpty(cache?.cachedCollectionData)) {
        // loading for first time data fetching into cache
        let cachedObj = {}
        snapshot.forEach((item) => {
          cachedObj[item.id] = item.data()
        })
        setCache({ type: 'SET_DATA', payload: cachedObj })
      } else {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            setCache({ type: 'ADD_DOC', payload: change.doc.data() })
          }
          if (change.type === 'modified') {
            setCache({
              type: 'MODIFY_DOC',
              payload: change.doc.data()
            })
          }
          if (change.type === 'removed') {
            console.log('removed')
            setCache({
              type: 'REMOVE_DOC',
              payload: change.doc.id
            })
          }
        })
      }
    })

    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    !_.isEmpty(cache?.cachedCollectionData) &&
      setData(Object.values(cache?.cachedCollectionData))
  }, [cache?.cachedCollectionData])

  return [data, setData]
}

export default useCache
