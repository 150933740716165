import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Input, Button, PhoneInput, Row, Col } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'

const AdministratorForm = (props) => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}
      {...props}>
      <Row negativeBlockMargin>
        <Col cw={[12, 6]} display="block">
          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[
              { required: true, message: t('Please, input first name') },
              {
                max: 75,
                message: t('First name should be less than 75 character')
              }
            ]}
            style={{ width: '100%' }}>
            <Input placeholder={t('First name')} />
          </Form.Item>
        </Col>
        <Col cw={[12, 6]} display="block">
          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[
              { required: true, message: t('Please, input last name') },
              {
                max: 75,
                message: t('Last name should be less than 75 character')
              }
            ]}
            style={{ width: '100%' }}>
            <Input placeholder={t('Last name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row negativeBlockMargin>
        <Col cw={[12, 6]} display="block">
          <Form.Item
            label={t('Phone')}
            name="phone"
            rules={[
              {
                required: true,
                message: t('Please input phone number')
              }
            ]}>
            <PhoneInput country="no" />
          </Form.Item>
        </Col>
        <Col cw={[12, 6]} display="block">
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[{ required: true, message: t('Please input email') }]}>
            <Input placeholder={t('Email')} />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" block htmlType="submit" mt="auto">
        {t('Invite')}
      </Button>
    </Form>
  )
}

AdministratorForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default AdministratorForm
