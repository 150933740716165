const COLLECTIONS = {
  FORMS: 'forms',
  QUESTIONS: 'questions',
  MEDIA: 'media',
  ANSWERS: 'answers',
  USER_ANSWERS_GROUP: 'userAnswersGroup',
  ANSWERS_SCORES_CONDITIONS: 'answersScoreConditions'
}

export default COLLECTIONS
