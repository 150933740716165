import { useReducer } from 'react'
import { BuyingTypesContext } from '../../contexts'
import CacheReducer from '../../reducer'

function BuyingTypesProvider(props) {
  const { children, ...rest } = props

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(CacheReducer, {})

  return (
    <BuyingTypesContext.Provider value={{ state, dispatch }} {...rest}>
      {children}
    </BuyingTypesContext.Provider>
  )
}

export default BuyingTypesProvider
