import React from 'react'
import PropTypes from 'prop-types'
import { OfferView } from 'domains/Offer/components'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { Col, Row } from '@qonsoll/react-design'
import { Empty } from 'antd'

function OffersList(props) {
  const { data } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [CLEAN_FUNCTIONS]
  const onItemClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.OFFER_SHOW, { id }))

  return (
    <>
      {data?.length ? (
        <Row negativeBlockMargin>
          {data?.map((item) => (
            <Col cw={[12, 5, 4, 3]}>
              <OfferView {...item} onCardClick={() => onItemClick(item.id)} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </>
  )
}

OffersList.propTypes = { data: PropTypes.array }

export default OffersList
