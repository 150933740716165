import _ from 'lodash'

const differenceTypes = (buyingCaseTypes, buyingTypesFromForm = []) => {
  let difference = []
  buyingTypesFromForm.forEach((buyingType) => {
    for (const item of buyingCaseTypes) {
      if (item.id === buyingType.buyingTypeId) difference.push(item)
    }
  })
  return _.differenceBy(buyingCaseTypes, difference, 'id')
}
export default differenceTypes
