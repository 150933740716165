import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Form, message } from 'antd'
import { Container, Row, Col, PageWrapper } from '@qonsoll/react-design'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import {
  getCollectionRef,
  getDocumentData,
  updateDocument
} from '~/services/Firebase/firestore'
import { PageLoading } from '~/app/components'
import { OfferForm } from 'domains/Offer/components'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useCollectionData } from '~/hooks/firestore'
import firebase from '~/services/Firebase'
import _ from 'lodash'
import { deleteURL } from '~/services/Firebase/storage'

function OfferEdit() {
  // [COMPONENT STATE HOOKS]
  const [loading, setLoading] = useState(true)
  const [offerType, setOfferType] = useState({})
  const [offer, setOffer] = useState({})

  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const { id } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [offersTypes, loadingOffersType] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFER_TYPES)
  )
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN FUNCTIONS]
  const onRemoveForResized = async (file, index) => {
    const removedImages_500 = offer.images_500.splice(index, 1)
    const removedImages_1000 = offer.images_1000.splice(index, 1)
    await updateDocument(
      COLLECTIONS.OFFERS,
      id,
      {
        images_500: offer.images_500,
        images_1000: offer.images_1000
      },
      { merge: true }
    )
    for (const image of removedImages_500) await deleteURL(image)
    for (const image of removedImages_1000) await deleteURL(image)
  }
  const onOfferEdit = async (values, type) => {
    setLoading(true)
    const images_500 = []
    const images_1000 = []
    const inputSize = '500x500,1000x1000'

    const func = await firebase
      .functions()
      .httpsCallable('resizeImages', { timeout: 0 })

    const images = values?.images?.map((item) => item.url)
    const differenceImages = _.difference(images, offer?.images)

    if (differenceImages?.length > 0) {
      for (const image of differenceImages) {
        const responseData = await func({
          url: image,
          fileName: image,
          inputSize: inputSize
        })
        const arrayURL = responseData.data?.arrayURL
        images_500.push(arrayURL[0])
        images_1000.push(arrayURL[1])
      }
    }

    try {
      await updateDocument(
        COLLECTIONS.OFFERS,
        id,
        {
          id,
          ...values,
          description: values?.description?.trim(),
          images,
          images_500: [...offer?.images_500, ...images_500],
          images_1000: [...offer?.images_1000, ...images_1000],
          phone:
            offer?.phone === values.phone ? offer?.phone : `+${values?.phone}`,
          offerTypeId: type?.id,
          partnerId: offer?.partnerId
        },
        { merge: false }
      )
      message.success(t('Offer was successfully edited'))
      return history.push(generatePath(ROUTE_PATHS.OFFER_SHOW, { id }))
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during offer editing'))
    }
    setLoading(false)
  }

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true

    const fetchData = async () => {
      // Fetch request
      const offerData = await getDocumentData(COLLECTIONS.OFFERS, id)
      const typeOffer =
        offerData?.offerTypeId &&
        (await getDocumentData(COLLECTIONS.OFFER_TYPES, offerData?.offerTypeId))
      setOfferType(typeOffer)
      setOffer(offerData)
      form.setFieldsValue({
        ...offerData
      })
      setLoading(false)
    }

    isComponentMount && fetchData()

    return () => {
      isComponentMount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageLoading loading={loading || loadingOffersType}>
      <PageWrapper
        headingProps={{
          title: t('Edit offer'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        isBottomSticky>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <OfferForm
                form={form}
                onRemoveForResized={onRemoveForResized}
                onFinish={onOfferEdit}
                edit
                offersTypes={offersTypes}
                initialType={offerType}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default OfferEdit
