import { useReducer } from 'react'
import { OffersContext } from '../../contexts'
import CacheReducer from '../../reducer'

function OffersProvider(props) {
  const { children, ...rest } = props

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(CacheReducer, {})

  return (
    <OffersContext.Provider value={{ state, dispatch }} {...rest}>
      {children}
    </OffersContext.Provider>
  )
}

export default OffersProvider
