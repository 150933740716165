import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'antd'
import { Col, Row, Text, Card, Img } from '@qonsoll/react-design'
import { PushpinOutlined } from '@ant-design/icons'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

const IMAGE_HEIGHT_CONFIG = { sm: 220, md: 120, lg: 120, xl: 150, xxl: 210 }

function OfferView(props) {
  const {
    title,
    subtitle,
    description,
    phone,
    location,
    images_500,
    images_1000,
    advanced,
    onCardClick
  } = props

  const images = useResponsiveBreakpoints({
    sm: images_500,
    md: images_500,
    lg: images_1000,
    xl: images_1000,
    xxl: images_1000
  })
  // [ADDITIONAL_HOOKS]
  const imageHeight = useResponsiveBreakpoints(IMAGE_HEIGHT_CONFIG)

  // [CLEAN FUNCTIONS]
  // const onLike = () => {}
  return (
    <Card
      onClick={onCardClick}
      style={{
        cursor: 'pointer',
        width: images?.length < 2 && '-webkit-fill-available'
      }}
      mb={3}
      bordered={false}
      shadowless
      size="small"
      overflow="hidden"
      cover={
        <Row noGutters>
          <Col minWidth={0}>
            <Carousel
              appendDots={(dots) => (
                <ul>
                  {dots.map((item) =>
                    cloneElement(item, {
                      onClick: (e) => e.stopPropagation()
                    })
                  )}
                </ul>
              )}>
              {images?.map((url, index) => (
                <Img
                  key={index}
                  src={url}
                  alt={url}
                  height={imageHeight}
                  objectFit="cover"
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      }>
      {advanced ? (
        <Row noGutters>
          {/* <Col cw="12" style={{ justifyContent: 'center' }}>
            <Button type="text" icon={<HeartOutlined />} onClick={onLike} />
          </Col> */}
          <Col cw="12">
            <Text whiteSpace="pre-line">{description}</Text>
          </Col>
          <Col cw="12">
            <Text>{phone}</Text>
          </Col>
          <Col>
            <Row v="center">
              <Col cw="auto" mr={1}>
                <PushpinOutlined />
              </Col>
              <Col>
                <Text>{location}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row noGutters>
          <Col minWidth={0} mr={3}>
            <Row noGutters minWidth={0}>
              <Col cw={12}>
                <Text
                  variant="h5"
                  fontWeight="var(--ql-font-weight-medium)"
                  isEllipsis>
                  {title}
                </Text>
              </Col>
              <Col cw={12}>
                <Text isEllipsis type="secondary">
                  {subtitle}
                </Text>
                {/* <Text>{category}</Text> */}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  )
}

OfferView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  phone: PropTypes.string,
  location: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  advanced: PropTypes.bool,
  onCardClick: PropTypes.func
}

export default OfferView
