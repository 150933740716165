import { LocationsAll, LocationCreate } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'LocationsAll',
    path: ROUTE_PATHS.LOCATIONS_ALL,
    exact: true,
    component: LocationsAll
  },
  {
    name: 'LocationCreate',
    path: ROUTE_PATHS.LOCATION_CREATE,
    exact: true,
    component: LocationCreate
  }
]
