const qFormTranslations = (t) => {
  return {
    //global components
    requiredAnswerMessage: t('The answer is required'),
    submitHint: t('Press enter'),
    choicePlaceholder: t('choice'),
    conditionRemovingWarn: t(
      'This option has connected logic, delete it anyway?'
    ),
    removeButton: t('Delete'),
    uploaderHint: t('Click or drag file to this area to upload'),
    itemRemovingHint: t('Delete this item?'),
    questionListTitle: t('Questions'),
    endingListTitle: t('Endings'),
    questionCreationTooltip: t('Create new question'),
    endingCreationTooltip: t('Create new ending'),
    formViewTooltip: t('Form preview'),
    answerViewTooltip: t('Answers preview'),
    longTextHint: t('Shift ⇧ + Enter ↵ to make a line break'),
    textQuestionPlaceholder: t('Type your answer here'),

    //domains: form components
    conditionsEndingsTab: t('Endings'),
    conditionsLogicJumpsTab: t('Logic jumps'),
    conditionsQuizTab: t('Answer score configurations'),
    conditionsNoData: t('There are no question to configure'),
    conditionAddQuestionType: t(
      'Please, add one of the following questions types'
    ),
    quizSwitcherText: t('Enable quiz system'),
    formTitlePlaceholder: t('Form name'),
    formSubtitlePlaceholder: t('Form short description'),
    formModalCreateTitle: t('Create new form'),
    formModalEditTitle: t('Edit form'),
    formModalEditButton: t('Save changes'),
    formModalCreateButton: t('Create form'),

    //domains: form routes
    phoneBrakepointDummy: t('This feature is available only on desktop'),
    formsAllTitle: t('Forms'),
    formCounter: t('Amount of forms'),
    formSearchPlaceholder: t('Search form by name'),

    //domains: condition components
    conditionsEndingSelectPlaceholder: t(
      'Select questions to call current ending'
    ),
    conditionModalTitle: t('Logic'),
    conditionModalResetLogic: t('Reset logic'),
    conditionModalSubmitButton: t('Close'),
    conditionsModalTooltip: t('Configure logic jumps'),
    conditionRedirectRulePlaceholder: t('Select redirect rule'),
    scoreWeightTitle: t('Enter score weight of answer'),

    //domains: media library components
    mediaLibraryCounter: t('Amount of shown files'),
    mediaLibraryButton: t('Change'),
    mediaLibrarySearchPlaceholder: t('Search media file by name'),
    mediaLibraryTitle: t('Media library'),
    mediaLibraryBrightness: t('Brightness'),

    //domains: question components
    questionFinishButton: t('Finish'),
    questionStartButton: t('Start'),
    questionVideo: t('Video question'),
    questionRequiredSetting: t('Required'),
    questionConfigurationTooltip: t('Configure question'),
    questionTypeConfiguration: t('Question types'),
    questionConfigurationTitle: t('settings'),
    questionRangeBottomSetting: t('From'),
    questionRangeUpperSetting: t('to'),
    questionConfigurationOptions: t('Amount of options'),
    questionEditableTitleHint: t('Editable question title'),
    questionEditableSubtitleHint: t('Description (optional)'),
    questionRemovingPopconfirm: t('Delete this question?'),
    questionWithLogicRemovingPopconfirm: t(
      'This question has connected logic, delete it anyway?'
    ),
    welcomeScreenDesc: t('Invite your audience in'),
    longTextDesc: t('More space to spill the beans'),
    shortTextDesc: t('For short answers, like names'),
    dateDesc: t('Collect answers in date format'),
    fileUploadDesc: t('Upload a file up to 10MB'),
    opinionDesc: t('A customizable, numbered scale'),
    pictureChoiceDesc: t('Multiple choice but prettier'),
    choiceDesc: t('Multiple choice'),
    ratingDesc: t('Rate'),
    statementDesc: t('Take the mic for a moment'),
    yesnoDesc: t('Just 2 options, yes or no')
  }
}

export default qFormTranslations
