import { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Box } from '@qonsoll/react-design'
import UploadItem from './UploadItem'

const showAnimation = keyframes`
 0% {  opacity: 0; }
 100% {  opacity: 1; }
`

const StyledUploadItem = styled(Box)`
  animation: ${showAnimation};
  animation-duration: 0.3s;
`

const UploadList = (props) => {
  const {
    files,
    maxShowItem = 3,
    setFiles,
    onRemove,
    uploadItemProps,
    uploadItemBoxProps
  } = props

  // [COMPONENT_STATE_HOOKS]
  const cardRef = useRef(null)

  // FIXME scroll style on desktop version
  return (
    <Box
      maxHeight={(cardRef?.current?.clientHeight + '8px') * maxShowItem}
      overflowY="scroll">
      {files.map((file, index) => (
        <StyledUploadItem ref={cardRef} {...uploadItemBoxProps}>
          <UploadItem
            isLastImage={files.length === 1}
            fileIndex={index}
            key={file.url}
            file={file}
            ref={cardRef}
            setFiles={setFiles}
            onRemove={onRemove}
            {...uploadItemProps}
          />
        </StyledUploadItem>
      ))}
    </Box>
  )
}

UploadList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  maxShowItem: PropTypes.number,
  setFiles: PropTypes.func.isRequired,
  onRemove: PropTypes.func
}

export default UploadList
