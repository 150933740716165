import React, { useEffect, useState } from 'react'
import { List, message, Space, Button, Empty, Popconfirm } from 'antd'
import {
  Row,
  Col,
  Box,
  Container,
  Text,
  PageWrapper,
  Link,
  Edit,
  Paragraph
} from '@qonsoll/react-design'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import BuyingCaseAttendeesList from 'domains/BuyingCase/components/BuyingCaseAttendeesList'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import {
  deleteDocument,
  getCollectionRef,
  updateDocument
} from '~/services/Firebase/firestore'
import { PageLoading, Remove } from '~/app/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS, STATUSES } from '~/app/constants'
import BuyingCaseShowStatuses from 'domains/BuyingCase/components/BuyingCaseShowStatuses'
import {
  WizardAnswerList,
  WizardConflictAnswersTable,
  WizardVotingDiffLocation,
  WizardVotingInSameLocation
} from 'domains/Wizard/components'
import { useSessionContext } from 'app/contexts/Session/hooks'
import {
  formatDataForTable,
  notificationForCreatedAgreement
} from 'domains/BuyingCase/helpers'
import { RedoOutlined } from '@ant-design/icons'
import { useBuyingCaseContext } from '~/app/contexts/Cache/hooks'

function BuyingCaseShow() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const history = useHistory()
  const { id } = useParams()
  const { state } = useSessionContext()
  const { dispatch } = useBuyingCaseContext()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPONENT_STATE_HOOKS]
  const [createAgreementLoading, setCreateAgreementLoading] = useState(false)
  const [tableColumns, setTableColumns] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([])
  const [questions, setQuestions] = useState()
  const [questionsLoading, setQuestionsLoading] = useState(false)

  const [buyingCaseData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [votingData, votingDataLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.VOTING_DATA).doc(id)
  )

  const [wizardProcessingData, wizardProcessingLoading] = useDocumentData(
    buyingCaseData && getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).doc(id)
  )
  const [usersData, usersDataLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.USERS).where(
        'email',
        'in',
        buyingCaseData?.attendees
      )
  )
  const [form, formLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.FORMS).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )
  const [agreementTemplate, agreementTemplateLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.AGREEMENT_TEMPLATES).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )

  // [CLEAN FUNCTIONS]
  const onRemove = async () => {
    try {
      await deleteDocument(COLLECTIONS.BUYING_CASES, id)
      await deleteDocument(COLLECTIONS.WIZARD_PROCESSING, id)
      await deleteDocument(COLLECTIONS.VOTING_DATA, id)

      message.success(t('Buying case was deleted successfully'))
      history.push(ROUTE_PATHS.BUYING_CASES_ALL)
      dispatch({ type: 'REMOVE_DOC', payload: id })
    } catch (error) {
      message.error(t('Buying case not was deleted'))
    }
  }
  const onEditBuyingCase = () => {
    const editPath = generatePath(ROUTE_PATHS.BUYING_CASE_EDIT, { id })
    history.push(editPath)
  }

  const onEditUser = () => {
    const editAttendees = generatePath(
      ROUTE_PATHS.BUYING_CASE_INVITE_ATTENDEES_EDIT,
      { id }
    )
    history.push(editAttendees)
  }

  const onCreateAgreement = async () => {
    setCreateAgreementLoading(true)

    // const func = firebase
    //   .functions()
    //   .httpsCallable('createAgreement', { timeout: 0 })
    //
    // await func({})

    await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
      status: STATUSES.AGREEMENT_CREATED
    })
    await notificationForCreatedAgreement(usersData, state, buyingCaseData)
    setCreateAgreementLoading(false)
  }

  const onShowAgreement = () => {
    console.log('show agreement')
  }

  const onResetAnswers = async () => {
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      id,
      { answers: null },
      { merge: true, withoutUndef: false }
    )
    await onResetVoting()
  }

  const onResetProgress = async () => {
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      id,
      { answers: null, status: STATUSES.IN_PROGRESS },
      { withoutUndef: false }
    )
  }

  const onResetVoting = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterUsersInfo.map((item) => {
      item.answer = null
      item.voted = null

      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterUsersInfo: editedData },
      { merge: true }
    )
  }

  const onBuyerClick = (buyerId = id) => {
    history.push(
      generatePath(ROUTE_PATHS.BUYING_CASE_SHOW_BUYER_ANSWERS, {
        id,
        buyerId: buyerId
      })
    )
  }
  const onBuyerClickInSameLocation = (buyerId = id) => {
    history.push(
      generatePath(ROUTE_PATHS.USER_SHOW, {
        id: buyerId
      })
    )
  }

  const onOkConfirmReset = async () => {
    try {
      await deleteDocument(COLLECTIONS.WIZARD_PROCESSING, id)
      await deleteDocument(COLLECTIONS.VOTING_DATA, id)
      message.success(t('Progress was successfully reset'))
    } catch (error) {
      message.error(t('Progress was not reset'))
    }
  }
  // const onShare = () => {}

  // [USE_EFFECTS]
  useEffect(() => {
    wizardProcessingData?.answers &&
      form &&
      Object.values(wizardProcessingData?.answers)?.length &&
      formatDataForTable(
        wizardProcessingData?.answers,
        form?.[0]?.id,
        setTableLoading,
        setTableColumns,
        setTableData
      )
  }, [wizardProcessingData, form])

  useEffect(() => {
    const unsubscribe = () => {
      form &&
        getCollectionRef(COLLECTIONS.QUESTIONS)
          .where('formId', '==', form?.[0]?.id)
          .onSnapshot((querySnapshot) => {
            setQuestionsLoading(true)
            let formattedData = []
            querySnapshot.forEach((item) => {
              formattedData.push(item.data())
            })
            setQuestions(formattedData)
            setQuestionsLoading(false)
          })
    }
    return wizardProcessingData && unsubscribe && unsubscribe()
  }, [wizardProcessingData, form])

  useEffect(() => {
    const editedQuestionsAndAnswers = () => {
      let questionsText = {}
      questions.forEach((item) => {
        questionsText[item?.id] = item?.title
      })
      const answersText = wizardProcessingData?.answers
      let resultArray = []
      for (let qa in answersText)
        resultArray.push({
          answer: answersText?.[qa]?.value,
          question: questionsText?.[qa]
        })
      setQuestionsAndAnswers(resultArray)
    }
    questions && wizardProcessingData && editedQuestionsAndAnswers()
  }, [questions, wizardProcessingData])

  const isVotingEndedInDiffLocation =
    votingData &&
    votingData?.voterUsersInfo?.length &&
    votingData?.voterUsersInfo
      ?.map((item) => item?.answer)
      ?.reduce((acc, curr) => acc && curr)
  const isVotingEndedInSameLocation =
    votingData &&
    votingData?.voterAnswersInfo?.length &&
    votingData?.voterAnswersInfo
      ?.map((item) => item?.answer)
      ?.reduce((acc, curr) => acc && curr)

  return (
    <PageLoading
      loading={
        loading ||
        wizardProcessingLoading ||
        usersDataLoading ||
        formLoading ||
        tableLoading ||
        createAgreementLoading ||
        questionsLoading ||
        votingDataLoading ||
        agreementTemplateLoading
      }>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: <Box textAlign="initial">{buyingCaseData?.name}</Box>,
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        action={[
          // <Button type="text" icon={<ShareAltOutlined />} onClick={onShare} />,
          <Space>
            {buyingCaseData?.attendees && (
              <Box ml={3}>
                <BuyingCaseAttendeesList
                  size="large"
                  data={buyingCaseData?.attendees}
                  onEditUser={
                    wizardProcessingData?.status !==
                      STATUSES.AGREEMENT_CREATED && onEditUser
                  }
                />
              </Box>
            )}
            <Popconfirm
              title={t('Are you sure to reset progress on buying case?')}
              onConfirm={onOkConfirmReset}
              okText={t('Yes, reset progress')}
              cancelText={t('No, keep case')}>
              <Edit type="text" tooltip={t('Reset')} icon={<RedoOutlined />} />
            </Popconfirm>
            <Edit
              type="text"
              onClick={onEditBuyingCase}
              tooltip={t('Edit')}
              icon
            />
            <Remove
              type="text"
              popconfirmPlacement="bottomRight"
              itemName={t('case')}
              onSubmit={onRemove}
              icon
            />
          </Space>
        ]}>
        <Row noGutters>
          <Col cw={12}>
            <BuyingCaseShowStatuses
              agreementTemplate={agreementTemplate}
              isVotingEndedInDiffLocation={isVotingEndedInDiffLocation}
              isVotingEndedInSameLocation={isVotingEndedInSameLocation}
              tableData={tableData}
              form={form}
              usersData={usersData}
              buyingCaseData={buyingCaseData}
              wizardProcessingData={wizardProcessingData}
            />
          </Col>
          <Col cw={12} mt={2}>
            <Paragraph whiteSpace="pre-line">
              {buyingCaseData?.description}
            </Paragraph>
          </Col>
          {!!buyingCaseData?.links?.length && (
            <Col cw={12} display="block" mb={3} mt={2}>
              <List
                dataSource={buyingCaseData.links}
                renderItem={(link) => (
                  <Box
                    overflow="hidden"
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}>
                    <Link href={link} target="_blank">
                      <Text color="var(--ql-color-accent1)">{link}</Text>
                    </Link>
                  </Box>
                )}
                split={false}
              />
            </Col>
          )}
          {wizardProcessingData?.status === undefined && (
            <Col cw={12} pt={2} style={{ justifyContent: 'center' }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  height: 60
                }}
                description={<span>{t('No data about buying case')}</span>}
              />
            </Col>
          )}
          {wizardProcessingData?.isSameLocation === false &&
            wizardProcessingData?.answers !== null && (
              <>
                <Col cw={12} h={'center'} mt={2}>
                  {tableColumns && tableData && (
                    <WizardConflictAnswersTable
                      columnNames={tableColumns}
                      data={tableData}
                    />
                  )}
                </Col>
                <Col cw={12} display={'block'} h={'center'} mt={2}>
                  <WizardVotingDiffLocation
                    agreementTemplate={agreementTemplate}
                    tableData={tableData}
                    usersData={usersData}
                    votingData={votingData}
                    wizardProcessingStatus={wizardProcessingData?.status}
                    onBuyerClick={onBuyerClick}
                    onCreateAgreement={onCreateAgreement}
                    onResetAnswers={onResetAnswers}
                    onResetVoting={onResetVoting}
                  />
                </Col>
              </>
            )}
          {wizardProcessingData?.isSameLocation === true &&
            wizardProcessingData?.answers !== null && (
              <Container
                height="inherit"
                display="flex"
                style={{
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Row noGutters h="center">
                  <Col cw={[12, 8]}>
                    <Box
                      display="flex"
                      justifyContent="start"
                      flex={1}
                      alignItems="center"
                      mt={2}
                      mb={3}>
                      <Text variant="h5">{t('Answers main buyer')}</Text>
                    </Box>
                  </Col>
                  <Col cw={[12, 8]}>
                    <WizardAnswerList data={questionsAndAnswers} />
                  </Col>
                </Row>
                <WizardVotingInSameLocation
                  agreementTemplate={agreementTemplate}
                  usersData={usersData}
                  onBuyerClick={onBuyerClickInSameLocation}
                  wizardProcessingStatus={wizardProcessingData?.status}
                  votingDataInSameLocation={votingData}
                  onCreateAgreement={onCreateAgreement}
                  onResetProgress={onResetProgress}
                />
              </Container>
            )}
        </Row>
        <Row noGutters h="center">
          {wizardProcessingData?.status === STATUSES.AGREEMENT_CREATED && (
            <Col py={2} cw={[12, 8]}>
              <Button
                size="large"
                block
                type="primary"
                onClick={onShowAgreement}>
                {t('Show agreement')}
              </Button>
            </Col>
          )}
        </Row>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseShow
