import React from 'react'
import { useHistory } from 'react-router-dom'
import { Empty } from 'antd'
import { PageWrapper, Button, Row, Col } from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { getCollectionRef } from '~/services/Firebase/firestore'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { AdministratorList } from 'domains/Administrator/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from 'app/constants'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useAdministratorsContext } from '~/app/contexts/Cache/hooks'
import useCache from '~/hooks/cache/useCache'
import _ from 'lodash'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'

function AdministratorsAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const { state, dispatch } = useAdministratorsContext()
  useCache(
    getCollectionRef(COLLECTIONS.USERS)
      .where('role', '==', 'ADMIN')
      .orderBy('id', 'asc')
      .limit(20),
    state,
    dispatch
  )

  // [CLEAN_FUNCTIONS]
  const goToInviteAdmin = () => history.push(ROUTE_PATHS.ADMINISTRATOR_INVITE)

  return (
    <PageWrapper
      headingProps={{
        title: t('Administrators'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}
      action={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={goToInviteAdmin}
        />
      }
      isBottomSticky>
      {!_.isEmpty(state?.cachedCollectionData) ? (
        <Row noGutters>
          <Col cw={12}>
            <InfiniteCachedList
              query={getCollectionRef(COLLECTIONS.USERS).where(
                'role',
                '==',
                'ADMIN'
              )}
              limit={15}
              collectionName={COLLECTIONS.USERS}
              cache={state}
              setCache={dispatch}
              order={{ field: 'id', type: 'asc' }}>
              {(admins) => <AdministratorList data={admins} />}
            </InfiniteCachedList>
          </Col>
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default AdministratorsAll
