import { useHistory } from 'react-router-dom'
import { Button, Col, PageWrapper, Row } from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { getCollectionRef } from 'services/Firebase/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { OfferTypeList } from 'domains/OfferType/components'
import { COLLECTIONS } from 'app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import useOfferTypesContext from '~/app/contexts/Cache/hooks/useOfferTypesContext'
import useCache from '~/hooks/cache/useCache'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'
import { Empty } from 'antd'
import _ from 'lodash'

function OfferTypeAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const { state, dispatch } = useOfferTypesContext()
  useCache(
    getCollectionRef(COLLECTIONS.OFFER_TYPES).orderBy('id', 'asc').limit(20),
    state,
    dispatch
  )

  // [CLEAN_FUNCTIONS]
  const goToOfferTypeCreate = () => {
    history.push(ROUTE_PATHS.OFFER_TYPE_CREATE)
  }

  return (
    <PageWrapper
      headingProps={{
        title: t('Offer Types'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}
      action={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={goToOfferTypeCreate}
        />
      }>
      {!_.isEmpty(state?.cachedCollectionData) ? (
        <InfiniteCachedList
          limit={15}
          collectionName={COLLECTIONS.OFFER_TYPES}
          cache={state}
          setCache={dispatch}
          order={{ field: 'id', type: 'asc' }}>
          {(offerTypes) => <OfferTypeList data={offerTypes} />}
        </InfiniteCachedList>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default OfferTypeAll
