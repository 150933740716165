import { AdministratorInvite, AdministratorsAll } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'AdministratorInvite',
    path: ROUTE_PATHS.ADMINISTRATOR_INVITE,
    exact: true,
    component: AdministratorInvite
  },
  {
    name: 'AdministratorsAll',
    path: ROUTE_PATHS.ADMINISTRATORS_ALL,
    exact: true,
    component: AdministratorsAll
  }
]
