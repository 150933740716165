import { useState, useEffect } from 'react'
import { List } from 'antd'
import { useHistory } from 'react-router-dom'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Button, PageWrapper } from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { PageLoading } from 'app/components'
import { AgreementTemplateListItem } from 'domains/AgreementTemplate/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from 'app/constants'
import { differenceTypes } from 'domains/Wizard/helpers'

function AgreementTemplateAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const [agreementTemplates, loading] = useCollectionData(
    getCollectionRef(COLLECTIONS.AGREEMENT_TEMPLATES)
  )
  const [buyingCaseTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )

  // [COMPONENT_STATE_HOOKS]
  const [type, setType] = useState([])

  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN_FUNCTIONS]
  const goToBuyingCaseTypeCreate = () => {
    history.push(ROUTE_PATHS.AGREEMENT_TEMPLATE_CREATE)
  }

  // [USE_EFFECTS]
  useEffect(() => {
    const types =
      buyingCaseTypes &&
      agreementTemplates &&
      differenceTypes(buyingCaseTypes, agreementTemplates)
    setType(types)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementTemplates?.length, buyingCaseTypes])

  return (
    <PageLoading loading={loading || loadingBuyingCaseTypes}>
      <PageWrapper
        headingProps={{
          title: t('Agreement templates'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        action={
          <Button
            disabled={type?.length === 0}
            type="primary"
            icon={<PlusOutlined />}
            onClick={goToBuyingCaseTypeCreate}
          />
        }>
        <List
          dataSource={agreementTemplates}
          renderItem={(item) => (
            <AgreementTemplateListItem {...item} key={item.id} />
          )}
        />
      </PageWrapper>
    </PageLoading>
  )
}

export default AgreementTemplateAll
