import React, { useState } from 'react'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useDocumentData } from '~/hooks/firestore'
import {
  deleteDocument,
  getCollectionRef,
  updateDocument
} from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { PageLoading } from 'app/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { message } from 'antd'
import {
  arrayWithNonActivatedUsers,
  emailsForSendInvite,
  inviteBuyer
} from 'domains/BuyingCase/helpers'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import {
  BuyingCaseCRUDAttendees,
  BuyingCaseInviteAttendeesList
} from 'domains/BuyingCase/components'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import _ from 'lodash'

function BuyingCaseInviteAttendeesEdit() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const { id } = useParams()
  const history = useHistory()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const [data, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [usersData, usersDataLoading] = useCollectionData(
    data?.attendees?.length > 0 &&
      getCollectionRef(COLLECTIONS.USERS).where('email', 'in', data?.attendees)
  )
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  //[STATE HOOKS]
  const [loadingForm, setLoadingForm] = useState(false)

  // [COMPUTED PROPERTIES]
  const attendeesUsers = arrayWithNonActivatedUsers(data?.attendees, usersData)

  // [CLEAN FUNCTIONS]
  const onBuyerClick = (id) => {
    id && history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))
  }

  const onRemove = async (emailForRemoved) => {
    //rest emails without emailForRemoved
    const newEmails = data?.attendees?.filter(
      (email) => email !== emailForRemoved
    )

    await updateDocument(COLLECTIONS.BUYING_CASES, id, {
      attendees: newEmails
    })

    //reset progress wizard
    await deleteDocument(COLLECTIONS.VOTING_DATA, id)
    await deleteDocument(COLLECTIONS.WIZARD_PROCESSING, id)
  }

  const onSendInvite = async (values) => {
    const { emails } = values
    //checking if form is no empty
    if (emails?.length > 0) {
      //only uniq emails from form
      const uniqEmails = _.uniqBy(emails, 'email')

      //return only new email for send invite to buyer app
      const { emailsForInvite } = emailsForSendInvite(
        uniqEmails,
        data?.attendees
      )
      setLoadingForm(true)
      try {
        // new emails for buying case with previous emails and new emails from form
        const newEmails = [...data?.attendees, ...emailsForInvite]

        await updateDocument(COLLECTIONS.BUYING_CASES, id, {
          attendees: newEmails
        })
        //reset progress wizard
        await deleteDocument(COLLECTIONS.VOTING_DATA, id)
        await deleteDocument(COLLECTIONS.WIZARD_PROCESSING, id)

        //send invite for new members
        if (emailsForInvite.length > 0) {
          for (const email of emailsForInvite) await inviteBuyer(email, t)
        }

        message.success(t(`Users was invited`))
      } catch (error) {
        message.error(t('Error operation'))
      }
      setLoadingForm(false)
    }
  }
  return (
    <PageLoading loading={loading || loadingForm || usersDataLoading}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Edit buyers'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container
          height="inherit"
          display="flex"
          style={{ justifyContent: 'center', flexDirection: 'column' }}>
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid" mb="24px">
              <BuyingCaseInviteAttendeesList
                usersData={attendeesUsers}
                onBuyerClick={onBuyerClick}
                onRemove={onRemove}
              />
            </Col>
            <Col cw={[12, 10, 8, 7, 6]}>
              <BuyingCaseCRUDAttendees
                onFinish={onSendInvite}
                buyersInBuyingCase={data?.attendees}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseInviteAttendeesEdit
