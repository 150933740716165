import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import { Row, Col, Text } from '@qonsoll/react-design'
import { RightOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function PartnerView(props) {
  const { name, isActivated } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Row noGutters v="center">
      <Col minWidth={0} mr={3}>
        <Text isEllipsis>{name}</Text>
      </Col>
      {!isActivated && (
        <Col cw="auto">
          <Tag icon={<CheckCircleOutlined />}>{t('pending')}</Tag>
        </Col>
      )}
      <Col cw="auto" ml={3}>
        <RightOutlined style={{ fontSize: '24px' }} />
      </Col>
    </Row>
  )
}

PartnerView.propTypes = {
  name: PropTypes.string.isRequired,
  isActivated: PropTypes.bool
}

export default PartnerView
