import React from 'react'
import { List, Space } from 'antd'
import { Box, Col, Row, Text, Card } from '@qonsoll/react-design'
import { UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import Avatar from 'antd/lib/avatar/avatar'
import { Remove } from 'app/components'

function BuyingCaseInviteAttendeesList(props) {
  const { usersData, onBuyerClick, onRemove } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Row noGutters h="center">
      <Col cw="12">
        <List
          style={{ width: 'inherit', flex: 1 }}
          dataSource={usersData}
          renderItem={(user, index) => (
            <Card
              key={index}
              mb={3}
              size="small"
              bordered={false}
              shadowless
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onBuyerClick(user?.id)
              }}>
              <Row noGutters h="center" v="center">
                <Col cw="auto" mr={3}>
                  <Box
                    height="fit-content"
                    border="var(--outline-width) var(--ql-border-style-default) var(--dark-transparent-7)"
                    borderRadius="50%">
                    <Avatar
                      size="large"
                      m="2px"
                      icon={<UserOutlined />}
                      src={user?.avatarURL}
                      alt="avatar"
                    />
                  </Box>
                </Col>
                <Col minWidth={0}>
                  <Text>
                    {user?.firstName && user?.secondName
                      ? `${user?.firstName} ${user?.secondName}`
                      : user?.email}
                  </Text>
                </Col>
                <Col cw="auto" ml={3}>
                  <Space>
                    <Remove
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      onSubmit={(e) => {
                        e.stopPropagation()
                        onRemove(user?.email)
                      }}
                      popconfirmPlacement={'right'}
                      itemName={t('buyer')}
                      icon
                    />
                  </Space>
                </Col>
              </Row>
            </Card>
          )}
        />
      </Col>
    </Row>
  )
}

BuyingCaseInviteAttendeesList.propTypes = {
  usersData: PropTypes.array.isRequired,
  onBuyerClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default BuyingCaseInviteAttendeesList
