import { Col, Row, Text, Img } from '@qonsoll/react-design'
import { Switch, Redirect, Link, useLocation } from 'react-router-dom'
import configMoment from 'app/config/moment'
import { useSessionContext } from 'app/contexts/Session/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { AuthenticatedRoute, UnauthenticatedRoute } from 'app/components'
import { GDPRPopup } from 'domains/App/components'
import routes from 'app/domains/allRoutes'
import unauthenticatedRoutes from 'app/constants/unauthenticatedRoutes'
import ROUTE_PATHS from 'domains/allRoutePath'
import cookie from 'assets/cookie.png'

configMoment()

function App() {
  // [ADDITIONAL_HOOKS]
  const { state } = useSessionContext()
  const { t } = useTranslations()
  const getLineBreak = useResponsiveBreakpoints({
    sm: true,
    md: false,
    lg: true,
    xl: false,
    xxl: false
  })

  const deviceID = new URLSearchParams(useLocation().search).get('pushDeviceId')
  deviceID && localStorage.setItem('DeviceID', deviceID)

  return (
    <>
      <Switch>
        {routes.map((route) =>
          !unauthenticatedRoutes.includes(route.path) ? (
            <AuthenticatedRoute
              key={route.name}
              exact={route.exact}
              path={route.path}
              component={route.component}
              user={state}
            />
          ) : (
            <UnauthenticatedRoute
              key={route.name}
              exact={route.exact}
              path={route.path}
              component={route.component}
              user={state}
            />
          )
        )}
        <Redirect
          from="/"
          to={state ? ROUTE_PATHS.DASHBOARD : ROUTE_PATHS.LOGIN}
        />
      </Switch>
      <GDPRPopup whenToShow={state?.email}>
        <Row noOuterGutters v="center">
          <Col cw="auto">
            <Img src={cookie} height="var(--ql-font-size-h2)" alt="cookie" />
          </Col>
          <Col>
            <Text variant="body2">
              {t('Buy2gether uses cookies to personalize content')}.{' '}
              {t(
                'By continuing of using this site, you agree with our cookie policy'
              )}
              . {getLineBreak && <br />}
              {t('Click')} <Link to={ROUTE_PATHS.GDPR_PAGE}>{t('here')}</Link>{' '}
              {t('to learn more')}.
            </Text>
          </Col>
        </Row>
      </GDPRPopup>
    </>
  )
}

export default App
