import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { InView } from 'react-intersection-observer'
import { message } from 'antd'
import { Box } from '@qonsoll/react-design'
import { getCollectionRef } from 'services/Firebase/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { Spinner } from 'app/components'

const InfiniteList = (props) => {
  const {
    query,
    children,
    initialData,
    limit,
    order,
    idField = 'id',
    collectionName,
    onReachEnd,
    cache,
    setCache
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)
  const [cachedData, setCachedData] = useState(
    Object.values(cache?.cachedCollectionData)
  )
  const [data, setData] = useState([])
  const [lastElement, setLastElement] = useState(
    _.last(Object.values(cache?.cachedCollectionData))
  )
  const [isEndOfList, setIsEndOfList] = useState(false)

  // [HELPER_FUNCTIONS]
  const handleUpdate = async (isView) => {
    if (isView) {
      !isEndOfList && setLoading(true)
      try {
        // Get new data snapshot
        const res =
          lastElement &&
          (query
            ? await query
                .orderBy(order.field, order.type ?? 'asc')
                .startAfter(lastElement?.[order.field])
                .limit(limit)
                .get()
            : await getCollectionRef(collectionName)
                .orderBy(order.field, order.type ?? 'asc')
                .startAfter(lastElement?.[order.field])
                .limit(limit)
                .get())
        if (!res?.size) {
          // If get all collection data
          onReachEnd?.()
          setIsEndOfList(true)

          setLoading(false)
        } else {
          // Transform data snapshot to valid data object
          const resData = res.docs
            .map((snapshot) => {
              if (idField) {
                return {
                  ...snapshot.data(),
                  [idField]: snapshot.id
                }
              }
              return snapshot.data()
            })
            .filter((item) => !cache?.cachedCollectionData?.[item.id])

          setData((data) => data.concat(resData))
          //get last element to know where to start new query
          setLastElement(_.last(resData))
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        message.error(t('Error occurred during data fetch'))
      }
    }
  }

  useEffect(() => {
    // const filteredData = data.filter(
    //   (item) => !cache?.cachedCollectionData?.[item.id]
    // )
    const cachedArray = Object.values(cache?.cachedCollectionData)
    setCachedData(cachedArray)
    setData([])
    setLastElement(_.last(cachedArray))
    setIsEndOfList(false)
  }, [cache?.cachedCollectionData])

  return (
    <>
      {children(cachedData.concat(data))}
      <InView as={Box} onChange={handleUpdate}>
        {loading && <Spinner mb={2} />}
      </InView>
    </>
  )
}

InfiniteList.propTypes = {
  children: PropTypes.func,
  initialData: PropTypes.array,
  collectionName: PropTypes.string.isRequired,
  order: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['asc', 'desc'])
  }),
  limit: PropTypes.number,
  idField: PropTypes.string,
  onReachEnd: PropTypes.func
}

export default InfiniteList
