import { BuyingCaseTypeAll, BuyingCaseTypeCreate } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'BuyingCaseTypeAll',
    path: ROUTE_PATHS.BUYING_CASE_TYPE_ALL,
    exact: true,
    component: BuyingCaseTypeAll
  },
  {
    name: 'BuyingCaseTypeCreate',
    path: ROUTE_PATHS.BUYING_CASE_TYPE_CREATE,
    exact: true,
    component: BuyingCaseTypeCreate
  }
]
