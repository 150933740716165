import React from 'react'
import { Form } from 'antd'
import { Button, Input } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function ForgotPasswordForm(props) {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form {...props}>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: t('Please enter valid email')
          }
        ]}>
        <Input
          autoFocus
          placeholder={t('Enter your account email')}
          textAlign="center"
        />
      </Form.Item>
      <Button type="primary" block htmlType="submit">
        {t('Restore Password')}
      </Button>
    </Form>
  )
}

export default ForgotPasswordForm
