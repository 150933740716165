import { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  BankOutlined
} from '@ant-design/icons'
import {
  Row,
  Col,
  Box,
  Input,
  Save,
  Title,
  Link,
  TextArea,
  Text,
  PhoneInput
} from '@qonsoll/react-design'
import { AvatarUploader } from '~/app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { OfferTypeSelect } from 'domains/OfferType/components'

function PartnerForm(props) {
  const { onFinish, offersTypes, diffImage = [], setDiffImage, ...rest } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [COMPONENT STATE HOOKS]
  const [type, setType] = useState({})

  // [CLEAN FUNCTIONS]

  const onChangeSelectType = (_, object) => {
    setType(object.key)
  }
  const onValuesChange = (data) => {
    if ('logoURL' in data) {
      setDiffImage?.([...diffImage, data?.logoURL])
    }
  }
  return (
    <Form
      autoComplete="off"
      onFinish={(data) => onFinish(data, type)}
      onValuesChange={onValuesChange}
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Form.Item name="logoURL" style={{ margin: 0 }}>
        <AvatarUploader shape="square" icon={<BankOutlined />} {...rest} />
      </Form.Item>
      <Box>
        <Form.Item
          label={t('Partner name')}
          name="partnerName"
          rules={[
            { required: true, message: t('Enter partner name, please') },
            {
              max: 200,
              message: t('Name should be less than 200 character')
            }
          ]}>
          <Input autoFocus placeholder={t('Enter partner name')} />
        </Form.Item>
      </Box>

      <Form.Item
        label={t('Offer type')}
        name="offerTypeId"
        style={{ width: '100%' }}
        rules={[{ required: true, message: t('Enter partner type, please') }]}>
        <OfferTypeSelect
          onChange={onChangeSelectType}
          offerTypes={offersTypes}
          placeholder={t('Type')}
        />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name="partnerDescription"
        rules={[
          {
            max: 2500,
            message: t('Description should be less than 2500 character')
          }
        ]}>
        <TextArea placeholder={t('Enter description')} rows={3} />
      </Form.Item>

      <Row noGutters mb={24}>
        <Col cw={12} mb={2}>
          <Title level={5}>{t('Contact person')}</Title>
        </Col>
        <Col cw={12}>
          <Text>
            {t(
              'Add information about persons who is responsible to contact with clients'
            )}
          </Text>
        </Col>
      </Row>
      <Form.List name="contactPeople">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Box key={key}>
                <Row noGutters mb={3}>
                  <Col>
                    <Text
                      variant="overline"
                      type="secondary"
                      textTransform="uppercase">
                      {t('Contact person')}
                    </Text>
                  </Col>
                  <Col cw="auto">
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
                <Form.Item
                  label={t('Email')}
                  {...restField}
                  name={[name, 'email']}
                  fieldKey={[fieldKey, 'email']}
                  rules={[
                    {
                      required: true,
                      message: t("Enter contact person's e-mail, please")
                    }
                  ]}>
                  <Input placeholder={t('Enter email')} />
                </Form.Item>
                <Row noGutters>
                  <Col cw={12} display="block">
                    <Form.Item
                      label={t('First name')}
                      {...restField}
                      name={[name, 'firstName']}
                      fieldKey={[fieldKey, 'firstName']}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "Enter contact person's first name, please"
                          )
                        },
                        {
                          max: 75,
                          message: t(
                            'First name should be less than 75 character'
                          )
                        }
                      ]}>
                      <Input placeholder={t('Enter first name')} />
                    </Form.Item>
                  </Col>
                  <Col cw={12} display="block">
                    <Form.Item
                      label={t('Last name')}
                      {...restField}
                      name={[name, 'secondName']}
                      fieldKey={[fieldKey, 'secondName']}
                      rules={[
                        {
                          required: true,
                          message: t("Enter contact person's last name, please")
                        },
                        {
                          max: 75,
                          message: t(
                            'Second name should be less than 75 character'
                          )
                        }
                      ]}>
                      <Input placeholder={t('Enter last name')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={t('Phone')}
                  {...restField}
                  name={[name, 'phone']}
                  fieldKey={[fieldKey, 'phone']}
                  rules={[
                    {
                      required: true,
                      message: t('Enter person`s phone, please')
                    }
                  ]}>
                  <PhoneInput country="no" />
                </Form.Item>
              </Box>
            ))}
            <Link onClick={() => add()} mb={48}>
              <PlusOutlined /> {t('Add new person')}
            </Link>
          </>
        )}
      </Form.List>
      <Row noGutters mt="auto">
        <Col>
          <Save
            type="primary"
            block
            htmlType="submit"
            text={t('Invite partner')}
            mb={1}
          />
        </Col>
      </Row>
    </Form>
  )
}

PartnerForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  offersTypes: PropTypes.array.isRequired
}

export default PartnerForm
