import React from 'react'
import {
  Row,
  Col,
  Box,
  PageWrapper,
  Title,
  Button
} from '@qonsoll/react-design'
import { RightOutlined } from '@ant-design/icons'
import { LastRecentActivitiesList } from 'domains/RecentActivity/components'
import { StatisticsList } from 'domains/Statistic/components'
import { useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

const activitiesNumberOnResponsiveSizes = {
  sm: 3,
  md: 8,
  lg: 10,
  xl: 10,
  xxl: 10
}

function Dashboard() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const activitiesNumber = useResponsiveBreakpoints(
    activitiesNumberOnResponsiveSizes
  )

  // [CLEAN_FUNCTIONS]
  const onRecentActivities = () => {
    history.push(ROUTE_PATHS.RECENT_ACTIVITIES_ALL)
  }

  return (
    <PageWrapper firstLevelHidden>
      <Row noGutters v="center" h="between" mb="24px">
        <Col cw="auto">
          <Title level={4}>{t('Recent activities')}</Title>
        </Col>
        <Col cw="auto">
          <Box mr={-12}>
            <Button
              icon={<RightOutlined />}
              type="text"
              onClick={onRecentActivities}
            />
          </Box>
        </Col>
      </Row>
      <Row noGutters>
        <Col cw={12} display="block">
          <LastRecentActivitiesList count={activitiesNumber} />
        </Col>
      </Row>
      <Row noGutters>
        <Col cw={12}>
          <StatisticsList />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default Dashboard
