import { getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import _ from 'lodash'

const formatDataForTable = async (
  data,
  formId,
  setTableLoading,
  setTableColumns,
  setTableData
) => {
  setTableLoading(true)
  const userIds = Object.keys(data)
  const userColData = await getCollectionRef(COLLECTIONS.USERS)
    .where('id', 'in', userIds)
    .get()
  const userNames = userColData.docs.map(
    (item) => `${item.data().firstName} ${item.data().secondName}`
  )
  setTableColumns(userNames)
  const questionData = await getCollectionRef(COLLECTIONS.QUESTIONS)
    .where('formId', '==', formId)
    .get()
  const questionNames = questionData.docs.map((item) => {
    return { id: item.id, title: item.data().title }
  })
  const formattedTableData = questionNames.map((item) => {
    let answers = {}
    let index = 0
    for (let userAns in data) {
      if (data[userAns][item.id]) {
        answers[`name${index}`] =
          data[userAns][item.id]?.letterKey || data[userAns][item.id]?.value
        index++
      }
    }
    return { ...answers, question: item.title }
  })
  const questionsWithAnswers = formattedTableData.filter(
    (item) => Object.keys(item).length > 1
  )
  const conflictAnswers = questionsWithAnswers.filter((item) => {
    const { question, ...answ } = item
    return _.uniq(Object.values(answ)).length !== 1
  })
  setTableData(conflictAnswers)
  setTableLoading(false)
}
export default formatDataForTable
