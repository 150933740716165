import React from 'react'
import PropTypes from 'prop-types'
import { Timeline } from 'antd'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useCollectionData } from '~/hooks/firestore'
import { RecentActivityView } from 'domains/RecentActivity/components'
import { COLLECTIONS } from '~/app/constants'

function LastRecentActivitiesList(props) {
  const { count = 3 } = props

  // [ADDITIONAL_HOOKS]
  const [activities, activitiesLoading] = useCollectionData(
    getCollectionRef(COLLECTIONS.ACTIVITIES)
      .orderBy('date', 'desc')
      .limit(count)
  )

  return !activitiesLoading ? (
    <Timeline>
      {activities.slice(0, count).map((item) => (
        <Timeline.Item key={item.id}>
          <RecentActivityView {...item} />
        </Timeline.Item>
      ))}
    </Timeline>
  ) : (
    <Timeline>
      {Array.from({ length: count }, () => '').map((item) => (
        <Timeline.Item key={item.id}>
          <RecentActivityView {...item} loading />
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

LastRecentActivitiesList.propTypes = {
  count: PropTypes.number
}

export default LastRecentActivitiesList
