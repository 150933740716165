import { Grid } from 'antd'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useHistory, generatePath, useParams } from 'react-router-dom'
import firebase from 'services/Firebase/init'
import { useBackButton } from 'app/contexts/UI/hooks'
import { PageLoading, RedirectToDesktop } from 'app/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import {
  CurrentQuestionContextProvider,
  FormEdit
} from '~/modules/feedback-typeform-app/src'
import { COLLECTIONS, qFormTranslations } from 'app/constants'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, PageWrapper } from '@qonsoll/react-design'
import { getDocumentData } from '~/services/Firebase/firestore'

const { useBreakpoint } = Grid

function WizardsEdit() {
  // [COMPONENT STATE HOOKS]
  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState({})

  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const translations = useMemo(() => {
    qFormTranslations(t)
  }, [t])
  const screen = useBreakpoint()
  const history = useHistory()
  const { id } = useParams()

  // [CLEAR_FUNCTIONS]
  const onFormShow = () => {
    const path = generatePath(ROUTE_PATHS.WIZARD_SHOW, { id })
    history.push(path)
  }

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true

    const fetchData = async () => {
      // Fetch request
      const formData = await getDocumentData(COLLECTIONS.FORMS, id)
      setForm(formData)
      setLoading(false)
    }

    isComponentMount && fetchData()

    return () => {
      isComponentMount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return screen.xl ? (
    <PageLoading loading={loading}>
      <PageWrapper
        headingProps={{
          title: <Box textAlign="initial">{form?.title}</Box>,
          titleSize: 3,
          marginBottom: '2px'
        }}
        height="inherit"
        onBack={goBack}
        action={[
          <Button type="primary" onClick={onFormShow}>
            {t('Show wizard')}
          </Button>
        ]}>
        <CurrentQuestionContextProvider>
          <FormEdit
            id={id}
            firebase={firebase}
            translations={translations}
            hideResults
          />
        </CurrentQuestionContextProvider>
      </PageWrapper>
    </PageLoading>
  ) : (
    Object.keys(screen).length && <RedirectToDesktop />
  )
}

export default WizardsEdit
