import { configResponsive, useResponsive } from 'ahooks'
import theme from 'styles/theme.js'

configResponsive(theme.customBreakpointsObject)

/**
 * @param {{
  sm: any,
  md: any,
  lg: any,
  xl: any,
  xxl: any
}} configMap

 * @returns {*}
 */

function useResponsiveBreakpoints(configMap) {
  const responsive = useResponsive(configResponsive)

  const currentScreenSize = Object.entries(responsive)
    .filter((item) => item[1])
    .pop()[0]

  return configMap[currentScreenSize]
}

export default useResponsiveBreakpoints
