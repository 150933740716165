import { Box, Button, Col, PageWrapper, Row } from '@qonsoll/react-design'
import { message } from 'antd'
import { COLLECTIONS } from 'app/constants'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import {
  AgreementTemplateCollapsibleEditor,
  AgreementTemplatePreview,
  AgreementTemplateTypeForm
} from 'domains/AgreementTemplate/components'
import {
  convertContentToDraftHtml,
  convertStateToReactComponent
} from 'domains/AgreementTemplate/helpers'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { createDocument, getCollectionRef } from '~/services/Firebase/firestore'

const ACCEPTED_QUESTION_TYPES = [
  'Rating',
  'Yes/No',
  'Picture choice',
  'Choice',
  'Opinion scale'
]

const TITLE_SIZE_CONFIG = {
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3
}

const BACK_BUTTON_VISIBILITY = {
  sm: false,
  md: true,
  lg: true,
  xl: true,
  xxl: true
}

function AgreementTemplateWithCustomBlocksCreate() {
  const backButtonVisibility = useResponsiveBreakpoints(BACK_BUTTON_VISIBILITY)
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const titleSizes = useResponsiveBreakpoints(TITLE_SIZE_CONFIG)
  const { t } = useTranslations()
  const history = useHistory()

  // [COMPONENT STATE HOOKS]
  const [questionData, setQuestionData] = useState([])
  const [currentType, setCurrentType] = useState(null)
  const [template, setTemplate] = useState({})

  // [CLEAN FUNCTIONS]
  const onTypeFormFinish = (values) => {
    setCurrentType(values?.buyingTypeId)
  }

  const onAnswerOptionChange = (
    questionField,
    id,
    title,
    answerOption,
    legalText = ''
  ) => {
    setTemplate((template) => {
      // if (legalText === '') return
      if (template?.[questionField]?.answers) {
        const checkIfThereIsChanges = template?.[questionField]?.answers?.find(
          (item) =>
            item.answerOption === answerOption && item.legalText === legalText
        )
        if (checkIfThereIsChanges) return

        const indexOfEditedItem = template?.[questionField]?.answers?.findIndex(
          (item) => item.answerOption === answerOption
        )
        const answers = [...template?.[questionField]?.answers]

        indexOfEditedItem === -1
          ? answers.push({ answerOption, legalText })
          : (answers[indexOfEditedItem] = { answerOption, legalText })

        return {
          ...template,
          [questionField]: {
            id,
            title,
            answers
          }
        }
      } else {
        return {
          ...template,
          [questionField]: {
            id,
            title,
            answers: [{ answerOption, legalText }]
          }
        }
      }
    })
  }

  const onTextEditorChange = (editorState, field) => {
    const component = convertStateToReactComponent(editorState)
    setTemplate({ ...template, [field]: { component, state: editorState } })
  }

  const onTemplateCreate = async () => {
    try {
      const header =
        template?.header?.state &&
        convertContentToDraftHtml(template?.header?.state)
      const footer =
        template?.footer?.state &&
        convertContentToDraftHtml(template?.footer?.state)
      const questions = Object.entries(template)
        .filter((item) => item[0].includes('question'))
        .map((item) => item[1])
      await createDocument(COLLECTIONS.AGREEMENT_TEMPLATES, {
        header,
        footer,
        questions,
        buyingTypeId: currentType
      })
      message.success(t('Agreement template was successfully created'))
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during agreement template creation'))
    }
    history.goBack()
  }

  // [USE EFFECTS]
  useEffect(() => {
    const getQuestionsData = async () => {
      const formQuery = await getCollectionRef(COLLECTIONS.FORMS)
        .where('buyingTypeId', '==', currentType)
        .get()
      const formID = formQuery?.docs?.map((item) => item.id)?.[0]
      if (formID) {
        const questionQuery = await getCollectionRef(COLLECTIONS.QUESTIONS)
          .where('formId', '==', formID)
          .orderBy('order')
          .get()
        const questions = questionQuery.docs
          .filter((item) =>
            ACCEPTED_QUESTION_TYPES.includes(item.data().questionType)
          )
          ?.map((item) => ({
            id: item.data().id,
            title: item.data().title,
            answers: item
              .data()
              ?.questionConfigurations?.map(
                (answerItem) => answerItem.answerOption
              )
          }))

        questions.forEach((question, index) => {
          question.answers.forEach((answerOption) => {
            onAnswerOptionChange(
              `question${index}`,
              question.id,
              question.title,
              answerOption
            )
          })
        })
        setQuestionData(questions)
      } else {
        setQuestionData([])
      }
    }
    currentType && getQuestionsData()
  }, [currentType])

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Create agreement template'),
        titleSize: titleSizes,
        marginBottom: 24
      }}
      onBack={goBack}>
      <Box mb={3}>
        <AgreementTemplateTypeForm onFinish={onTypeFormFinish} />
      </Box>

      {!!questionData.length && (
        <>
          <Row noGutters mb="24px">
            <Col mr={3}>
              <AgreementTemplateCollapsibleEditor
                questionData={questionData}
                onAnswerOptionChange={onAnswerOptionChange}
                onTextEditorChange={onTextEditorChange}
              />
            </Col>
            <Col>
              <AgreementTemplatePreview
                header={template?.header?.component}
                footer={template?.footer?.component}
              />
            </Col>
          </Row>
          <Box>
            <Button block type="primary" onClick={onTemplateCreate}>
              {t('Create agreement template')}
            </Button>
          </Box>
        </>
      )}
    </PageWrapper>
  )
}

export default AgreementTemplateWithCustomBlocksCreate
