const theme = {}

theme.space = [0, 2, 4, 8, 16, 32]

theme.customBreakpointsObject = {
  sm: 0,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

export default theme
