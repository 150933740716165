import { useReducer } from 'react'
import { BuyersContext } from '../../contexts'
import CacheReducer from '../../reducer'

function BuyersProvider(props) {
  const { children, ...rest } = props

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(CacheReducer, {})

  return (
    <BuyersContext.Provider value={{ state, dispatch }} {...rest}>
      {children}
    </BuyersContext.Provider>
  )
}

export default BuyersProvider
