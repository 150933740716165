import React from 'react'
import { Col, Row } from '@qonsoll/react-design'
import { Alert } from 'antd'
import _ from 'lodash'
import { STATUSES } from 'app/constants'
import PropTypes from 'prop-types'
import { useTranslations } from 'app/contexts/Translation/hooks'

function BuyingCaseShowStatuses(props) {
  const {
    isVotingEndedInDiffLocation,
    isVotingEndedInSameLocation,
    buyingCaseData,
    form,
    usersData,
    wizardProcessingData,
    agreementTemplate,
    tableData
  } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  return (
    <Row noGutters>
      {agreementTemplate?.length < 1 && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content">
          <Alert
            message={`${t(
              'No corresponding agreement template for your buying case type'
            )}. ${t('Contact to administrator or wait')}`}
            type="error"
          />
        </Col>
      )}
      {buyingCaseData?.attendees?.length <= 1 && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert
            message={t('To start wizard should be at least 2 buyers')}
            type="error"
          />
        </Col>
      )}
      {form?.length < 1 && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert
            message={`${t(
              'No corresponding wizard for your buying case type'
            )}.${t('Contact to administrator or wait')}`}
            type="error"
          />
        </Col>
      )}
      {usersData?.length !== buyingCaseData?.attendees?.length && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert
            type="error"
            message={`${_.xor(
              usersData?.map(({ email }) => email),
              buyingCaseData?.attendees
            )}
            - ${t("haven't activated yet")}.`}
          />
        </Col>
      )}
      {wizardProcessingData?.status === STATUSES.INIT && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert
            message={t('Wizard at the initialization stage')}
            type="warning"
          />
        </Col>
      )}
      {wizardProcessingData?.status === STATUSES.IN_PROGRESS && (
        <>
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert message={t('Wizard is in progress')} type="info" />
          </Col>
          {wizardProcessingData?.answers === null && (
            <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
              <Alert message={t('There are no answers yet')} type="info" />
            </Col>
          )}
        </>
      )}
      {wizardProcessingData?.status === STATUSES.AGREEMENT_CREATED && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert message={t('The agreement was created')} type="success" />
        </Col>
      )}
      {wizardProcessingData?.status === STATUSES.FINISHED_TESTING && (
        <>
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert
              message={t('Wizard is in the process of completing testing')}
              type="success"
            />
          </Col>
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert
              message={t("Agreement haven't created yet")}
              type="warning"
            />
          </Col>
        </>
      )}
      {wizardProcessingData?.status === undefined && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert message={t('Wizard has not started yet')} type="warning" />
        </Col>
      )}
      {wizardProcessingData?.isSameLocation === true &&
        wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert message={t('Users in same location')} type="warning" />
          </Col>
        )}
      {wizardProcessingData?.isSameLocation === false &&
        wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert message={t('Users in different location')} type="warning" />
          </Col>
        )}
      {!isVotingEndedInDiffLocation &&
        isVotingEndedInDiffLocation !== undefined && (
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert
              message={t(
                "Users can't create agreement, because voting in different location not ended"
              )}
              type="error"
            />
          </Col>
        )}
      {!isVotingEndedInSameLocation &&
        isVotingEndedInSameLocation !== undefined && (
          <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
            <Alert
              message={t(
                "Users can't create agreement, because voting in same location not ended"
              )}
              type="error"
            />
          </Col>
        )}
      {tableData?.length > 0 && wizardProcessingData?.isSameLocation === false && (
        <Col cw={'auto'} mr={3} mt={2} maxWidth="fit-content">
          <Alert
            message={t(
              "Users can't create agreement, because they have conflict in answers"
            )}
            type="error"
          />
        </Col>
      )}
    </Row>
  )
}

BuyingCaseShowStatuses.propTypes = {
  buyingCaseData: PropTypes.array.isRequired,
  form: PropTypes.array.isRequired,
  usersData: PropTypes.array.isRequired,
  wizardProcessingData: PropTypes.array.isRequired,
  isVotingEndedInDiffLocation: PropTypes.bool.isRequired,
  isVotingEndedInSameLocation: PropTypes.bool.isRequired,
  tableData: PropTypes.array.isRequired,
  agreementTemplate: PropTypes.array.isRequired
}

export default BuyingCaseShowStatuses
