import { Login, ForgotPassword, ResetPassword } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    user: false,
    name: 'Login',
    path: ROUTE_PATHS.LOGIN,
    exact: true,
    component: Login
  },
  {
    user: false,
    name: 'ForgotPassword',
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword
  },
  {
    user: true,
    name: 'ResetPassword',
    path: ROUTE_PATHS.RESET_PASSWORD,
    exact: true,
    component: ResetPassword
  }
]
