import { useState, useEffect } from 'react'
import { Text, Box } from '@qonsoll/react-design'
import {
  TeamOutlined,
  ShoppingOutlined,
  BuildOutlined,
  FileDoneOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useUI } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'

const MENU = [
  { name: 'Buyers', icon: TeamOutlined, key: ROUTE_PATHS.BUYERS_ALL },
  { name: 'Cases', icon: ShoppingOutlined, key: ROUTE_PATHS.BUYING_CASES_ALL },
  {
    name: 'Agreements',
    icon: FileDoneOutlined,
    key: ROUTE_PATHS.AGREEMENT_ALL
  },
  { name: 'Offers', icon: BuildOutlined, key: ROUTE_PATHS.OFFERS_ALL },
  { name: 'Settings', icon: SettingOutlined, key: ROUTE_PATHS.SETTINGS }
]

const BottomNavigation = (props) => {
  const { history } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const UI = useUI()
  // [COMPONENT_STATE_HOOKS]
  const [currentPage, setCurrentPage] = useState('')

  // [CLEAN_FUNCTIONS]
  const onItemClick = (key) => {
    setCurrentPage(key)
    history.push(key)
  }

  useEffect(() => {
    setCurrentPage(history.location.pathname)
  }, [history.location.pathname])

  return (
    <Box display="flex" mb={UI.state?.safeAreaInsets?.bottom} py={'6px'} px={2}>
      {MENU.map((item) => (
        <Box
          key={item.key}
          display="flex"
          flex={1}
          flexDirection="column"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={() => onItemClick(item.key)}>
          <Box display="flex" mb={1}>
            <item.icon
              style={{
                fontSize: 'var(--ql-font-size-h4)',
                color:
                  currentPage === item.key
                    ? 'var(--ql-color-accent1)'
                    : 'var(--menu-item-color)'
              }}
            />
          </Box>
          <Text
            fontSize="var(--ql-font-size-caption2)"
            lineHeight="var(--ql-line-height-caption2)"
            fontWeight="var(--ql-font-weight-medium)"
            letterSpacing="var(--letter-spacing-default)"
            color={
              currentPage === item.key
                ? 'var(--menu-horizontal-main-item-color-hover)'
                : 'var(--menu-item-color)'
            }
            textTransform="uppercase">
            {t(item.name)}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

export default withRouter(BottomNavigation)
