import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import parseStringToHtml from 'html-react-parser'

const convertStateToReactComponent = (editorState) => {
  // Get the current contents of the editor.
  const rawContentState = convertToRaw(editorState.getCurrentContent())

  // Convert draft state to string
  const markup = draftToHtml(rawContentState)

  // Convert string to valid html tags
  return parseStringToHtml(markup)
}

export default convertStateToReactComponent
