import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { List, Empty } from 'antd'
import { Row, Col, PageWrapper, Button, Input } from '@qonsoll/react-design'
import LocationView from 'domains/Location/components/LocationView'
import { PlusOutlined } from '@ant-design/icons'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { PageLoading } from '~/app/components'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useLocationsContext } from '~/app/contexts/Cache/hooks'
import useCachedData from '~/hooks/cache/useCachedData'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import useCache from '~/hooks/cache/useCache'
import _ from 'lodash'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'

function LocationsAll() {
  // [COMPONENT STATE HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const [locationsData, setLocationsData] = useState([])

  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const { state, dispatch } = useLocationsContext()
  useCache(
    getCollectionRef(COLLECTIONS.LOCATIONS).orderBy('id', 'asc').limit(20),
    state,
    dispatch
  )

  // [CLEAN FUNCTIONS]

  // TODO add search mechanism for cached list
  // !!!
  // const onLocationSearch = (event) => {
  //   const filteredLocations = collectionData.filter((item) =>
  //     item.name.toLowerCase().startsWith(event.target.value.toLowerCase())
  //   )
  //   setLocationsData(filteredLocations)
  // }

  const goToCreateLocation = () => {
    history.push(ROUTE_PATHS.LOCATION_CREATE)
  }

  return (
    <PageWrapper
      headingProps={{
        title: t('Locations'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}
      action={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={goToCreateLocation}
        />
      }
      isBottomSticky>
      {!_.isEmpty(state?.cachedCollectionData) ? (
        <Row noGutters>
          {/* <Col mb="24px">
            <Input
              placeholder={t('Enter name here')}
              allowClear
              onChange={onLocationSearch}
            />
          </Col> */}
          <Col cw={12}>
            <InfiniteCachedList
              limit={15}
              collectionName={COLLECTIONS.LOCATIONS}
              cache={state}
              setCache={dispatch}
              order={{ field: 'id', type: 'asc' }}>
              {(partners) => (
                <List
                  size="small"
                  dataSource={partners}
                  renderItem={(item) => <LocationView {...item} />}
                />
              )}
            </InfiniteCachedList>
          </Col>
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default LocationsAll
