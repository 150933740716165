const emailsForSendInvite = (dataFormForm, previousEmails) => {
  const emailsArray = dataFormForm?.map((item) => item.email)

  const emailsForInvite = emailsArray?.filter((item) => {
    return previousEmails?.indexOf(item) < 0
  })
  const result = [emailsArray, emailsForInvite]
  result.emailsArray = result[0]
  result.emailsForInvite = result[1]
  return result
}
export default emailsForSendInvite
