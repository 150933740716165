import { useHistory } from 'react-router-dom'
import { Button, List } from 'antd'
import { Container, Row, Col, PageWrapper, Text } from '@qonsoll/react-design'
import { RightOutlined } from '@ant-design/icons'
import auth from 'services/Firebase/auth'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useHomeButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { LanguageSelect } from 'domains/Translation/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { ThemeSwitch } from '~/app/components'

const MENU_ITEMS_MAP = [
  {
    title: 'Dark theme',
    Component: ThemeSwitch
  },
  {
    title: 'Administrators',
    route: ROUTE_PATHS.ADMINISTRATORS_ALL
  },
  {
    title: 'Languages',
    route: ROUTE_PATHS.TRANSLATIONS
  },
  {
    title: 'Offer types',
    route: ROUTE_PATHS.OFFER_TYPE_ALL
  },
  {
    title: 'Buying case types',
    route: ROUTE_PATHS.BUYING_CASE_TYPE_ALL
  },
  {
    title: 'Partners',
    route: ROUTE_PATHS.PARTNERS_ALL
  },
  {
    title: 'Locations',
    route: ROUTE_PATHS.LOCATIONS_ALL
  },
  {
    title: 'Wizards',
    route: ROUTE_PATHS.WIZARD_ALL
  },
  {
    title: 'Agreement templates',
    route: ROUTE_PATHS.AGREEMENT_TEMPLATE_ALL
  },
  {
    title: 'Cookie Policy',
    route: ROUTE_PATHS.GDPR_PAGE
  }
]

function Settings() {
  // [ADDITIONAL_HOOKS]
  useHomeButton()
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN FUNCTIONS]
  const onLogoutClick = async () => {
    await auth.signOut()
    localStorage.setItem('logout', 'true')
  }

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Settings'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      action={<LanguageSelect />}>
      <Container height="inherit" display="grid">
        <Row noGutters>
          <Col cw={12} display="grid" mb={3}>
            <List
              dataSource={MENU_ITEMS_MAP}
              renderItem={(item) => (
                <List.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(item.route)}
                  actions={[
                    item?.Component ? (
                      <item.Component />
                    ) : (
                      <RightOutlined
                        style={{
                          fontSize: 'var(--ql-font-size-h3)'
                        }}
                      />
                    )
                  ]}>
                  <Text fontSize={'var(--ql-font-size-body1)'}>
                    {t(item.title)}
                  </Text>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row noGutters mt="auto">
          <Col cw={12}>
            <Button block onClick={onLogoutClick}>
              {t('Logout')}
            </Button>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default Settings
