export const USERS = 'users'
export const ACTIVITIES = 'activities'
export const PARTNERS = 'partners'
export const BUYING_CASES = 'buyingCases'
export const AGREEMENTS = 'agreements'
export const AGREEMENT_TEMPLATES = 'agreementTemplates'
export const OFFERS = 'offers'
export const TRANSLATIONS = 'translations'
export const OFFER_TYPES = 'offerTypes'
export const LOCATIONS = 'locations'
export const CHATS = 'chats'
export const PARTICIPANTS = 'participants'
export const FORMS = 'forms'
export const BUYING_CASE_TYPES = 'buyingCaseTypes'
export const WIZARD_PROCESSING = 'wizardProcessing'
export const VOTING_DATA = 'votingData'
export const USER_DEVICES = 'userDevices'
export const QUESTIONS = 'questions'
export const NOTIFICATIONS = 'notifications'
