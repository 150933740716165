import {
  AgreementTemplateAll,
  AgreementTemplateCreate,
  AgreementTemplateEdit,
  AgreementTemplateWithCustomBlocksCreate,
  AgreementTemplateWithCustomBlocksEdit
} from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'AgreementTemplateAll',
    path: ROUTE_PATHS.AGREEMENT_TEMPLATE_ALL,
    exact: true,
    component: AgreementTemplateAll
  },
  {
    name: 'AgreementTemplateCreate',
    path: ROUTE_PATHS.AGREEMENT_TEMPLATE_CREATE,
    exact: true,
    component: AgreementTemplateWithCustomBlocksCreate
  },
  {
    name: 'AgreementTemplateEdit',
    path: ROUTE_PATHS.AGREEMENT_TEMPLATE_EDIT,
    exact: true,
    component: AgreementTemplateWithCustomBlocksEdit
  }
]
