import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { createDocument } from 'services/Firebase/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { OfferTypeForm } from 'domains/OfferType/components'
import { COLLECTIONS } from 'app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'

function OfferTypeCreate() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPONENT_STATE_HOOKS]
  const [createLoading, setCreateLoading] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onFinish = async (value) => {
    setCreateLoading(true)
    try {
      await createDocument(COLLECTIONS.OFFER_TYPES, value)
      message.success(t('Offer type successfully created'))
      history.push(ROUTE_PATHS.OFFER_TYPE_ALL)
    } catch (error) {
      console.log(error)
      message.error(t('Error occurred during offer editing'))
    }
    setCreateLoading(false)
  }

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Create offer type'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}>
      <Container height="inherit" display="grid">
        <Row noGutters h="center">
          <Col cw={[12, 10, 8, 7, 6]} display="grid">
            <OfferTypeForm onFinish={onFinish} loading={createLoading} />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default OfferTypeCreate
