import React, { useState } from 'react'
import { Button, Form, Input, message, Space } from 'antd'
import { Col, Row, Card, Edit, Text } from '@qonsoll/react-design'
import { CheckOutlined } from '@ant-design/icons'
import { deleteDocument, updateDocument } from '~/services/Firebase/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { Remove } from 'app/components'
import { COLLECTIONS } from 'app/constants'
import firebase from '~/services/Firebase/init'
import { useLocationsContext } from '~/app/contexts/Cache/hooks'

const LocationListItem = (item) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()
  const { t } = useTranslations()
  const { dispatch } = useLocationsContext()

  // [COMPONENT_STATE_HOOKS]
  const [isEdit, setIsEdit] = useState(false)
  const [editLoading, setEditLoading] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onRemove = async () => {
    try {
      const functionsSetNullLocationId = firebase
        .functions()
        .httpsCallable('setNullLocationId', { timeout: 0 })
      const functionSendNotificationToUsers = firebase
        .functions()
        .httpsCallable('createNotificationToAllUserWithRemovedLocationId', {
          timeout: 0
        })

      //send notification for all users where locationId === removed id
      await functionSendNotificationToUsers({ id: item?.id })

      //set null to field locationId in collection users where locationId === removed id
      await functionsSetNullLocationId({ id: item?.id })

      // delete document location
      await deleteDocument(COLLECTIONS.LOCATIONS, item?.id)
      message.success(t('Location was deleted successfully'))
      dispatch({ type: 'REMOVE_DOC', payload: item?.id })
    } catch (error) {
      message.error(t("Location wasn't deleted"))
    }
  }

  const onFinish = async (values) => {
    if (values?.name !== item?.name) {
      setEditLoading(true)
      try {
        await updateDocument(COLLECTIONS.LOCATIONS, item?.id, values)
        message.success(t('Location was edited successfully'))
      } catch (error) {
        message.error(t('Error occurred during location edit'))
        console.log(error.message)
      }
      setEditLoading(false)
    }
    setIsEdit(false)
  }
  return (
    <Card mb={3} size="small" bordered={false} shadowless>
      <Row noOuterGutters h="center" v="center">
        <Col minWidth={0}>
          {isEdit ? (
            <Form
              onFinish={onFinish}
              form={form}
              initialValues={{ name: item?.name }}>
              <Form.Item
                name="name"
                style={{ marginBottom: 0 }}
                rules={[
                  { required: true, message: t('Enter location name please') },
                  {
                    max: 100,
                    message: t('Name should be less than 100 character')
                  }
                ]}>
                <Input autoFocus />
              </Form.Item>
            </Form>
          ) : (
            <Text isEllipsis>{item?.name}</Text>
          )}
        </Col>
        <Col cw="auto">
          <Space>
            {isEdit ? (
              <Button
                onClick={form.submit}
                icon={<CheckOutlined />}
                loading={editLoading}
              />
            ) : (
              <Edit
                type="text"
                onClick={() => setIsEdit(true)}
                tooltip={t('Edit')}
                icon
              />
            )}

            <Remove
              type="text"
              popconfirmPlacement="bottomRight"
              itemName={t('location')}
              onSubmit={onRemove}
              icon
            />
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default LocationListItem
