import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory, generatePath } from 'react-router-dom'
import { Skeleton } from 'antd'
import { Row, Col, Box, Avatar, Text } from '@qonsoll/react-design'
import { UserOutlined } from '@ant-design/icons'
import { useDocumentDataOnce } from '~/hooks/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { getCollectionRef, getTimestamp } from '~/services/Firebase/firestore'
import { parseDescription } from 'domains/RecentActivity/helpers'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'

const getTimeout = (seed) => {
  const TIME_DIFF_MAP = {
    1: 1000,
    2: 30000,
    10: 60000,
    60: 600000,
    1439: 600000,
    1440: 1440000
  }
  const timeDiff = Math.abs(moment(seed).diff(new Date(), 'minutes'))

  const settings = Object.keys(TIME_DIFF_MAP).reduce((prev, curr) =>
    Math.abs(curr - timeDiff) < Math.abs(prev - timeDiff) ? curr : prev
  )

  return TIME_DIFF_MAP[settings]
}

const SKELETON_CONFIG = {
  sm: { rows: 2 },
  md: { rows: 1, style: { margin: 0 } },
  lg: { rows: 1, style: { margin: 0 } },
  xl: { rows: 1, style: { margin: 0 } },
  xxl: { rows: 1, style: { margin: 0 } }
}

function RecentActivityView(props) {
  const { date, trigerrer, company, timeFormat = true, loading } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const rowConfig = useResponsiveBreakpoints(SKELETON_CONFIG)
  const [userData, userDataLoading] = useDocumentDataOnce(
    getCollectionRef(COLLECTIONS.USERS).doc(trigerrer)
  )
  const [companyData, companyLoading] = useDocumentDataOnce(
    getCollectionRef(COLLECTIONS.PARTNERS).doc(company)
  )

  // [COMPONENT_STATE_HOOKS]
  const [formattedDate, setFormattedDate] = useState(
    timeFormat
      ? moment(date?.toDate()).fromNow()
      : moment(date?.toDate()).format('LT')
  )

  //[COMPUTED_PROPERTIES]
  const description =
    !loading &&
    (trigerrer
      ? !userDataLoading &&
        parseDescription({ ...props, trigerrer: userData }, t)
      : !companyLoading &&
        parseDescription({ ...props, company: companyData }, t))

  // [CLEAN_FUNCTIONS]
  const onUserClick = () => {
    trigerrer
      ? userData?.id &&
        history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: userData?.id }))
      : history.push(
          generatePath(ROUTE_PATHS.PARTNER_PROFILE, { id: companyData?.id })
        )
  }

  // [USE_EFFECTS]
  useEffect(() => {
    const updateTime = () =>
      setFormattedDate(
        timeFormat
          ? moment(date?.toDate()).fromNow()
          : moment(date?.toDate()).format('LT')
      )

    const timer = setInterval(updateTime, getTimeout(date?.toDate()))

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row noGutters>
      <Col ml={3} cw="auto">
        <Box
          onClick={onUserClick}
          height="fit-content"
          border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
          borderRadius="var(--ql-border-radius-full)"
          style={{
            cursor: userData || companyData ? 'pointer' : 'not-allowed'
          }}>
          {userDataLoading || companyLoading || loading ? (
            <Skeleton.Avatar style={{ margin: '2px' }} active />
          ) : (
            <Avatar
              alt="avatar"
              m="2px"
              icon={<UserOutlined />}
              src={userData?.avatarURL ?? companyData?.logoURL}
            />
          )}
        </Box>
      </Col>
      <Col ml={4} mr={3} v="center">
        <Skeleton
          title={false}
          paragraph={rowConfig}
          loading={userDataLoading || loading}
          active>
          <Text fontSize="var(--ql-font-size-body1)">{description}</Text>
        </Skeleton>
      </Col>
      <Col cw="auto" pt="3px" style={{ alignItems: 'center' }}>
        <Text type="secondary" variant="body2" minWidth={32} textAlign="right">
          {!loading && formattedDate}
        </Text>
      </Col>
    </Row>
  )
}

RecentActivityView.propTypes = {
  date: PropTypes.instanceOf(getTimestamp()),
  description: PropTypes.string,
  trigerrer: PropTypes.string,
  loading: PropTypes.bool
}

export default RecentActivityView
