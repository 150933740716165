import PropTypes from 'prop-types'
import { Box, Title } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'

const AgreementTemplatePreview = (props) => {
  const { header, footer } = props

  //[ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <>
      <Title level={3} mb={2} textAlign="center">
        {t('Result')}
      </Title>
      <Box
        flex={1}
        borderRadius="var(--ql-border-radius-16)"
        bg="var(--agreement-template-preview-bg)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between">
        <Box>{header}</Box>
        <Box display="flex" justifyContent="center" alignContent="center">
          [AnswersView]
        </Box>
        <Box>{footer}</Box>
      </Box>
    </>
  )
}

AgreementTemplatePreview.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node
}

export default AgreementTemplatePreview
