import React from 'react'
import { PageWrapper } from '@qonsoll/react-design'
//import { EyeInvisibleOutlined } from '@ant-design/icons'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useCollectionData } from '~/hooks/firestore'
import { PageLoading, InfiniteList } from 'app/components'
import { RecentActivitiesList } from 'domains/RecentActivity/components'
import { COLLECTIONS } from 'app/constants'
import { useTranslations } from 'app/contexts/Translation/hooks'

const TITLE_SIZE_CONFIG = {
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3
}

function RecentActivitiesAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const [initialActivities, loading] = useCollectionData(
    getCollectionRef(COLLECTIONS.ACTIVITIES).orderBy('date', 'desc').limit(25)
  )
  const titleSizes = useResponsiveBreakpoints(TITLE_SIZE_CONFIG)
  // [CLEAN FUNCTIONS]
  //  const onClickActions = () => {}

  return (
    <PageLoading loading={loading}>
      <PageWrapper
        headingProps={{
          title: t('All activities'),
          titleSize: titleSizes,
          marginBottom: '14px'
        }}
        onBack={goBack}
        // action={
        // <Button
        //   mr={-12}
        //   type="text"
        //   icon={<EyeInvisibleOutlined />}
        //   onClick={onClickActions}
        // />
        // }
      >
        <InfiniteList
          initialData={initialActivities}
          limit={15}
          collectionName={COLLECTIONS.ACTIVITIES}
          order={{ field: 'date', type: 'desc' }}>
          {(activities) => <RecentActivitiesList data={activities} />}
        </InfiniteList>
      </PageWrapper>
    </PageLoading>
  )
}

export default RecentActivitiesAll
