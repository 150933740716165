import { Select } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import PropTypes from 'prop-types'

function LocationSelect(props) {
  const { locations, ...rest } = props

  //[ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Select
      placeholder={t('Location')}
      options={locations?.map((item) => ({
        value: item?.id,
        key: item?.id,
        label: item?.name
      }))}
      {...rest}
    />
  )
}

LocationSelect.propTypes = {
  locations: PropTypes.array.isRequired,
  onChange: PropTypes.func
}

export default LocationSelect
