import { Collapse } from 'antd'
import {
  TextEditor,
  CustomBlockForAnswerItem
} from 'domains/AgreementTemplate/components'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'

const { Panel } = Collapse

function AgreementTemplateCollapsibleEditor(props) {
  const { questionData, onAnswerOptionChange, onTextEditorChange, formState } =
    props

  const { t } = useTranslations()

  return (
    <>
      <Title level={3} textAlign="center" mb={2}>
        {t('Editor')}
      </Title>
      <Collapse>
        <Panel header={t('Header')}>
          <TextEditor
            onChange={(value) => onTextEditorChange(value, 'header')}
            state={formState?.header?.state}
          />
        </Panel>
        {questionData?.map((item, index) => (
          <Panel header={item.title} key={`question${index}`}>
            {item?.answers?.map((answerItem, ansIndex) => (
              <CustomBlockForAnswerItem
                key={`answer${index}${ansIndex}`}
                onBlur={(values) =>
                  onAnswerOptionChange(
                    `question${index}`,
                    item.id,
                    item.title,
                    answerItem?.answerOption || answerItem,
                    values.target.value
                  )
                }
                answerOption={answerItem?.answerOption || answerItem}
                questionTitle={item.title}
                defaultValue={answerItem?.legalText}
              />
            ))}
          </Panel>
        ))}
        <Panel header={t('Footer')}>
          <TextEditor
            onChange={(value) => onTextEditorChange(value, 'footer')}
            state={formState?.footer?.state}
          />
        </Panel>
      </Collapse>
    </>
  )
}

export default AgreementTemplateCollapsibleEditor
