import { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { Timeline } from 'antd'
import { Box } from '@qonsoll/react-design'
import { RecentActivityView } from 'app/domains/RecentActivity/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { DateLabel } from '~/app/components'

const RecentActivitiesList = (props) => {
  const { data } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [COMPONENT_STATE_HOOKS]
  const [timeline, setTimeline] = useState(() => ({}))

  // [COMPUTED_PROPERTIES]
  const DAY_DIFF_MAP = {
    0: t('Today'),
    1: t('Yesterday')
  }

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true
    const buf = { ...timeline }

    data.forEach((activity) => {
      const actDate = activity.date.toDate()
      const daysDiff = moment(new Date().setHours(23, 59, 59, 59)).diff(
        actDate,
        'days'
      )
      if (DAY_DIFF_MAP[daysDiff]) {
        buf[DAY_DIFF_MAP[daysDiff]] = buf[DAY_DIFF_MAP[daysDiff]]?.length
          ? _.orderBy(
              _.unionBy([...buf[DAY_DIFF_MAP[daysDiff]], activity], 'id'),
              'date',
              'desc'
            )
          : [activity]
      } else {
        const formattedDate = moment(actDate).format('DD MMM YYYY')
        buf[formattedDate] = buf[formattedDate]?.length
          ? _.orderBy(
              _.unionBy([...buf[formattedDate], activity], 'id'),
              'date',
              'desc'
            )
          : [activity]
      }
    })
    isComponentMounted && setTimeline(buf)
    return () => {
      isComponentMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Box mt={2}>
      {Object.keys(timeline).map((date) => (
        <Fragment key={date}>
          <DateLabel text={date} />

          <Timeline>
            {timeline[date]?.map((item) => (
              <Timeline.Item key={item.id}>
                <RecentActivityView {...item} timeFormat={date === 'Today'} />
              </Timeline.Item>
            ))}
          </Timeline>
        </Fragment>
      ))}
    </Box>
  )
}

RecentActivitiesList.propTypes = {
  data: PropTypes.array.isRequired
}

export default RecentActivitiesList
