import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Box,
  Text,
  Avatar,
  Card,
  Button
} from '@qonsoll/react-design'
import { UserOutlined, PhoneOutlined } from '@ant-design/icons'
import { generatePath, useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'

function UserAdvancedView(props) {
  const { id, withCall, onCallClick, name, avatarURL, phone } = props

  // [ADDITIONAL HOOKS]
  const history = useHistory()

  // [CLEAN FUNCTIONS]
  const onAvatarClick = () => {
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))
  }

  return (
    <Card
      style={{ cursor: 'pointer' }}
      mb={3}
      size="small"
      bordered={false}
      shadowless
      onClick={onAvatarClick}>
      <Row noGutters h="center" v="center">
        <Col cw="auto" mr={3}>
          <Box
            height="fit-content"
            border="var(--outline-width) var(--ql-border-style-default) var(--dark-transparent-7)"
            borderRadius="50%">
            <Avatar m="2px" icon={<UserOutlined />} src={avatarURL} />
          </Box>
        </Col>
        <Col minWidth={0}>
          <Text isEllipsis>{name}</Text>
        </Col>
        {withCall && phone && (
          <Col cw="auto" ml={3}>
            <Button
              href={`tel:${phone}`}
              onClick={(e) => {
                e.stopPropagation()
              }}
              type="primary"
              icon={
                <PhoneOutlined style={{ fontSize: 24 }} onClick={onCallClick} />
              }
            />
          </Col>
        )}
      </Row>
    </Card>
  )
}

UserAdvancedView.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  withCall: PropTypes.bool,
  onCallClick: PropTypes.func,
  avatarURL: PropTypes.string,
  phone: PropTypes.string
}

export default UserAdvancedView
