import React, { useCallback, useEffect, useState } from 'react'
import { message, Form } from 'antd'
import { Container, Row, Col, PageWrapper } from '@qonsoll/react-design'
import { UserForm } from 'domains/User/components'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { COLLECTIONS } from '~/app/constants'
import { PageLoading } from '~/app/components'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { getCollectionRef, updateDocument } from '~/services/Firebase/firestore'
import { getReferenceChild } from '~/services/Firebase/database'
import {
  useDocumentData,
  useCollectionData
} from 'react-firebase-hooks/firestore'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import _ from 'lodash'
import { deleteURL } from '~/services/Firebase/storage'

function UserEdit() {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const history = useHistory()
  const { id } = useParams()
  const [userData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.USERS).doc(id)
  )
  const [locationsData, loadingLocations] = useCollectionData(
    getCollectionRef(COLLECTIONS.LOCATIONS)
  )
  const [buyingCaseTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )

  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPONENT STATE HOOKS]
  const [formLoading, setFormLoading] = useState(false)
  const [diffImage, setDiffImage] = useState([''])

  // [CLEAN FUNCTIONS]
  const onFinish = async (values) => {
    const initialValues = {
      avatarURL: userData?.avatarURL,
      phone: userData?.phone,
      firstName: userData?.firstName,
      secondName: userData?.secondName,
      locationId: userData?.locationId,
      buyingTypeId: userData?.buyingTypeId,
      isVisible: userData?.isVisible,
      aboutMe: userData?.aboutMe?.trim()
    }
    //remove all undefined filed in object for equal in next logic step
    // (because buyer fields and admin and partner are different)
    Object.keys(initialValues).forEach(
      (key) => initialValues[key] === undefined && delete initialValues[key]
    )
    /**
     * if initial data doesn't equal new data,
     * new data will set to data base
     */
    if (initialValues?.avatarURL !== values?.avatarURL) {
      //delete prev image with best pixels
      await deleteURL(initialValues?.avatarURL).catch((error) => {
        console.log(error.message)
      })
    }
    if (!_.isEqual(initialValues, values)) {
      setFormLoading(true)
      try {
        //pop the last url for not remove from storage last upload image
        setDiffImage(...diffImage?.pop())
        await updateDocument(COLLECTIONS.USERS, userData.id, {
          ...values,
          phone:
            userData?.phone === values.phone
              ? userData.phone
              : `+${values.phone}`
        })
        if (userData?.role === 'YHB' && userData?.isParticipant) {
          await getReferenceChild(
            `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${id}`
          ).update({
            displayName: `${values.firstName} ${values.secondName}`,
            phone:
              userData?.phone === values.phone
                ? userData.phone
                : `+${values.phone}`,
            avatarURL: values?.avatarURL || ''
          })
        }
        message.success(t('User was edited successfully'))
      } catch (error) {
        message.error(t('Error occurred during user edit'))
        message.error(error.message)
        setFormLoading(false)
        return
      }
      setFormLoading(false)
    }
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))
  }
  const checkIfUserSaveNewAvatar = useCallback(async () => {
    if (Array.isArray(diffImage)) {
      for (const image of diffImage) {
        if (image !== userData?.avatarURL) {
          image?.length > 0 &&
            (await deleteURL(image).catch((error) => {
              console.log(error.message)
            }))
        }
      }
    }
  }, [diffImage])

  const goBack = useBackButton({
    returnCallback: backButtonVisibility,
    callback: checkIfUserSaveNewAvatar
  })

  // [USE_EFFECTS]
  useEffect(() => {
    return () => {
      checkIfUserSaveNewAvatar()
    }
  }, [diffImage])

  return (
    <PageLoading
      loading={loading || loadingLocations || loadingBuyingCaseTypes}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Edit profile'),
          titleSize: titleSizes,
          titleMarginBottom: 2,
          subTitle: t('Change personal information as you wish'),
          textAlign: 'left',
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <UserForm
                form={form}
                diffImage={diffImage}
                setDiffImage={setDiffImage}
                locationsData={locationsData}
                buyingCaseTypes={buyingCaseTypes}
                initialValues={userData}
                onFinish={onFinish}
                loading={formLoading}
                setFormLoading={setFormLoading}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default UserEdit
