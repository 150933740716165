import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@qonsoll/react-design'
import { Spinner } from 'app/components'

const StyledBackdrop = styled(Box)`
  display: flex;
  align-items: center;
  background-color: var(--dark-transparent-1);
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const Backdrop = (props) => (
  <StyledBackdrop>
    <Spinner
      text={props.spinnerText}
      {...props}
      textStyles={{ color: 'var(--white-transparent-1)' }}
    />
  </StyledBackdrop>
)

Backdrop.propTypes = {
  spinnerText: PropTypes.string
}

export default Backdrop
