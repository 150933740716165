const UIReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE': {
      return { ...state, ...action.payload }
    }
    case 'SET_WITH_BACK_BUTTON': {
      return { ...state, onBack: action.payload }
    }
    case 'SET_WITH_HOME_BUTTON': {
      return { ...state, onHome: action.payload }
    }
    default: {
      return state
    }
  }
}

export default UIReducer
