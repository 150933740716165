import { Box, Text } from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslations } from 'app/contexts/Translation/hooks'

const UploadButton = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ cursor: 'pointer' }}
      bg="var(--btn-upload-bg)"
      border="var(--ql-border-width-2) var(--ql-border-style-dashed) var(--btn-upload-border-color)"
      borderRadius="var(--ql-border-radius-8)"
      py={64}>
      <PlusOutlined style={{ fontSize: 'var(--ql-font-size-h3)' }} />
      <Text fontWeight="var(--ql-font-weight-medium)" ml={3}>
        {t('Upload images')}
      </Text>
    </Box>
  )
}
export default UploadButton
