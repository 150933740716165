import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, generatePath } from 'react-router-dom'
import { List } from 'antd'
import { Row, Col, Card, Text } from '@qonsoll/react-design'
import BuyingCaseAttendeesList from '../BuyingCaseAttendeesList'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function BuyingCasesList(props) {
  const { data } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()

  // [CLEAN FUNCTIONS]
  const onItemSelected = () => {}
  const onItemClick = (id) => {
    history.push(generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, { id }))
  }
  const onChange = () => {}

  return (
    <List
      dataSource={data}
      onChange={onChange}
      renderItem={(item, index) => (
        <Card
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => onItemClick(item.id)}
          onSelect={onItemSelected}
          mb={3}
          size="small"
          bordered={false}
          shadowless>
          <Row noGutters h="center" v="center">
            <Col minWidth={0} mr={3}>
              <Text isEllipsis>{item.name}</Text>
            </Col>
            <Col cw="auto">
              <BuyingCaseAttendeesList
                data={item.attendees}
                size="large"
                t={t}
              />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

BuyingCasesList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      attendees: PropTypes.arrayOf(PropTypes.string)
    })
  )
}

export default BuyingCasesList
