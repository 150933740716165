import firebase from '~/services/Firebase/init'
import { message } from 'antd'

const inviteBuyer = async (email, t) => {
  try {
    const func = firebase
      .functions()
      .httpsCallable('sendBuyingCaseInvitation', { timeout: 0 })
    await func({
      email,
      link: `${process.env.REACT_APP_BUYER_HOSTNAME}/sign-up`
    })
  } catch (err) {
    console.log(err)
    message.error(t('Error occurred during invitation of') + ` ${email}`)
  }
}

export default inviteBuyer
