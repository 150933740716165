import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { OfferForm } from 'domains/Offer/components'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import {
  createDocument,
  getCollectionRef,
  getTimestamp
} from '~/services/Firebase/firestore'
import { writeLog } from '~/helpers'
import { PageLoading } from '~/app/components'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useCollectionData } from '~/hooks/firestore'
import firebase from '~/services/Firebase/init'

function OfferCreate() {
  // [COMPONENT STATE HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { state } = useSessionContext()
  const { t } = useTranslations()
  const [offersTypes, loadingOffersType] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFER_TYPES)
  )
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN FUNCTIONS]
  const onOfferCreate = async (values, type) => {
    setLoading(true)
    const images_500 = []
    const images_1000 = []
    const inputSize = '500x500,1000x1000'
    const func = await firebase
      .functions()
      .httpsCallable('resizeImages', { timeout: 0 })
    const images = values?.images?.map((item) => item.url)
    for (const image of images) {
      const responseData = await func({
        url: image,
        fileName: image,
        inputSize: inputSize
      })
      const arrayURL = responseData.data.arrayURL
      images_500.push(arrayURL[0])
      images_1000.push(arrayURL[1])
    }
    try {
      const offer = await createDocument(COLLECTIONS.OFFERS, {
        ...values,
        description: values.description.trim(),
        images,
        images_500,
        images_1000,
        phone: `+${values.phone}`,
        offerTypeId: type.id
      })
      message.success(t('Successfully created offer'))
      writeLog(
        `{trigerrer} created {offer}`,
        {
          trigerrer: state.id,
          date: getTimestamp().fromDate(new Date()),
          offer: offer.id
        },
        COLLECTIONS.ACTIVITIES
      )
      setLoading(false)
      history.push(ROUTE_PATHS.OFFERS_ALL)
    } catch (err) {
      console.log(err)
      setLoading(false)
      message.error(t('Error occurred during offer creation'))
    }
  }

  return (
    <PageLoading loading={loading || loadingOffersType}>
      <PageWrapper
        headingProps={{
          title: t('Create offer'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        isBottomSticky>
        <Row noGutters h="center">
          <Col cw={[12, 10, 8, 7, 6]} display="grid">
            <OfferForm onFinish={onOfferCreate} offersTypes={offersTypes} />
          </Col>
        </Row>
      </PageWrapper>
    </PageLoading>
  )
}

export default OfferCreate
