const ROUTE_PATHS = {
  404: '/404',
  ADMINISTRATOR_INVITE: '/administrator/invite',
  ADMINISTRATORS_ALL: '/administrators',
  AGREEMENT_ALL: '/agreements',
  AGREEMENT_TEMPLATE_ALL: '/agreement-templates',
  AGREEMENT_TEMPLATE_EDIT: '/agreement-templates/:id/edit',
  AGREEMENT_TEMPLATE_CREATE: '/agreement-template/new',
  ACCESS_DENIED: '/403',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  BUYERS_ALL: '/buyers',
  BUYING_CASE_EDIT: '/buying-cases/:id/edit',
  BUYING_CASE_INVITE_ATTENDEES_EDIT: '/buying-cases/:id/invite/edit',
  BUYING_CASE_SHOW_BUYER_ANSWERS: '/buying-cases/:id/wizard-result/:buyerId',
  BUYING_CASES_ALL: '/buying-cases',
  BUYING_CASE_SHOW: '/buying-cases/:id',
  OFFER_TYPE_CREATE: '/offer-type/new',
  OFFER_TYPE_EDIT: '/offer-types/:id/edit',
  OFFER_TYPE_ALL: '/offer-types',
  OFFER_CREATE: '/offer/new',
  OFFER_SHOW: '/offers/:id',
  OFFERS_ALL: '/offers',
  OFFER_EDIT: '/offers/:id/edit',
  PARTNER_CREATE: '/partner/new',
  PARTNER_PROFILE: '/partners/:id',
  PARTNER_EDIT: '/partners/:id/edit',
  PARTNERS_ALL: '/partners',
  RECENT_ACTIVITIES_ALL: '/recent-activities',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_SHOW: '/users/:id',
  USER_EDIT: '/users/:id/edit',
  TRANSLATIONS: '/translations',
  LOCATIONS_ALL: '/locations',
  LOCATION_CREATE: '/location/new',
  LOCATION_EDIT: '/locations/:id/edit',
  WIZARD_SHOW: '/wizard/:id',
  WIZARD_EDIT: '/wizard/:id/edit',
  WIZARD_CREATE: '/wizard/new',
  WIZARD_ALL: '/wizards',
  BUYING_CASE_TYPE_ALL: '/buying-case-types',
  BUYING_CASE_TYPE_CREATE: '/buying-case-types/new',
  BUYING_CASE_TYPE_EDIT: '/buying-case-types/:id/edit',
  GDPR_PAGE: '/cookie-policy'
}

export default ROUTE_PATHS
