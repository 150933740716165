import ROUTE_PATHS from 'domains/allRoutePath'
import { generatePath, Link } from 'react-router-dom'

const parseDescription = (args, t) => {
  const { description } = args

  const FLAGS_MAP = {
    offer: { route: ROUTE_PATHS.OFFER_SHOW, text: () => t('offer') },
    trigerrer: {
      route: ROUTE_PATHS.USER_SHOW,
      text: ({ firstName, secondName }) => `${firstName} ${secondName}`
    },
    company: {
      route: ROUTE_PATHS.PARTNER_PROFILE,
      text: ({ partnerName }) => partnerName
    }
  }

  // Find all flags that inside {<flag>}.
  const flags = [...description.matchAll(/\{.*?\}/g)]
    .flat()
    // eslint-disable-next-line no-useless-escape
    .map((item) => item.replace(/[\{\}]/g, ''))

  let bufDesc = description
  let bufArr = []

  // Iterate through flags to paste Links instead of it.
  flags.forEach((flag) => {
    bufArr = [...bufArr, ...bufDesc.split(`{${flag}}`)]

    // Set to bufDesc not parsed part of description.
    bufDesc = bufArr[bufArr.length - 1]
    // Go through array where ""(empty string) is the item where should be Link.
    bufArr.forEach((item, index) => {
      if (!item) {
        bufArr[index] =
          args[flag]?.id || args[flag] ? (
            <Link
              to={generatePath(FLAGS_MAP[flag].route, {
                id: args[flag]?.id ?? args[flag]
              })}>
              {FLAGS_MAP[flag].text(args[flag])}
            </Link>
          ) : (
            <Link to={ROUTE_PATHS[404]}>{t('Deleted user')}</Link>
          )
      }
    })
  })

  // Clear bufArr from not parsed parts of description, because I can't delete it while parse description.
  flags.forEach((flag) => {
    bufArr = bufArr.filter((desc) => !desc.includes?.(`{${flag}}`))
  })

  const bufBufArr = []

  bufArr.forEach((item) => {
    if (typeof item === 'string') {
      if (item.startsWith(' ')) {
        bufBufArr.push(' ')
      }
      bufBufArr.push(item.trim())
      if (item.endsWith(' ')) {
        bufBufArr.push(' ')
      }
    } else {
      bufBufArr.push(item)
    }
  })
  bufArr = bufBufArr.map((text) =>
    typeof text === 'string' && text !== ' ' ? t(text) : text
  )
  return <>{bufArr.length ? bufArr : description}</>
}

export default parseDescription
