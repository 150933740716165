import { AgreementAll } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'AgreementAll',
    path: ROUTE_PATHS.AGREEMENT_ALL,
    exact: true,
    component: AgreementAll
  }
]
