import { WizardEdit, WizardShow, WizardsAll } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'WizardEdit',
    path: ROUTE_PATHS.WIZARD_EDIT,
    exact: true,
    component: WizardEdit
  },
  {
    name: 'WizardShow',
    path: ROUTE_PATHS.WIZARD_SHOW,
    exact: true,
    component: WizardShow
  },
  {
    name: 'WizardsAll',
    path: ROUTE_PATHS.WIZARD_ALL,
    exact: true,
    component: WizardsAll
  }
]
