import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { PageWrapper } from '@qonsoll/react-design'
import auth from '~/services/Firebase/auth'
import { PageLoading } from '~/app/components'
import { LoginForm } from 'domains/Session/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

function Login() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [COMPONENT_STATE_HOOKS]
  const [loginLoading, setLoginLoading] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onLogin = async (values) => {
    setLoginLoading(true)
    try {
      await auth.signInWithEmailAndPassword(values.email, values.password)
    } catch (err) {
      console.error(err)
      message.error(t('Incorrect login or password'))
    }
    setLoginLoading(false)
  }
  const onForgotPassword = () => {
    history.push(ROUTE_PATHS.FORGOT_PASSWORD)
  }

  return (
    <PageLoading loading={loginLoading} height="inherit">
      <PageWrapper
        alignMiddle
        headingProps={{
          title: t('Welcome back'),
          titleSize: titleSizes,
          textAlign: 'center',
          marginBottom: 24
        }}
        contentWidth={['100%', '100%', 400]}>
        <LoginForm onFinish={onLogin} onForgotPassword={onForgotPassword} />
      </PageWrapper>
    </PageLoading>
  )
}

export default Login
