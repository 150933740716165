import { PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

const GDPRPage = () => {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPUTED_PROPERTIES]
  const gdpr = JSON.parse(localStorage.getItem('gdpr'))

  return (
    <PageWrapper
      headingProps={{
        title: t('Cookie Policy'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}>
      {gdpr && 'You agreed with Cookie Policy'}
    </PageWrapper>
  )
}

export default GDPRPage
