import moment from 'moment'
import 'moment/locale/nb'

export default () => {
  const LCLanguage = localStorage.getItem('language')
  const language = LCLanguage === 'no' ? 'nb' : LCLanguage
  moment.locale(language)
  moment.updateLocale(language, {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: '%ds',
      ss: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
      w: '%dw',
      ww: '%dw',
      M: '%dmo',
      MM: '%dmo',
      y: '%dy',
      yy: '%dy'
    }
  })
}
