import React, { useEffect, useState } from 'react'
import database from 'services/Firebase/database'
import TranslationsContext from 'app/contexts/Translation/context'
import {
  CURRENT_APP,
  LANGUAGES,
  DEFAULT_LANGUAGE
} from 'app/contexts/Translation/__constants__'
import { COLLECTIONS } from '~/app/constants'

const TranslationsProvider = (props) => {
  const LSLang = window?.localStorage.getItem('language')
  const { children } = props

  // STATES
  const [languageSwitch, setLanguageSwitch] = useState(false)
  // State that indicates current language
  const [language, setLanguage] = useState(LSLang || DEFAULT_LANGUAGE)
  // State that indicates downloaded translations from the DB
  const [translations, setTranslations] = useState({})
  // Loading state
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  // LISTENERS
  // Set initial language to the localStorage
  useEffect(() => {
    let isComponentMounted = true
    const setLSLanguage = () =>
      window?.localStorage.setItem('language', DEFAULT_LANGUAGE)

    if (!LSLang && isComponentMounted) setLSLanguage()

    return () => {
      isComponentMounted = false
    }
  }, [LSLang])

  // Fetching translations from the DB
  useEffect(() => {
    let isComponentMounted = true
    const ref =
      language && `${COLLECTIONS.TRANSLATIONS}/${CURRENT_APP}/${language}`

    const fetchTranslations = async () => {
      if (ref) {
        setLoading(true)
        const snapshot = await database.ref(ref).once('value')
        const data = snapshot?.val()
        if (data && Object.keys(data).length) {
          setTranslations(data)
        }
        setLoading(false)
        setLoaded(true)
        setLanguageSwitch(false)
      }
    }

    isComponentMounted && fetchTranslations()

    return () => {
      isComponentMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [database, language])

  // FUNCTIONS
  // Function set current language to the LS and provider state
  const setCurrentLanguage = (language) => {
    setLanguage(language)
    return window?.localStorage.setItem('language', language)
  }

  // Function that looks like i18n t
  const t = (label) => {
    const DBLabel = translations && translations[label]

    if (!DBLabel && loaded) {
      LANGUAGES.forEach((lang) => {
        const ref = `${COLLECTIONS.TRANSLATIONS}/${CURRENT_APP}/${lang.shortCode}/${label}`
        database.ref(ref).set(label)
      })
    }

    return DBLabel || label
  }

  return (
    <TranslationsContext.Provider
      value={{
        setCurrentLanguage,
        language,
        translations,
        translateLoading: loading,
        loaded,
        languages: LANGUAGES,
        t,
        languageSwitch,
        setLanguageSwitch
      }}>
      {children}
    </TranslationsContext.Provider>
  )
}

export default TranslationsProvider
