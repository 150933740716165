import { useState, useEffect } from 'react'
import { BuyingCaseTypeSelect } from 'domains/BuyingCaseType/components'
import { Button, Form } from 'antd'
import { differenceTypes } from 'domains/Wizard/helpers'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useCollectionData } from '~/hooks/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { COLLECTIONS } from 'app/constants'

function AgreementTemplateTypeForm(props) {
  const { initialValues, onFinish } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const [form] = Form.useForm()
  const [buyingTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )
  const [agreementTemplates] = useCollectionData(
    getCollectionRef(COLLECTIONS.AGREEMENT_TEMPLATES)
  )

  // [COMPONENT STATE HOOKS]
  const [types, setTypes] = useState([])

  // [USE_EFFECTS]
  useEffect(() => {
    if (agreementTemplates) {
      // Get didn't use buying types to use in select
      const diffTypes = differenceTypes(buyingTypes, agreementTemplates)

      // Push initial value, because it should exist in list event if there are no available types to use
      initialValues?.buyingTypeId && diffTypes.push(initialValues.buyingTypeId)

      // Init buffer to store select items there
      const buf = diffTypes

      // Push full data about initialValues ids to buffer
      buyingTypes.forEach((item) => {
        const { id } = item
        if (diffTypes.includes(id)) {
          buf.push(item)
        }
      })

      // Set generated options to state & delete initialValue(because it string type)
      setTypes(buf.filter((item) => typeof item !== 'string'))
    }
  }, [agreementTemplates, buyingTypes, initialValues?.buyingTypeId])

  return (
    <Form
      requiredMark={false}
      form={form}
      onFinish={onFinish}
      layout="vertical">
      <Form.Item
        name="buyingTypeId"
        label={t('Buying type')}
        rules={[
          {
            required: true,
            message: t('Please, select buying type')
          }
        ]}>
        <BuyingCaseTypeSelect
          buyingTypes={types}
          loading={loadingBuyingCaseTypes}
        />
      </Form.Item>
      <Button type="primary" block htmlType="submit">
        {t('Choose type')}
      </Button>
    </Form>
  )
}

export default AgreementTemplateTypeForm
