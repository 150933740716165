import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import { List } from 'antd'
import { Card } from '@qonsoll/react-design'
import { PartnerView } from 'app/domains/Partner/components'
import ROUTE_PATHS from 'domains/allRoutePath'

function PartnerList(props) {
  const { data } = props

  // [ADDITIONAL HOOKS]
  const history = useHistory()

  // [CLEAN FUNCTIONS]
  const onItemClick = (id) => {
    history.push(generatePath(ROUTE_PATHS.PARTNER_PROFILE, { id }))
  }

  return (
    <List
      dataSource={data}
      renderItem={(item, index) => (
        <Card
          style={{ cursor: 'pointer' }}
          key={index}
          onClick={() => onItemClick(item.id)}
          mb={3}
          bordered={false}
          shadowless>
          <PartnerView
            name={item.partnerName}
            isActivated={item?.isActivated}
          />
        </Card>
      )}
    />
  )
}

PartnerList.propTypes = {
  data: PropTypes.array.isRequired
}

export default PartnerList
