import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import { List } from 'antd'
import { Col, Row, Card, Text } from '@qonsoll/react-design'
import { RightOutlined } from '@ant-design/icons'
import ROUTE_PATHS from 'domains/allRoutePath'

function OfferTypeList(props) {
  const { data } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [CLEAN_FUNCTIONS]
  const onItemClick = (id) => {
    history.push(generatePath(ROUTE_PATHS.OFFER_TYPE_EDIT, { id }))
  }

  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <Card
          style={{ cursor: 'pointer' }}
          onClick={() => onItemClick(item.id)}
          mb={3}
          bordered={false}
          shadowless>
          <Row noGutters h="center" v="center">
            <Col minWidth={0}>
              <Text isEllipsis>{item.name}</Text>
            </Col>
            <Col cw="auto" ml={3} mr={-3}>
              <RightOutlined
                style={{
                  fontSize: 'var(--ql-font-size-h3)',
                  color: 'var(--text-color-secondary)'
                }}
              />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

OfferTypeList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired })
  )
}

export default OfferTypeList
