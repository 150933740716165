import _ from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import generator from 'generate-password'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import firebase from '~/services/Firebase/init'
import { createDocument } from '~/services/Firebase/firestore'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { AdministratorForm } from 'domains/Administrator/components'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { PageLoading } from 'app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'

function AdministratorInvite() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onFormFinish = async (values) => {
    setLoading(true)
    const password = generator.generate({
      length: 10,
      numbers: true
    })
    const func = firebase
      .functions()
      .httpsCallable('sendInvite', { timeout: 0 })
    const { data } = await func({
      email: values.email,
      password: password,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: `+${values.phone}`,
      link: process.env.REACT_APP_HOSTNAME
    })
    const responseData = data
    const userId = responseData.data
    if (userId) {
      await createDocument(
        COLLECTIONS.USERS,
        {
          id: userId,
          firstName: values.firstName,
          secondName: values.lastName,
          phone: `+${values.phone}`,
          email: values.email,
          role: 'ADMIN',
          isActivated: false,
          themeMode: 'light'
        },
        { docId: userId }
      )
      message.success(t('User was successfully invited'))
      history.push(ROUTE_PATHS.ADMINISTRATORS_ALL)
    } else {
      const error = responseData.error
      console.log(error)
      message.error(t(_.trimEnd(error.message, '.')))
    }

    setLoading(false)
  }

  return (
    <PageLoading loading={loading}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Invite administrator'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <AdministratorForm onFinish={onFormFinish} />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default AdministratorInvite
