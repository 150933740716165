import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Box, Avatar, Text, Card } from '@qonsoll/react-design'
import { List, Tag } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { UserOutlined, RightOutlined } from '@ant-design/icons'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function AdministratorList(props) {
  const { data } = props

  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { t } = useTranslations()

  // [CLEAN FUNCTIONS]
  const onItemSelected = () => {}
  const onItemClick = (id) => {
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))
  }
  // const onChange = () => {}

  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <Card
          style={{ cursor: 'pointer' }}
          onClick={() => onItemClick(item.id)}
          onSelect={onItemSelected}
          mb={3}
          size="small"
          bordered={false}
          shadowless>
          <Row noGutters h="center" v="center">
            <Col cw="auto" mr={3}>
              <Box
                height="fit-content"
                border="var(--ql-border-width-2) var(--ql-border-style-default) var(--avatar-border-color)"
                borderRadius="var(--ql-border-radius-full)">
                <Avatar
                  m="2px"
                  icon={<UserOutlined />}
                  src={item?.avatarURL}
                  alt="avatar"
                />
              </Box>
            </Col>
            <Col minWidth={0} pr={3}>
              <Text isEllipsis>{`${item.firstName} ${item.secondName}`}</Text>
            </Col>
            {!item.isActivated && (
              <Col cw="auto">
                <Tag icon={<CheckCircleOutlined />}>{t('pending')}</Tag>
              </Col>
            )}
            <Col cw="auto" ml={3} mr={-3}>
              <RightOutlined
                style={{
                  fontSize: 'var(--ql-font-size-h3)',
                  color: 'var(--text-color-secondary)'
                }}
              />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

AdministratorList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, avatar: PropTypes.string })
  )
}

export default AdministratorList
