import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { SessionProvider } from 'app/contexts/Session/components'
import { UIProvider } from 'app/contexts/UI/components'
import { TranslationsProvider } from 'app/contexts/Translation/components'
import {
  BuyingCasesProvider,
  BuyersProvider,
  OffersProvider,
  AdministratorsProvider,
  OfferTypesProvider,
  BuyingTypesProvider,
  PartnersProvider,
  LocationsProvider
} from 'app/contexts/Cache/components'
import theme from 'styles/theme'
import '@qonsoll/react-design/dist/styles/styles.css'
import './styles/styles.css'

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <TranslationsProvider>
        <SessionProvider>
          <UIProvider>
            <BuyingCasesProvider>
              <BuyersProvider>
                <OffersProvider>
                  <AdministratorsProvider>
                    <OfferTypesProvider>
                      <BuyingTypesProvider>
                        <PartnersProvider>
                          <LocationsProvider>
                            <App />
                          </LocationsProvider>
                        </PartnersProvider>
                      </BuyingTypesProvider>
                    </OfferTypesProvider>
                  </AdministratorsProvider>
                </OffersProvider>
              </BuyersProvider>
            </BuyingCasesProvider>
          </UIProvider>
        </SessionProvider>
      </TranslationsProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
)
