import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { Row, Col } from '@qonsoll/react-design'
import db from 'services/Firebase/database'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { Spinner } from 'app/components'
import LocalizationItem from '../LocalizationItem'
import { COLLECTIONS } from '~/app/constants'

const { Title } = Typography

// HELPER_FUNCTIONS
// Function converts obj from RDB to the array of objects
const formatTranslations = (obj) => {
  const labels = obj?.en && Object.keys(obj.en)
  let formattedTranslations = []
  labels.length &&
    labels.forEach((label) => {
      formattedTranslations.push({
        original: label,
        en: obj.en[label],
        no: obj.no[label]
      })
    })

  return formattedTranslations
}

const LocalizationForm = (props) => {
  // Props destructuring
  const { appName } = props

  // Hooks
  const { t } = useTranslations()

  // States
  const [translations, setTranslations] = useState()
  const [loading, setLoading] = useState(true)

  // Loading data from the RDB by appName
  useEffect(() => {
    let isComponentMounted = true

    const fetchTranslations = async () => {
      const ref = `${COLLECTIONS.TRANSLATIONS}/${appName}`
      const snapshot = await db.ref(ref).once('value')
      const data = snapshot.val()

      if (data && Object.keys(data).length) {
        setTranslations(formatTranslations(data))
        setLoading(false)
      }
    }

    isComponentMounted && fetchTranslations()

    return () => {
      isComponentMounted = false
    }
  }, [appName])

  return (
    <>
      <Row mb={2} noOuterGutters>
        <Col>
          <Title level={5}>{t('English')}</Title>
        </Col>
        <Col>
          <Title level={5}>{t('Norwegian')}</Title>
        </Col>
      </Row>
      {loading ? (
        <Spinner />
      ) : (
        translations.map((translation, index) => (
          <LocalizationItem
            key={`${appName}-${index}`}
            appName={appName}
            data={translation}
          />
        ))
      )}
    </>
  )
}

LocalizationForm.propTypes = {
  appName: PropTypes.string.isRequired
}

export default LocalizationForm
