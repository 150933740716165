import React from 'react'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { ResetPasswordForm } from 'domains/Session/components'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useHistory } from 'react-router-dom'
import firebase from 'services/Firebase/init'
import auth from 'services/Firebase/auth'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function ForgotPassword() {
  // [ADDITIONAL_HOOKS]
  const { state } = useSessionContext()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN FUNCTIONS]
  const reauthenticate = (currentPassword) => {
    const user = auth.currentUser
    const cred = firebase.auth.EmailAuthProvider.credential(
      state.email,
      currentPassword
    )
    return user.reauthenticateWithCredential(cred)
  }
  const onFinish = async (values) => {
    try {
      await reauthenticate(values.oldPassword)
    } catch (err) {
      console.log(err)
      message.error(t('Incorrect old password'))
      return
    }
    try {
      await auth.currentUser.updatePassword(values.newPassword)
      message.success(t('Your password was successfully changed'))
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during password change'))
    }
    history.goBack()
  }

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Reset password'),
        titleSize: titleSizes,
        titleMarginBottom: 2,
        subTitle: t(
          'Enter your current password before start entering new one'
        ),
        textAlign: 'left',
        marginBottom: 24
      }}
      onBack={goBack}>
      <Container height="inherit" display="grid">
        <Row noGutters h="center">
          <Col cw={[12, 10, 8, 7, 6]} display="grid">
            <ResetPasswordForm onFinish={onFinish} />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default ForgotPassword
