import { Result } from 'antd'
import { Box } from '@qonsoll/react-design'
import { ReactComponent as UseAnotherDevice } from 'assets/useAnotherDevice.svg'

const RedirectToDesktop = () => (
  <Result
    icon={
      <Box display="flex" justifyContent="center">
        <UseAnotherDevice height="192px" />
      </Box>
    }
    title="Use desktop version of site."
  />
)

export default RedirectToDesktop
