import React, { useState } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import firebase from 'firebase'
import { message } from 'antd'
import {
  Row,
  Col,
  Container,
  PageWrapper,
  Text,
  Button
} from '@qonsoll/react-design'
import { EditOutlined } from '@ant-design/icons'
import { deleteDocument, getCollectionRef } from 'services/Firebase/firestore'
import { useBackButton } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { PageLoading, Remove, Backdrop } from '~/app/components'
import { PartnerAdvancedView } from 'domains/Partner/components'
import { OffersList } from 'domains/Offer/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from 'app/constants'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { usePartnersContext } from '~/app/contexts/Cache/hooks'

function PartnerProfile() {
  // [ADDITIONAL_HOOKS]
  const { id } = useParams()
  const { t } = useTranslations()
  const history = useHistory()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const [partnerData, partnerLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.PARTNERS).doc(id)
  )

  const [contactsData, contactsLoading] = useCollectionData(
    getCollectionRef(COLLECTIONS.USERS).where('partnerId', '==', id)
  )
  const [offers, offersLoading] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFERS).where('partnerId', '==', id)
  )

  const { dispatch } = usePartnersContext()

  // [COMPONENT_STATE_HOOKS]
  const [deletePartnerLoading, setDeletePartnerLoading] = useState(false)
  // [CLEAN FUNCTIONS]
  const onEditClick = () => {
    const toEditPartner = generatePath(ROUTE_PATHS.PARTNER_EDIT, { id })
    history.replace(toEditPartner, { partnerID: id })
  }
  const onRemove = async () => {
    try {
      setDeletePartnerLoading(true)
      const func = firebase
        .functions()
        .httpsCallable('deletePartner', { timeout: 0 })
      await func({ partnerForDelete: partnerData })
      await deleteDocument(COLLECTIONS.PARTNERS, id)
      message.success(t('Partner was delete'))
      dispatch({ type: 'REMOVE_DOC', payload: id })
      return history.push(ROUTE_PATHS.PARTNERS_ALL)
    } catch (err) {
      console.log(err)
      message.error(t('Partner was not deleted'))
    }
    setDeletePartnerLoading(false)
  }

  return (
    <PageLoading loading={partnerLoading || contactsLoading || offersLoading}>
      <PageWrapper onBack={goBack} divided={false} isBottomSticky>
        {deletePartnerLoading && <Backdrop spinnerText={t('Deleting')} />}

        <Container
          height="inherit"
          display="flex"
          style={{
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          <PartnerAdvancedView contactsData={contactsData} {...partnerData} />
          {offers?.length > 0 && (
            <>
              <Row noGutters>
                <Col cw={12}>
                  <Text variant="h5" mb={3}>
                    {t('Offers')}
                  </Text>
                </Col>
              </Row>
              <OffersList data={offers} />
            </>
          )}
          <Row noGutters mt="auto">
            <Col cw={12} mt={3} mb={2}>
              <Button icon={<EditOutlined />} block onClick={onEditClick}>
                {t('Edit partner')}
              </Button>
            </Col>
            <Col cw={12} mb={2}>
              <Remove
                text={t('Delete partner')}
                block
                onSubmit={onRemove}
                type="primary"
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default PartnerProfile
