import React, { useMemo, useRef } from 'react'
import { Box, PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useParams } from 'react-router-dom'
import firebase from 'services/Firebase/init'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import {
  AnswersContextProvider,
  FormShow
} from '~/modules/feedback-typeform-app/src'
import { qFormTranslations } from 'app/constants'

function WizardShow() {
  // [ADDITIONAL_HOOKS]
  const goBack = useBackButton({ returnCallback: true })
  const wrapperOffsets = useResponsiveBreakpoints({
    sm: 0,
    md: 56,
    lg: 56,
    xl: 56,
    xxl: 56
  })
  const { t } = useTranslations()
  const translations = useMemo(() => {
    qFormTranslations(t)
  }, [t])
  const { id } = useParams()

  // [COMPONENT_STATE_HOOKS]
  const wrapperRef = useRef(null)

  return (
    <PageWrapper onBack={goBack} height="inherit">
      <Box ref={wrapperRef} height="inherit">
        <AnswersContextProvider>
          <FormShow
            id={id}
            firebase={firebase}
            translate={t}
            wrapperHeight={`${wrapperRef?.current?.offsetHeight}px`}
            wrapperOffset={wrapperOffsets}
            translations={translations}
            wrapperPaddings={0}
          />
        </AnswersContextProvider>
      </Box>
    </PageWrapper>
  )
}

export default WizardShow
