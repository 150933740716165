import { default as AdministratorRoutes } from './Administrator/__constants__/routes'
import { default as AgreementRoutes } from './Agreement/__constants__/routes'
import { default as AgreementTemplateRoutes } from './AgreementTemplate/__constants__/routes'
import { default as AppRoutes } from './App/__constants__/routes'
import { default as BuyerRoutes } from './Buyer/__constants__/routes'
import { default as BuyingCaseRoutes } from './BuyingCase/__constants__/routes'
import { default as BuyingCaseTypeRoutes } from './BuyingCaseType/__constants__/routes'
import { default as LocationRoutes } from './Location/__constants__/routes'
import { default as OfferRoutes } from './Offer/__constants__/routes'
import { default as OfferTypeRoutes } from './OfferType/__constants__/routes'
import { default as PartnerRoutes } from './Partner/__constants__/routes'
import { default as RecentActivityRoutes } from './RecentActivity/__constants__/routes'
import { default as SessionRoutes } from './Session/__constants__/routes'
import { default as TranslationRoutes } from './Translation/__constants__/routes'
import { default as UserRoutes } from './User/__constants__/routes'
import { default as WizardRoutes } from './Wizard/__constants__/routes'

export default [
  ...AdministratorRoutes,
  ...AgreementRoutes,
  ...AgreementTemplateRoutes,
  ...AppRoutes,
  ...BuyerRoutes,
  ...BuyingCaseRoutes,
  ...BuyingCaseTypeRoutes,
  ...LocationRoutes,
  ...OfferRoutes,
  ...OfferTypeRoutes,
  ...PartnerRoutes,
  ...RecentActivityRoutes,
  ...SessionRoutes,
  ...TranslationRoutes,
  ...UserRoutes,
  ...WizardRoutes
]
