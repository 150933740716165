import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import { message, Space } from 'antd'
import { Col, Row, Card, Edit, Text } from '@qonsoll/react-design'
import { useDocumentDataOnce } from '~/hooks/firestore'
import { deleteDocument, getCollectionRef } from '~/services/Firebase/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { Remove } from 'app/components'
import { COLLECTIONS } from 'app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'

function AgreementTemplateListItem(props) {
  const { id, buyingTypeId } = props
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const [buyingCaseTypeData] = useDocumentDataOnce(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES).doc(buyingTypeId)
  )

  // [CLEAN_FUNCTIONS]
  const goToEdit = () =>
    history.push(generatePath(ROUTE_PATHS.AGREEMENT_TEMPLATE_EDIT, { id }), {
      buyingTypeName: buyingCaseTypeData?.name
    })
  const onRemove = async () => {
    try {
      await deleteDocument(COLLECTIONS.AGREEMENT_TEMPLATES, id)
      message.success(t('Agreement template was deleted successfully'))
    } catch (error) {
      message.error(t("Agreement template wasn't deleted"))
    }
  }

  return (
    <Card mb={3} size="small" bordered={false} shadowless>
      <Row noGutters h="center" v="center">
        <Col minWidth={0}>
          <Text isEllipsis>{buyingCaseTypeData?.name}</Text>
        </Col>
        <Col cw="auto">
          <Space>
            <Edit type="text" onClick={goToEdit} tooltip={t('Edit')} icon />
            <Remove
              type="text"
              popconfirmPlacement="bottomRight"
              itemName={t('Agreement template')}
              onSubmit={onRemove}
              icon
            />
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

AgreementTemplateListItem.propTypes = {
  buyingCaseTypeId: PropTypes.string.isRequired
}

export default AgreementTemplateListItem
