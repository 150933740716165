import { useTranslations } from 'app/contexts/Translation/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { Form, Grid } from 'antd'
import { useBackButton } from 'app/contexts/UI/hooks'
import firebase from 'services/Firebase/init'
import { getCollectionRef } from 'services/Firebase/firestore'
import { useCollectionData } from '~/hooks/firestore'
import { PageLoading, RedirectToDesktop } from 'app/components'
import { COLLECTIONS } from 'app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { differenceTypes } from 'domains/Wizard/helpers'
import { BuyingCaseTypeSelect } from 'domains/BuyingCaseType/components'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { FormsAll } from '~/modules/feedback-typeform-app/src'
import { qFormTranslations } from 'app/constants'
import { PageWrapper } from '@qonsoll/react-design'

const { useBreakpoint } = Grid

const Select = (buyingCaseTypes) => {
  return (
    <Form.Item
      name="buyingTypeId"
      rules={[{ required: true, message: 'Please, select buying type' }]}>
      <BuyingCaseTypeSelect buyingTypes={buyingCaseTypes} />
    </Form.Item>
  )
}

function WizardsAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const screen = useBreakpoint()
  const { t } = useTranslations()
  const translations = useMemo(() => {
    qFormTranslations(t)
  }, [t])
  const [buyingCaseTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )
  const [forms, loadingForms] = useCollectionData(
    getCollectionRef(COLLECTIONS.FORMS)
  )
  // [COMPONENT_STATE_HOOKS]
  const [type, setType] = useState([])

  // [CLEAR_FUNCTIONS]
  const onFormShow = (id) => {
    const path = generatePath(ROUTE_PATHS.WIZARD_SHOW, { id })
    history.push(path)
  }
  const onFormItemClick = (id) => {
    const path = generatePath(ROUTE_PATHS.WIZARD_EDIT, { id })
    history.push(path)
  }

  const onFormDelete = (id) => {
    getCollectionRef(COLLECTIONS.AGREEMENT_TEMPLATES)
      .where('buyingTypeId', '==', id)
      .get()
      .then((querySnap) =>
        querySnap.forEach((item) => {
          item.ref.delete()
        })
      )
  }

  // [USE_EFFECTS]
  useEffect(() => {
    const types = differenceTypes(buyingCaseTypes, forms)
    setType(types)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms?.length, buyingCaseTypes])
  return (
    <PageLoading loading={loadingBuyingCaseTypes || loadingForms}>
      {screen.xl ? (
        buyingCaseTypes && (
          <PageWrapper
            headingProps={{
              title: t('Wizards'),
              titleSize: 3,
              marginBottom: '24px'
            }}
            onBack={goBack}>
            <FormsAll
              wrapperPaddings={0}
              firebase={firebase}
              actions={{
                onFormShow: onFormShow,
                onFormItemClick: onFormItemClick,
                onFormDelete
              }}
              childrenModal={Select(type.length !== 0 ? type : buyingCaseTypes)}
              disableAddButton={type?.length === 0}
              titleProps={{ level: 3 }}
              translations={translations}
            />
          </PageWrapper>
        )
      ) : (
        <RedirectToDesktop />
      )}
    </PageLoading>
  )
}

export default WizardsAll
