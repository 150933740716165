import React from 'react'
import { Empty } from 'antd'
import { Row, Col, PageWrapper, Input } from '@qonsoll/react-design'
import { AgreementList } from 'domains/Agreement/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useHomeButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

function AgreementAll() {
  // [ADDITIONAL_HOOKS]
  useHomeButton()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const data = [
    { name: 'Agreement name 1' },
    { name: 'Agreement name 2' },
    { name: 'Agreement name 3' }
  ]

  return (
    <PageWrapper
      headingProps={{
        title: t('Agreements'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      isBottomSticky>
      {data?.length ? (
        <Row noGutters>
          <Col mb="24px">
            <Input placeholder={t('Enter name here')} allowClear />
          </Col>
          <Col cw={12}>
            <AgreementList data={data} />
          </Col>
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default AgreementAll
