import { RecentActivitiesAll } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'RecentActivitiesAll',
    path: ROUTE_PATHS.RECENT_ACTIVITIES_ALL,
    exact: true,
    component: RecentActivitiesAll
  }
]
