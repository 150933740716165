import { Input } from 'antd'
import { Text, Row, Col } from '@qonsoll/react-design'

function CustomBlockForAnswerItem(props) {
  const { answerOption, onChange, onBlur, defaultValue } = props

  return (
    <Row mb={2} v="center" h="center">
      <Col cw={2} h="center">
        <Text isEllipsis>{answerOption}</Text>
      </Col>
      <Col>
        <Input.TextArea
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Col>
    </Row>
  )
}

export default CustomBlockForAnswerItem
