import { OfferTypeAll, OfferTypeCreate, OfferTypeEdit } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'OfferTypeAll',
    path: ROUTE_PATHS.OFFER_TYPE_ALL,
    exact: true,
    component: OfferTypeAll
  },
  {
    name: 'OfferTypeCreate',
    path: ROUTE_PATHS.OFFER_TYPE_CREATE,
    exact: true,
    component: OfferTypeCreate
  },
  {
    name: 'OfferTypeEdit',
    path: ROUTE_PATHS.OFFER_TYPE_EDIT,
    exact: true,
    component: OfferTypeEdit
  }
]
