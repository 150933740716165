import { message } from 'antd'

const avatarRestrictionCheck = (file, t) => {
  const isLessThan20MB = file.size / 1024 / 1024 < 20
  if (!isLessThan20MB) {
    message.error(t('Image must smaller than 20MB!'))
  }
  return isLessThan20MB
}
export default avatarRestrictionCheck
