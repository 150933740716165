import { Select } from 'antd'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { LANGUAGES } from 'app/contexts/Translation/__constants__'

const LanguageSelect = () => {
  const { setCurrentLanguage, language, setLanguageSwitch, translateLoading } =
    useTranslations()

  const onChange = ({ value }) => {
    setLanguageSwitch(true)

    setCurrentLanguage(value)
  }
  return (
    <Select
      labelInValue
      defaultValue={LANGUAGES.find(({ value }) => value === language)}
      options={LANGUAGES.filter(({ value }) => value !== language)}
      style={{ minWidth: 150 }}
      loading={translateLoading}
      onSelect={onChange}
    />
  )
}

LanguageSelect.propTypes = {}

export default LanguageSelect
