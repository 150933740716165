import React, { useState } from 'react'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from 'app/constants'
import { PageLoading } from 'app/components'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { LocationForm } from '../../components'
import { createDocument } from '~/services/Firebase/firestore'

function LocationCreate() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  //[STATE HOOKS]
  const [loadingForm, setLoadingForm] = useState(false)

  // [CLEAN FUNCTIONS]
  const onFinish = async (values) => {
    setLoadingForm(true)
    try {
      await createDocument(COLLECTIONS.LOCATIONS, values)
      message.success(t(`Location was created`))
      history.push(ROUTE_PATHS.LOCATIONS_ALL)
    } catch (error) {
      message.error(t(`Can't create location`))
    }
    setLoadingForm(false)
  }

  return (
    <PageLoading loading={loadingForm}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Create location'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <LocationForm onFinish={onFinish} />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default LocationCreate
