import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Space, Tooltip } from 'antd'
import { PageWrapper, Title, Box, Col, Row } from '@qonsoll/react-design'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { createDocument } from '~/services/Firebase/firestore'
import {
  prepareStateForDatabase,
  convertStateToReactComponent
} from 'domains/AgreementTemplate/helpers'
import {
  AgreementTemplateAdvancedForm,
  AgreementTemplatePreview,
  TextEditor
} from 'domains/AgreementTemplate/components'
import { COLLECTIONS } from 'app/constants'

// Responsive config constants
const BACK_BUTTON_VISIBILITY = {
  sm: false,
  md: true,
  lg: true,
  xl: true,
  xxl: true
}
const TITLE_SIZE_CONFIG = {
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3
}
const SHOW_RESULT_SIDE = {
  sm: false,
  md: false,
  lg: false,
  xl: true,
  xxl: true
}

function AgreementTemplateEdit() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints(BACK_BUTTON_VISIBILITY)
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints(TITLE_SIZE_CONFIG)
  const showResult = useResponsiveBreakpoints(SHOW_RESULT_SIDE)

  // [COMPONENT_STATE_HOOKS]
  const [displayResult, setDisplayResult] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [template, setTemplate] = useState({})

  // [CLEAN_FUNCTIONS]
  const onChange = (editorState, field) => {
    const component = convertStateToReactComponent(editorState)
    setTemplate({ ...template, [field]: { component, state: editorState } })
  }

  const onCreate = async (data) => {
    const agreementTemplate = prepareStateForDatabase(data, {
      header: template.header,
      footer: template.footer
    })
    await createDocument(COLLECTIONS.AGREEMENT_TEMPLATES, agreementTemplate)
    setModalVisible(false)
    history.goBack()
  }

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Create agreement template'),
        titleSize: titleSizes,
        marginBottom: 24
      }}
      onBack={goBack}
      action={
        <Space>
          {!showResult && (
            <Tooltip
              title={displayResult ? t('Edit template') : t('Show result')}>
              <Button
                onClick={() => setDisplayResult(!displayResult)}
                icon={displayResult ? <EditOutlined /> : <EyeOutlined />}
              />
            </Tooltip>
          )}
          <Button type="primary" onClick={() => setModalVisible(true)}>
            {t('Create')}
          </Button>
        </Space>
      }>
      <Row height="inherit">
        {displayResult ? (
          <Col flexDirection="column">
            <AgreementTemplatePreview
              header={template?.header?.component}
              footer={template?.footer?.component}
            />
          </Col>
        ) : (
          <>
            <Col flexDirection="column">
              <Box mb={3} height="100%" display="flex" flexDirection="column">
                <Title level={3}>{t('Header')}</Title>
                <TextEditor
                  onChange={(value) => onChange(value, 'header')}
                  state={template?.header?.state}
                />
              </Box>

              <Box height="100%" display="flex" flexDirection="column">
                <Title level={3} mb={2}>
                  {t('Footer')}
                </Title>
                <TextEditor
                  onChange={(value) => onChange(value, 'footer')}
                  state={template?.footer?.state}
                />
              </Box>
            </Col>
            {showResult && (
              <Col flexDirection="column">
                <AgreementTemplatePreview
                  header={template?.header?.component}
                  footer={template?.footer?.component}
                />
              </Col>
            )}
          </>
        )}
      </Row>
      <AgreementTemplateAdvancedForm
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onFinish={onCreate}
      />
    </PageWrapper>
  )
}

export default AgreementTemplateEdit
