import React from 'react'
import { List } from 'antd'
import { Box } from '@qonsoll/react-design'
import {
  TeamOutlined,
  ShoppingOutlined,
  BuildOutlined,
  FileDoneOutlined
} from '@ant-design/icons'
import { StatisticView } from 'app/domains/Statistic/components'
import { COLLECTIONS } from '~/app/constants'
import { getCollectionRef } from '~/services/Firebase/firestore'
import ROUTE_PATHS from '~/app/domains/allRoutePath'

const STATISTIC_ITEMS = [
  {
    title: 'Buyers',
    request: getCollectionRef(COLLECTIONS.USERS)
      .where('role', '==', 'YHB')
      .where('isActivated', '==', true),
    icon: <TeamOutlined />,
    path: ROUTE_PATHS.BUYERS_ALL
  },
  {
    title: 'Buying cases',
    request: getCollectionRef(COLLECTIONS.BUYING_CASES),
    icon: <ShoppingOutlined />,
    path: ROUTE_PATHS.BUYING_CASES_ALL
  },
  {
    title: 'Agreements',
    request: getCollectionRef(COLLECTIONS.AGREEMENTS),
    icon: <FileDoneOutlined />,
    path: ROUTE_PATHS.AGREEMENT_ALL
  },
  {
    title: 'Offers',
    request: getCollectionRef(COLLECTIONS.OFFERS),
    icon: <BuildOutlined />,
    path: ROUTE_PATHS.OFFERS_ALL
  }
]

function StatisticsList() {
  return (
    <Box width="100%">
      <List
        grid={{
          gutter: [8, -8],
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2
        }}
        split={false}
        dataSource={STATISTIC_ITEMS}
        renderItem={(item, index) => (
          <List.Item key={index} style={{ cursor: 'pointer' }}>
            <StatisticView {...item} />
          </List.Item>
        )}
      />
    </Box>
  )
}

export default StatisticsList
