// import { OfferView } from 'domains/Offer/components'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { message, Space } from 'antd'
import {
  Col,
  Container,
  Row,
  Box,
  Title,
  Text,
  PageWrapper,
  Img,
  Edit,
  Link,
  Button
} from '@qonsoll/react-design'
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  BankOutlined
} from '@ant-design/icons'
import { deleteDocument, getCollectionRef } from '~/services/Firebase/firestore'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { CustomCarousel, PageLoading, Remove } from '~/app/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'
import { deleteURL } from '~/services/Firebase/storage'
import { useOffersContext } from '~/app/contexts/Cache/hooks'

const config = { sm: 240, md: 320, lg: 500, xl: 500, xxl: 500 }
const mobileMap = {
  sm: true,
  md: true,
  lg: false,
  xl: false,
  xxl: false
}

function OfferShow() {
  // [ADDITIONAL_HOOKS]
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const { id } = useParams()
  const history = useHistory()
  const [offerData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.OFFERS).doc(id)
  )
  const [partnerData, partnerLoading] = useDocumentData(
    !loading &&
      offerData?.partnerId &&
      getCollectionRef(COLLECTIONS.PARTNERS).doc(offerData?.partnerId)
  )
  const responsive = useResponsiveBreakpoints(config)
  const isMobile = useResponsiveBreakpoints(mobileMap)
  const images = useResponsiveBreakpoints({
    sm: offerData?.images_500,
    md: offerData?.images_500,
    lg: offerData?.images_1000,
    xl: offerData?.images_1000,
    xxl: offerData?.images_1000
  })
  const { dispatch } = useOffersContext()

  // [COMPUTED PROPERTIES]
  const whoCreated =
    offerData?.partnerId &&
    generatePath(ROUTE_PATHS.PARTNER_PROFILE, {
      id: offerData?.partnerId
    })

  // [CLEAN FUNCTIONS]
  const onRemove = async () => {
    try {
      //remove images and resize images from storage
      for (const image of offerData?.images)
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })
      for (const image of offerData?.images_500)
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })
      for (const image of offerData?.images_1000)
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })

      //remove offer
      await deleteDocument(COLLECTIONS.OFFERS, id)
      message.success(t('Offer was successfully deleted'))
      history.push(ROUTE_PATHS.OFFERS_ALL)
      dispatch({ type: 'REMOVE_DOC', payload: id })
    } catch (error) {
      console.log(error)
      message.error(t('Error occurred during offer deletion'))
    }
  }
  const onEditClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.OFFER_EDIT, { id }))

  if (!offerData && !loading) {
    history.replace(ROUTE_PATHS[404])
  }

  const info = (
    <Row noGutters>
      <Col cw={12} mb={3}>
        <Text whiteSpace="pre-line">{offerData?.description}</Text>
      </Col>
      {offerData?.price && (
        <Col
          cw={12}
          mb={3}
          style={{ alignItems: 'baseline' }}
          flexDirection="row">
          <Title level={2} mr={2}>
            {offerData?.price}
          </Title>
          <Text type="secondary">{offerData?.currency || t('NOK')}</Text>
        </Col>
      )}
      {offerData?.fixedPrice && (
        <Col
          cw={12}
          mb={3}
          style={{ alignItems: 'baseline' }}
          flexDirection="row">
          <Text>
            {t('Fixed price:')} {offerData.fixedPrice}
            {offerData?.currency || t('NOK')}
          </Text>
        </Col>
      )}
      {offerData?.fixedDiscount && (
        <Col
          cw={12}
          mb={3}
          style={{ alignItems: 'baseline' }}
          flexDirection="row">
          <Text>
            {`${t('Fixed discount:')} ${offerData.fixedDiscount} `}
            {offerData?.currency || t('NOK')}
          </Text>
        </Col>
      )}
      {offerData?.fixedDiscountPercentage && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed discount percentage:')}{' '}
            {offerData.fixedDiscountPercentage}%
          </Text>
        </Col>
      )}
      {offerData?.fixedPercentage && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed percentage:')} {offerData.fixedPercentage}%
          </Text>
        </Col>
      )}
      {partnerData && (
        <Col cw={12} mb={4} flexDirection="row">
          <BankOutlined
            style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
          />
          <Link href={whoCreated} ml={3}>
            {partnerData.partnerName}
          </Link>
        </Col>
      )}
      <Col cw={12} mb={4} flexDirection="row">
        <EnvironmentOutlined
          style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
        />
        <Text ml={3}>{offerData?.location}</Text>
      </Col>
      <Col cw={12} mb={4} flexDirection="row">
        <MailOutlined
          style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
        />
        <Text ml={3}>{offerData?.email}</Text>
      </Col>
      <Col cw={12} mb={4} flexDirection="row">
        <PhoneOutlined
          style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
        />
        <Text ml={3}>{offerData?.phone}</Text>
      </Col>
    </Row>
  )
  return (
    <PageLoading loading={loading || partnerLoading}>
      <PageWrapper
        headingProps={{
          title: <Box textAlign="initial">{offerData?.title}</Box>,
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        isBottomSticky
        onBack={goBack}
        action={[
          // <Button type="text" icon={<ShareAltOutlined />} onClick={onShare} />,
          <Space>
            <Edit
              type="text"
              onClick={() => onEditClick(id)}
              tooltip={t('Edit')}
              icon
            />
            <Remove
              type="text"
              popconfirmPlacement="bottomRight"
              onSubmit={onRemove}
              icon
            />
          </Space>
        ]}>
        {isMobile ? (
          <Container
            height="inherit"
            display="flex"
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
            <Row noGutters mb={3}>
              <Col
                cw={12}
                minWidth={0}
                style={{ position: 'relative', height: 'fit-content' }}>
                <CustomCarousel noOuterGutters data={images}>
                  {images?.map((item, index) => (
                    <Img
                      key={index}
                      src={item}
                      alt={item}
                      height={responsive}
                      objectFit="cover"
                    />
                  ))}
                </CustomCarousel>
              </Col>
            </Row>
            <>{info}</>
            {offerData?.phone && (
              <Button
                type="primary"
                href={`tel:${offerData?.phone}`}
                icon={<PhoneOutlined />}
                block
                mt="auto">
                {t('Make a call')}
              </Button>
            )}
          </Container>
        ) : (
          <Container
            height="inherit"
            display="flex"
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
            <Row noOuterGutters mt={28} style={{ alignItem: 'baseline' }}>
              <Col
                cw={6}
                style={{ position: 'relative', height: 'fit-content' }}>
                <CustomCarousel data={images}>
                  {images?.map((item, index) => (
                    <Img
                      key={index}
                      src={item}
                      alt={item}
                      height={responsive}
                      objectFit="cover"
                    />
                  ))}
                </CustomCarousel>
              </Col>
              <Col cw={6} h={'center'}>
                <Row noGutters height="100%">
                  <Col cw={12} display={'block'}>
                    <>{info}</>
                  </Col>
                  <Col cw={12}>
                    {offerData?.phone && (
                      <Button
                        type="primary"
                        href={`tel:${offerData?.phone}`}
                        icon={<PhoneOutlined />}
                        block
                        mt="auto">
                        {t('Make a call')}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </PageWrapper>
    </PageLoading>
  )
}

export default OfferShow
