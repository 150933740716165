import { Translations } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'Translations',
    path: ROUTE_PATHS.TRANSLATIONS,
    exact: true,
    component: Translations
  }
]
