import React from 'react'
import { Form } from 'antd'
import { Col, Row, Input, Button } from '@qonsoll/react-design'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useTranslations } from '~/app/contexts/Translation/hooks'

const MAX_NUMBER_OF_MEMBERS_IN_BUYING_CASE = 5

function BuyingCaseCRUDAttendees(props) {
  const { onFinish, buyersInBuyingCase } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const currentCountBuyersInBuyingCase =
    MAX_NUMBER_OF_MEMBERS_IN_BUYING_CASE - buyersInBuyingCase?.length

  return (
    <Form
      onFinish={onFinish}
      autoComplete="off"
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Form.List name="emails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row noGutters>
                <Col mr={2}>
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    fieldKey={[fieldKey, 'email']}
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: t('Enter valid email!')
                      }
                    ]}>
                    <Input placeholder={t('Email')} autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col cw="auto">
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            ))}
            {buyersInBuyingCase?.length <=
              MAX_NUMBER_OF_MEMBERS_IN_BUYING_CASE &&
              fields?.length < currentCountBuyersInBuyingCase && (
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}>
                  {t('Add one more buyer')}
                </Button>
              )}
          </>
        )}
      </Form.List>
      <Button
        type="primary"
        block
        htmlType="submit"
        mt={3}
        disabled={
          buyersInBuyingCase?.length >= MAX_NUMBER_OF_MEMBERS_IN_BUYING_CASE
        }>
        {t('Send invite')}
      </Button>
    </Form>
  )
}

BuyingCaseCRUDAttendees.propTypes = {
  onFinish: PropTypes.func.isRequired,
  buyersInBuyingCase: PropTypes.array.isRequired
}

export default BuyingCaseCRUDAttendees
