import PropTypes from 'prop-types'
import { Row, Col, Container, Text } from '@qonsoll/react-design'
import { Spin } from 'antd'
import { useTranslations } from 'app/contexts/Translation/hooks'

const Spinner = (props) => {
  const { text, textStyles, ...rest } = props
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Container {...rest}>
      <Row v="center" style={{ flexDirection: 'column' }}>
        <Col cw="auto" mb={2}>
          <Spin size="large" />
        </Col>
        <Col cw="auto">
          <Text type="secondary" style={textStyles}>
            {t(text || 'Loading')}...
          </Text>
        </Col>
      </Row>
    </Container>
  )
}

Spinner.propTypes = {
  text: PropTypes.string,
  textStyles: PropTypes.object
}

export default Spinner
