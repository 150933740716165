import { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, InputNumber } from 'antd'
import {
  Box,
  Col,
  Row,
  Button,
  TextArea,
  Input,
  PhoneInput
} from '@qonsoll/react-design'
import { ImagesUploader, UploadButton } from '~/app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import _ from 'lodash'

const CURRENCIES = ['NOK']

function OfferForm(props) {
  const { onRemoveForResized, offersTypes, onFinish, form, edit, initialType } =
    props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  //[STATE_HOOKS]
  const [visible, setVisible] = useState(!!edit)
  const [type, setType] = useState(initialType || {})

  // [CLEAN FUNCTIONS]
  const onChangeSelectType = (_, object) => {
    setVisible(true)
    setType(object.key)
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={(data) => onFinish(data, type)}
      form={form}>
      <Form.Item
        label={t('Offer type')}
        name="type"
        style={{ width: '100%' }}
        rules={[
          {
            required: true,
            message: t('Choose type, please')
          }
        ]}>
        <Select
          onChange={onChangeSelectType}
          placeholder={t('Type')}
          options={offersTypes?.map((type) => ({
            value: type?.name,
            key: type,
            label: _.capitalize(t(type?.name))
          }))}
        />
      </Form.Item>

      {visible && (
        <>
          <Box display="flex" justifyContent="center">
            <Form.Item
              name="images"
              rules={[{ required: true, message: t('Please, upload images') }]}
              style={{ width: '100%' }}>
              <ImagesUploader onRemoveForResized={onRemoveForResized}>
                <UploadButton />
              </ImagesUploader>
            </Form.Item>
          </Box>
          <Form.Item
            label={t('Title')}
            name="title"
            rules={[
              { required: true, message: t('Enter offer title, please') },
              {
                max: 150,
                message: t('Name should be less than 150 character')
              }
            ]}>
            <Input placeholder={t('Title')} />
          </Form.Item>
          <Form.Item
            label={t('Description')}
            name="description"
            rules={[
              { required: true, message: t('Enter offer description, please') },
              {
                max: 2500,
                message: t('Description should be less than 2500 character')
              }
            ]}>
            <TextArea placeholder={t('Description')} rows={4} />
          </Form.Item>
          <Form.Item
            label={t('Location')}
            name="location"
            rules={[
              { required: true, message: t('Please enter location') },
              {
                max: 100,
                message: t('Location should be less than 100 character')
              }
            ]}>
            <Input placeholder={t('Enter location')} />
          </Form.Item>
          <Row negativeBlockMargin>
            <Col cw={[12, 12, 6]} display="block">
              <Form.Item
                label={t('Contact person email')}
                name="email"
                rules={[
                  { required: true, message: t('Please, enter contact email') },
                  { type: 'email', message: t('Please, enter valid email') }
                ]}
                style={{ width: '100%' }}>
                <Input placeholder={t('Enter contact person email')} />
              </Form.Item>
            </Col>
            <Col cw={[12, 12, 6]} display="block">
              <Form.Item
                label={t('Contact person phone')}
                name="phone"
                rules={[
                  { required: true, message: t('Please, enter contact phone') }
                ]}
                style={{ width: '100%' }}>
                <PhoneInput country="no" />
              </Form.Item>
            </Col>
          </Row>

          <Row negativeBlockMargin v="bottom">
            {type.fixedPrice && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed price')}
                  name="fixedPrice"
                  rules={[
                    {
                      required: true,
                      message: t('Enter fixed price, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    placeholder={t('Fixed price')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}

            {type.price && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Price')}
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: t('Enter price, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    placeholder={t('Price')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {type.fixedDiscount && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed discount')}
                  name="fixedDiscount"
                  rules={[
                    {
                      required: true,
                      message: t('Enter offer fixed discount, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    placeholder={t('Fixed discount')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {type.fixedDiscountPercentage && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed discount percentage')}
                  name="fixedDiscountPercentage"
                  rules={[
                    {
                      required: true,
                      message: t(
                        'Enter offer fixed discount percentage, please'
                      )
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder={t('Fixed discount percentage')}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                  />
                </Form.Item>
              </Col>
            )}
            {type.fixedPercentage && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed percentage')}
                  name="fixedPercentage"
                  rules={[
                    {
                      required: true,
                      message: t('Enter offer fixed  percentage, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    placeholder={t('Fixed percentage')}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                  />
                </Form.Item>
              </Col>
            )}
            {type.currency && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Currency')}
                  name="currency"
                  initialValue={CURRENCIES[0]}
                  rules={[
                    {
                      required: true,
                      message: t('Choose currency, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <Select
                    placeholder={t('Currency')}
                    options={CURRENCIES.map((currency) => ({
                      value: currency,
                      label: t(currency)
                    }))}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </>
      )}
      {!!Object?.keys(type)?.length && (
        <Button htmlType="submit" block type="primary" mt="auto">
          {edit ? t('Save changes') : t('Create offer')}
        </Button>
      )}
    </Form>
  )
}
OfferForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  form: PropTypes.object,
  edit: PropTypes.bool,
  offersTypes: PropTypes.array.isRequired,
  initialType: PropTypes.object,
  onRemoveForResized: PropTypes.array
}
export default OfferForm
