import { useState } from 'react'
import { message } from 'antd'
import {
  Container,
  Row,
  Col,
  Box,
  Title,
  Remove,
  PageWrapper,
  Button,
  Avatar,
  Text,
  Link
} from '@qonsoll/react-design'
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
  PhoneOutlined,
  MailOutlined
} from '@ant-design/icons'
import { PageLoading, Backdrop } from '~/app/components'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import {
  getCollectionRef,
  getDocumentData
} from '~/services/Firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import firebase from '~/services/Firebase/init'
import {
  useAdministratorsContext,
  useBuyersContext
} from '~/app/contexts/Cache/hooks'

function UserShow() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslations()
  const { state } = useSessionContext()
  const [userData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.USERS).doc(id)
  )
  const [locationData, loadingLocation] = useDocumentData(
    userData?.locationId &&
      getCollectionRef(COLLECTIONS.LOCATIONS).doc(userData.locationId)
  )
  const [buyingCaseTypeData, loadingBuyingCaseTypeData] = useDocumentData(
    userData?.buyingTypeId &&
      getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES).doc(userData.buyingTypeId)
  )
  const dispatchMap = {
    YHB: useBuyersContext(),
    ADMIN: useAdministratorsContext()
  }

  const { dispatch } =
    userData?.role !== undefined &&
    !loading &&
    userData?.role !== 'PARTNER' &&
    dispatchMap[userData?.role]

  // [COMPONENT_STATE_HOOKS]
  const [deleteUserLoading, setDeleteUserLoading] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)

  // [COMPUTED PROPERTIES]
  const isCurrentUser = state.id === id

  // [CLEAN_FUNCTIONS]
  const onEditClick = () => {
    history.push(generatePath(ROUTE_PATHS.USER_EDIT, { id }))
  }

  const onDeleteAccount = async () => {
    setDeleteUserLoading(true)
    const func = firebase
      .functions()
      .httpsCallable('deleteUser', { timeout: 0 })
    try {
      history.goBack()
      let userForDelete
      if (state.id === id) {
        userForDelete = state
      } else {
        userForDelete = await getDocumentData(COLLECTIONS.USERS, id)
      }
      await func({ userForDelete })
      message.success(
        `${t('Profile was successfully deleted from application')}.`
      )

      dispatch({ type: 'REMOVE_DOC', payload: id })
    } catch (e) {
      console.log(e)
      message.error(`${t('Error occurred during user deleting')}.`)
    }
    setDeleteUserLoading(false)
  }
  const onResetPassword = () => history.push(ROUTE_PATHS.RESET_PASSWORD)

  const onClickOnLink = () => setIsShowMore((prevStatus) => !prevStatus)

  return (
    <PageLoading
      loading={loading || loadingLocation || loadingBuyingCaseTypeData}>
      <PageWrapper onBack={goBack} divided={false} isBottomSticky>
        <Container
          height="inherit"
          display="flex"
          paddingTop="5%"
          style={{
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          {deleteUserLoading && <Backdrop spinnerText={t('Deleting')} />}

          <Row h="center">
            <Col cw={[12, 9, 8, 7]} flexDirection="column">
              <Box
                width="100%"
                margin="0 auto"
                display="flex"
                bg="var(--card-user-show-bg)"
                borderRadius="var(--ql-border-radius-12)"
                pb={2}
                mb={3}>
                <Row width="100%">
                  <Col cw={12} h="center" mb={2}>
                    <Avatar
                      style={{ margin: '0 auto', marginTop: '-60px' }}
                      src={userData?.avatarURL}
                      alt={t('User')}
                      icon={<UserOutlined />}
                      size={128}
                    />
                  </Col>
                  <Col cw={12} h="center" mb={3} minWidth={0}>
                    <Title
                      textAlign="center"
                      level={
                        3
                      }>{`${userData?.firstName} ${userData?.secondName}`}</Title>
                  </Col>
                  {userData?.role === 'YHB' && (
                    <Col cw={12} h="center" mb={2}>
                      <Text type="secondary">
                        {userData?.isVisible
                          ? t('Visible profile')
                          : t('Invisible profile')}
                      </Text>
                    </Col>
                  )}
                  {!isCurrentUser &&
                    (userData?.isVisible ||
                      userData?.role === 'ADMIN' ||
                      userData?.role === 'PARTNER') && (
                      <Col cw={12} h="center">
                        <Row h="center" mb={2}>
                          {!!userData?.phone?.length && (
                            <Col cw="auto">
                              <Button
                                href={`tel:${userData.phone}`}
                                icon={
                                  <PhoneOutlined
                                    style={{
                                      fontSize: 'var(--ql-font-size-h3)'
                                    }}
                                  />
                                }
                              />
                            </Col>
                          )}
                          <Col cw="auto">
                            <Button
                              href={`mailto:${userData?.email}`}
                              icon={
                                <MailOutlined
                                  style={{ fontSize: 'var(--ql-font-size-h3)' }}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                </Row>
              </Box>
              <Row h="center" mb={2}>
                <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                  <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                    {t('Email')}
                  </Text>
                  <Text isEllipsis fontWeight="var(--ql-font-weight-medium)">
                    {userData?.email}
                  </Text>
                </Col>
                <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                  <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                    {t('Phone')}
                  </Text>
                  <Text fontWeight="var(--ql-font-weight-medium)">
                    {userData?.phone}
                  </Text>
                </Col>
              </Row>
              {userData?.role === 'YHB' && (
                <Row mb={2}>
                  {locationData?.name && (
                    <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                      <Text
                        type="secondary"
                        fontSize="var(--ql-font-size-body2)">
                        {t('Location')}
                      </Text>
                      <Text
                        isEllipsis
                        fontWeight="var(--ql-font-weight-medium)">{`${
                        locationData?.name
                      }, ${t('Norway')}`}</Text>
                    </Col>
                  )}
                  {buyingCaseTypeData?.name && (
                    <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                      <Text
                        type="secondary"
                        fontSize="var(--ql-font-size-body2)">
                        {t('Buying case type')}
                      </Text>
                      <Text
                        isEllipsis
                        fontWeight="var(--ql-font-weight-medium)">
                        {buyingCaseTypeData?.name}
                      </Text>
                    </Col>
                  )}
                </Row>
              )}
              {userData?.aboutMe && (
                <Row h="center" mb={4} style={{ flexDirection: 'column' }}>
                  <Col cw="auto">
                    <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                      {t('About me')}
                    </Text>
                  </Col>
                  <Col cw="auto">
                    <Text
                      whiteSpace="pre-line"
                      fontWeight="var(--ql-font-weight-medium)">
                      {userData?.aboutMe?.length > 200 && !isShowMore
                        ? userData.aboutMe.slice(0, 200).concat('...')
                        : userData.aboutMe}
                      {userData?.aboutMe?.length > 200 && (
                        <Link onClick={onClickOnLink}>
                          {!isShowMore && t(' show more')}
                        </Link>
                      )}
                    </Text>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row noGutters>
            <Col cw={12} mb={2}>
              <Button icon={<EditOutlined />} block onClick={onEditClick}>
                {t('Edit profile')}
              </Button>
            </Col>
            <Col cw={12} mb={2}>
              <Button icon={<KeyOutlined />} block onClick={onResetPassword}>
                {t('Change password')}
              </Button>
            </Col>
            <Col cw={12}>
              <Remove
                type="primary"
                danger
                text={t('Delete account')}
                icon={<DeleteOutlined />}
                block
                onSubmit={onDeleteAccount}
                itemName={t('account')}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default UserShow
