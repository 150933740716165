import { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, message } from 'antd'
import { Row, Col } from '@qonsoll/react-design'
import db from 'services/Firebase/database'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { COLLECTIONS } from '~/app/constants'

const LocalizationItem = (props) => {
  // Props destructuring
  const { data, appName } = props

  // Hooks
  const { t } = useTranslations()

  // Data destructuring
  const { original, en, no } = data

  // States
  const [enLabel, setEnLabel] = useState(en)
  const [noLabel, setNoLabel] = useState(no)

  // Functions
  const save = (lang, value) => {
    const ref = `${COLLECTIONS.TRANSLATIONS}/${appName}/${lang}/${original}`
    db.ref(ref)
      .set(value)
      .then(() => {
        message.success(`"${value}" ${t('label was saved to the')} ${appName}`)
      })
  }

  // Template
  return (
    <Row mb={2} noOuterGutters>
      <Col>
        <Input
          value={enLabel}
          onChange={(e) => setEnLabel(e.target.value)}
          onPressEnter={() => save('en', enLabel)}
        />
      </Col>
      <Col>
        <Input
          value={noLabel}
          onChange={(e) => setNoLabel(e.target.value)}
          onPressEnter={() => save('no', noLabel)}
        />
      </Col>
    </Row>
  )
}

LocalizationItem.propTypes = {
  data: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired
}

export default LocalizationItem
