import { BuyersAll } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'BuyersAll',
    path: ROUTE_PATHS.BUYERS_ALL,
    exact: true,
    component: BuyersAll
  }
]
