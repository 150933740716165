import React from 'react'
import { Tabs } from 'antd'
import { PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { LocalizationForm } from '../../components'

const { TabPane } = Tabs

const TranslationEdit = () => {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  return (
    <PageWrapper
      headingProps={{
        title: t('Translations'),
        titleSize: titleSizes,
        textAlign: 'left',
        marginBottom: '24px'
      }}
      onBack={goBack}>
      <Tabs defaultActiveKey="b2g-admin-app">
        <TabPane tab={t('Admin app')} key="b2g-admin-app">
          <LocalizationForm appName="b2g-admin-app" />
        </TabPane>
        <TabPane tab={t('Partner app')} key="b2g-partner-app">
          <LocalizationForm appName="b2g-partner-app" />
        </TabPane>
        <TabPane tab={t('Buyer app')} key="b2g-buyer-app">
          <LocalizationForm appName="b2g-buyer-app" />
        </TabPane>
      </Tabs>
    </PageWrapper>
  )
}

export default TranslationEdit
