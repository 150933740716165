import { OfferCreate, OfferShow, OffersAll, OfferEdit } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'OfferCreate',
    path: ROUTE_PATHS.OFFER_CREATE,
    exact: true,
    component: OfferCreate
  },
  {
    name: 'OfferShow',
    path: ROUTE_PATHS.OFFER_SHOW,
    exact: true,
    component: OfferShow
  },
  {
    name: 'OffersAll',
    path: ROUTE_PATHS.OFFERS_ALL,
    exact: true,
    component: OffersAll
  },
  {
    name: 'OffersEdit',
    path: ROUTE_PATHS.OFFER_EDIT,
    exact: true,
    component: OfferEdit
  }
]
