import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Image, Progress, Skeleton } from 'antd'
import { Box, Col, Row, Card, Link, Text } from '@qonsoll/react-design'
import { DeleteOutlined, PictureOutlined } from '@ant-design/icons'
import { Remove } from 'app/components/Buttons'
import { storageRef } from '~/services/Firebase/storage'

const ImageSkeleton = () => (
  <Box
    height={50}
    width={50}
    display="flex"
    alignItems="center"
    justifyContent="center"
    bg="var(--dark-8)"
    borderRadius="var(--ql-border-radius-4)">
    <PictureOutlined />
  </Box>
)

const UploadItem = (props) => {
  const { file, fileIndex, setFiles, onRemove, isLastImage } = props

  // [COMPONENT_STATE_HOOKS]
  const [initLoading, setInitLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  // [USE_EFFECTS]
  useEffect(
    () => {
      let isComponentMount = true
      if (isComponentMount && file.init) {
        const fetchFile = async () => {
          setInitLoading(true)

          const ref = storageRef({ url: file.url })
          try {
            const refMetadata = await ref.getMetadata()
            setFiles((images) => {
              const newImages = { ...images }
              if (refMetadata.customMetadata?.fileName) {
                newImages[file.url] = {
                  ...newImages[file.url],
                  name: refMetadata.customMetadata?.fileName || ref.name,
                  init: false
                }
              }
              return newImages
            })
          } catch (error) {
            setFiles((images) => {
              const newImages = { ...images }
              delete newImages[file.url]
              return newImages
            })
          }

          setInitLoading(false)
        }
        fetchFile()
      }
      return () => {
        isComponentMount = false
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [file]
  )

  return (
    <Card
      style={{ cursor: 'pointer' }}
      mt={3}
      size="small"
      bordered={false}
      shadowless>
      <Row noOuterGutters h="center" v="center">
        <Col cw="auto" pr={0}>
          {initLoading || file.percent ? (
            <ImageSkeleton />
          ) : (
            <Image
              src={file.url}
              height={50}
              width={50}
              placeholder
              style={{
                borderRadius: 'var(--ql-border-radius-4)',
                objectFit: 'cover'
              }}
            />
          )}
        </Col>
        <Col minWidth={0}>
          {/*need fix text ellipsis on small device*/}
          <Skeleton
            title={false}
            loading={initLoading}
            paragraph={{ rows: 1, width: '100%', style: { margin: 0 } }}>
            <Text isEllipsis>
              <Link href={file.url} target="_blank">
                {file.name}
              </Link>
            </Text>
          </Skeleton>
        </Col>
        <Col cw="auto">
          {file.percent ? (
            <Progress type="circle" width={30} percent={file?.percent} />
          ) : (
            !isLastImage && (
              <Remove
                disabled={initLoading}
                loading={deleteLoading}
                icon={<DeleteOutlined />}
                type="text"
                shape="default"
                popconfirmPlacement="topRight"
                size="small"
                style={{ fontSize: 'var(--ql-font-size-body1)' }}
                onSubmit={async () => {
                  setDeleteLoading(true)
                  await onRemove(file, fileIndex)
                  setDeleteLoading(false)
                }}
              />
            )
          )}
        </Col>
      </Row>
    </Card>
  )
}

UploadItem.propTypes = {
  file: PropTypes.object.isRequired,
  setFiles: PropTypes.func.isRequired,
  onRemove: PropTypes.func
}

export default UploadItem
