import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Text } from '@qonsoll/react-design'
import { Switch, Form, Input } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'

const OPTIONS = [
  { label: 'Fixed price', itemName: 'fixedPrice' },
  { label: 'Fixed discount', itemName: 'fixedDiscount' },
  { label: 'Fixed discount %', itemName: 'fixedDiscountPercentage' },
  { label: 'Fixed percentage', itemName: 'fixedPercentage' },
  { label: 'Price', itemName: 'price' },
  { label: 'Currency', itemName: 'currency' }
]

function OfferTypeForm(props) {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}
      {...props}>
      <Box>
        <Form.Item
          label={t('Offer type name')}
          name="name"
          rules={[
            { required: true, message: t('Please, enter offer type name') },
            {
              max: 150,
              message: t('Name should be less than 150 character')
            }
          ]}>
          <Input placeholder={t('Name')} />
        </Form.Item>
      </Box>
      {OPTIONS.map((item) => (
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between">
          <Text>{t(item.label)}</Text>
          <Form.Item
            name={item.itemName}
            initialValue={props?.initialValues?.[item.itemName] || false}>
            <Switch
              defaultChecked={props?.initialValues?.[item.itemName] || false}
            />
          </Form.Item>
        </Box>
      ))}
      <Button block htmlType="submit" type="primary" mt="auto">
        {props.edit ? t('Save changes') : t('Create offer type')}
      </Button>
    </Form>
  )
}

OfferTypeForm.propTypes = {
  edit: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default OfferTypeForm
