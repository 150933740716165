import _ from 'lodash'

// dataWithNoneActiveUsers it's users which are in filed attendees in buying case
// dataWithActiveUsers it's users which are in system
const arrayWithNonActivatedUsers = (
  dataWithNoneActiveUsers,
  dataWithActiveUsers
) => {
  let arrayWitUsers = []
  let objWithEmails = {}

  // get all emails from users which are in system,and filed === user
  //example obj = {
  // test@gmail.com:{
  // email: "test@gmail.com"}}
  dataWithActiveUsers?.forEach((item) => {
    objWithEmails[item.email] = item
  })

  const objKeysArray = Object.keys(objWithEmails)

  // get difference between users which are in system and none activated users
  const difference = _.difference(dataWithNoneActiveUsers, objKeysArray)

  //create new array with none activated users
  //example arr = [ {email:test@gmail.com}, {email:test1@gmail.com}]
  const noneActivatedUsers = difference.map((item) => {
    return { email: item }
  })

  // push users into arrayWithUsers array if dataWithNoneActiveUsers include real user
  //example array = [
  //   // test3@gmail.com:{
  //   // email: "test3@gmail.com"}]
  dataWithActiveUsers?.forEach((item) => {
    const isInclude = dataWithNoneActiveUsers.includes(item.email)
    if (isInclude) return arrayWitUsers.push(item)
  })

  return [...arrayWitUsers, ...noneActivatedUsers]
}
export default arrayWithNonActivatedUsers
