import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Input, Button, Box } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function LocationForm(props) {
  const { edit, initialValues, onFinish } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}>
      <Box>
        <Form.Item
          label={t('Location name')}
          name="name"
          rules={[
            { required: true, message: t('Enter location name please') },
            {
              max: 100,
              message: t('Name should be less than 100 character')
            }
          ]}>
          <Input
            autoFocus
            placeholder={t('Enter location name')}
            autoComplete="off"
          />
        </Form.Item>
      </Box>
      <Button block htmlType="submit" type="primary" mt="auto">
        {edit ? t('Save changes') : t('Create location')}
      </Button>
    </Form>
  )
}

LocationForm.propTypes = {
  edit: PropTypes.bool,
  initialValues: PropTypes.array,
  onFinish: PropTypes.func.isRequired
}

export default LocationForm
