import PropTypes from 'prop-types'
import {
  Box,
  Col,
  Row,
  Avatar,
  Button,
  Card,
  Text
} from '@qonsoll/react-design'
import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Badge, List } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { STATUSES } from 'app/constants'

function WizardVotingInSameLocation(props) {
  const {
    usersData,
    votingDataInSameLocation,
    onCreateAgreement,
    onResetProgress,
    wizardProcessingStatus,
    onBuyerClick,
    agreementTemplate
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const isVotingEnded =
    votingDataInSameLocation &&
    votingDataInSameLocation?.voterAnswersInfo?.length &&
    votingDataInSameLocation?.voterAnswersInfo
      ?.map((item) => item.answer)
      ?.reduce((acc, curr) => acc && curr)
  return (
    <>
      <Row noGutters h="center">
        <Col cw={[12, 8]}>
          <Box
            display="flex"
            justifyContent="start"
            flex={1}
            alignItems="center"
            mt={2}
            mb={3}>
            <Text variant="h5">{t('Voting results')}</Text>
          </Box>
        </Col>
        <Col cw={[12, 8]}>
          <List
            style={{ flex: 1 }}
            dataSource={votingDataInSameLocation?.voterAnswersInfo}
            renderItem={(votedUser, index) => (
              <Card
                key={index}
                mb={3}
                onClick={() => onBuyerClick?.(votedUser?.id)}
                size="small"
                bordered={false}
                shadowless
                style={{ cursor: 'pointer' }}>
                <Row noGutters h="center" v="center">
                  <Col cw="auto" mr={3}>
                    <Box
                      height="fit-content"
                      border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
                      borderRadius="50%">
                      <Avatar
                        m="2px"
                        icon={<UserOutlined />}
                        src={
                          usersData?.find((user) => votedUser.id === user.id)
                            ?.avatarURL
                        }
                        alt="avatar"
                      />
                    </Box>
                  </Col>
                  <Col minWidth={0}>
                    <Text isEllipsis>{`${
                      usersData?.find((user) => votedUser.id === user.id)
                        ?.firstName
                    } ${
                      usersData?.find((user) => votedUser.id === user.id)
                        ?.secondName
                    }`}</Text>
                  </Col>
                  <Col cw="auto" ml={3}>
                    <Badge
                      count={
                        votedUser?.answer === null ? (
                          <QuestionOutlined />
                        ) : votedUser?.answer ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )
                      }
                      style={{
                        color:
                          typeof votedUser?.answer === 'boolean'
                            ? votedUser?.answer
                              ? '#52c41a'
                              : '#f5222d'
                            : ''
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            )}
          />
        </Col>
      </Row>
      {wizardProcessingStatus !== STATUSES.AGREEMENT_CREATED && (
        <Row noGutters mt={3} h="center">
          <Col cw={[12, 8]} mb={2}>
            <Button
              size="large"
              block
              type={'primary'}
              onClick={onCreateAgreement}
              disabled={!isVotingEnded || agreementTemplate?.length < 1}>
              {t('Create agreement')}
            </Button>
          </Col>
          <Col cw={[12, 8]} mb={3}>
            <Button block type="text" onClick={onResetProgress}>
              {t('Reset progress')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}
WizardVotingInSameLocation.propTypes = {
  onCreateAgreement: PropTypes.func.isRequired,
  onResetProgress: PropTypes.func.isRequired,
  votingDataInSameLocation: PropTypes.array.isRequired,
  wizardProcessingStatus: PropTypes.bool.isRequired,
  usersData: PropTypes.array.isRequired,
  onBuyerClick: PropTypes.func,
  agreementTemplate: PropTypes.array.isRequired
}
export default WizardVotingInSameLocation
