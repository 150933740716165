import PropTypes from 'prop-types'
import { Row, Col, Box, Card, Title, Text } from '@qonsoll/react-design'
import { Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function StatisticView(props) {
  const { title, icon, path, request } = props

  // [ADDITIONAL HOOKS]
  const [collectionData, loading] = useCollectionData(request)
  const history = useHistory()
  const { t } = useTranslations()

  // [CLEAN FUNCTIONS]
  const onItemClick = () => {
    history.push(path)
  }

  return (
    <Card bordered={false} onClick={onItemClick}>
      <Row noGutters v="center">
        <Col cw="auto" mr={3}>
          <Box fontSize="24px" lineHeight={1}>
            {icon}
          </Box>
        </Col>
        <Col>
          <Text>{t(title)}</Text>
        </Col>
        <Col cw="auto">
          <Title level={3}>
            {loading ? <Spin /> : collectionData?.length ?? 0}
          </Title>
        </Col>
      </Row>
    </Card>
  )
}

StatisticView.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired
  })
}

export default StatisticView
