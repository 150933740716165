import { useState, useEffect } from 'react'
import { Box, Col, PageWrapper, Row, Button } from '@qonsoll/react-design'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import {
  AgreementTemplateCollapsibleEditor,
  AgreementTemplatePreview
} from 'domains/AgreementTemplate/components'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useDocumentDataOnce } from '~/hooks/firestore'
import { getCollectionRef, updateDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import htmlToDraft from 'html-to-draftjs'
import parseStringToHtml from 'html-react-parser'
import { ContentState, EditorState } from 'draft-js'
import {
  convertContentToDraftHtml,
  convertStateToReactComponent
} from 'domains/AgreementTemplate/helpers'
import { message } from 'antd'
import { PageLoading } from 'app/components'

const TITLE_SIZE_CONFIG = {
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3
}

const BACK_BUTTON_VISIBILITY = {
  sm: false,
  md: true,
  lg: true,
  xl: true,
  xxl: true
}

function AgreementTemplateWithCustomBlocksEdit() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints(BACK_BUTTON_VISIBILITY)
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const titleSizes = useResponsiveBreakpoints(TITLE_SIZE_CONFIG)
  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslations()
  const [agreementTemplate, agreementTemplateLoading] = useDocumentDataOnce(
    getCollectionRef(COLLECTIONS.AGREEMENT_TEMPLATES).doc(id)
  )

  // [COMPONENT STATE HOOKS]
  const [questionData, setQuestionData] = useState([])
  const [template, setTemplate] = useState({})

  // [CLEAN FUNCTIONS]
  const onAnswerOptionChange = (
    questionField,
    id,
    title,
    answerOption,
    legalText
  ) => {
    if (legalText === '') return
    if (template?.[questionField]?.answers) {
      const checkIfThereIsChanges = template?.[questionField]?.answers?.find(
        (item) =>
          item.answerOption === answerOption && item.legalText === legalText
      )
      if (checkIfThereIsChanges) return

      const indexOfEditedItem = template?.[questionField]?.answers?.findIndex(
        (item) => item.answerOption === answerOption
      )
      const answers = [...template?.[questionField]?.answers]

      indexOfEditedItem === -1
        ? answers.push({ answerOption, legalText })
        : (answers[indexOfEditedItem] = { answerOption, legalText })

      setTemplate({
        ...template,
        [questionField]: {
          id,
          title,
          answers
        }
      })
    } else {
      setTemplate({
        ...template,
        [questionField]: {
          id,
          title,
          answers: [{ answerOption, legalText }]
        }
      })
    }
  }

  const onTextEditorChange = (editorState, field) => {
    const component = convertStateToReactComponent(editorState)
    setTemplate({ ...template, [field]: { component, state: editorState } })
  }

  const onTemplateCreate = async () => {
    try {
      const header =
        template?.header?.state &&
        convertContentToDraftHtml(template?.header?.state)
      const footer =
        template?.footer?.state &&
        convertContentToDraftHtml(template?.footer?.state)
      const questions = Object.entries(template)
        .filter((item) => item[0].includes('question'))
        .map((item) => item[1])
      await updateDocument(COLLECTIONS.AGREEMENT_TEMPLATES, id, {
        header,
        footer,
        questions
      })
      message.success(t('Agreement template was successfully updated'))
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during agreement template update'))
    }
    history.goBack()
  }

  // [USE EFFECTS]
  useEffect(() => {
    if (agreementTemplate) {
      let Header = htmlToDraft(agreementTemplate?.header || '')
      Header =
        Header &&
        ContentState.createFromBlockArray(
          Header?.contentBlocks,
          Header?.entityMap
        )
      let Footer = htmlToDraft(agreementTemplate?.footer || '')
      Footer =
        Footer &&
        ContentState.createFromBlockArray(
          Footer?.contentBlocks,
          Footer?.entityMap
        )
      const questionsPart = {}
      agreementTemplate?.questions?.forEach((item, ind) => {
        questionsPart[`question${ind}`] = item
      })
      setQuestionData(agreementTemplate?.questions)
      setTemplate({
        ...questionsPart,
        header: {
          component: parseStringToHtml(agreementTemplate?.header || ''),
          state: EditorState.createWithContent(Header)
        },
        footer: {
          component: parseStringToHtml(agreementTemplate?.footer || ''),
          state: EditorState.createWithContent(Footer)
        }
      })
    }
  }, [agreementTemplate])

  return (
    <PageLoading loading={agreementTemplateLoading}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: `${t('Edit')} ${
            history.location.state?.buyingTypeName ?? ''
          } ${t('agreement template')}`,
          titleSize: titleSizes,
          marginBottom: 24
        }}
        onBack={goBack}>
        <Row noGutters mb="24px">
          <Col mr={3}>
            <AgreementTemplateCollapsibleEditor
              formState={template}
              questionData={questionData}
              onAnswerOptionChange={onAnswerOptionChange}
              onTextEditorChange={onTextEditorChange}
            />
          </Col>
          <Col>
            <AgreementTemplatePreview
              header={template?.header?.component}
              footer={template?.footer?.component}
            />
          </Col>
        </Row>
        <Box>
          <Button block type="primary" onClick={onTemplateCreate}>
            {t('Edit')}
          </Button>
        </Box>
      </PageWrapper>
    </PageLoading>
  )
}

export default AgreementTemplateWithCustomBlocksEdit
