import React, { useEffect, useState } from 'react'
import { Col, Row, Avatar, PageWrapper, Text } from '@qonsoll/react-design'
import { PageLoading } from '~/app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useParams } from 'react-router-dom'
import {
  useDocumentData,
  useCollectionData,
  useDocumentOnce
} from 'react-firebase-hooks/firestore'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'
import { WizardAnswerList } from 'domains/Wizard/components'
import { UserOutlined } from '@ant-design/icons'

function BuyingCaseShowBuyerAnswers() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const { id, buyerId } = useParams()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [buyingCaseData, buyingCaseDataLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [buyerData, buyerDataLoading] = useDocumentOnce(
    getCollectionRef(COLLECTIONS.USERS).doc(buyerId)
  )
  const [wizardProcessing, loadingWizardData] = useDocumentData(
    getCollectionRef(`${COLLECTIONS.WIZARD_PROCESSING}`).doc(id)
  )
  const [form, formLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.FORMS).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )
  const [questions, loadingQuestions] = useCollectionData(
    form?.[0]?.id &&
      getCollectionRef(COLLECTIONS.QUESTIONS).where(
        'formId',
        '==',
        form?.[0]?.id
      )
  )

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([])

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true

    const getQuestionsAndAnswers = () => {
      setLoading(true)
      let questionsText = {}
      questions?.forEach((question) => {
        questionsText[question?.id] = question?.title
      })

      const answersText = wizardProcessing?.answers?.[buyerId]

      let resultArray = []
      for (let qa in answersText)
        resultArray.push({
          answer: answersText?.[qa].value,
          question: questionsText?.[qa]
        })

      setQuestionsAndAnswers(resultArray)
      setLoading(false)
    }

    isComponentMount &&
      !loadingWizardData &&
      !loadingQuestions &&
      getQuestionsAndAnswers()

    return () => {
      isComponentMount = false
    }
  }, [
    loadingWizardData,
    wizardProcessing,
    questions,
    loadingQuestions,
    buyerId
  ])

  return (
    <PageLoading
      loading={
        loadingWizardData ||
        loading ||
        buyingCaseDataLoading ||
        formLoading ||
        buyerDataLoading
      }
      height="inherit">
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: (
            <Row noGutters>
              <Col cw={'auto'} mr={3}>
                <Avatar
                  fontSize="16px !important"
                  icon={<UserOutlined />}
                  src={buyerData?.data()?.avatarURL}
                  display="inline-block"
                />
              </Col>
              <Col cw={'auto'}>
                <Text isEllipsis>{`${buyerData?.data()?.firstName} ${
                  buyerData?.data()?.secondName
                } ${t('answers')}`}</Text>
              </Col>
            </Row>
          ),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <WizardAnswerList data={questionsAndAnswers} />
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseShowBuyerAnswers
