import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useUI from './useUI'
import ROUTE_PATHS from 'domains/allRoutePath'

/**
 * @info useHomeButton (28 Apr 2021) // CREATION DATE
 *
 * @comment useHomeButton - hook that add back button in header.
 *
 * @since 28 Apr 2021 ( v.0.0.1 ) // LAST-EDIT DATE
 *
 * @param {Object}                 [options]
 * @param {function(History)}      [options.callback]      Additional function that can be run on button click.
 * @param {boolean}                [options.goHome]        Should go back on button click.
 *
 * @return {void}
 */

const useHomeButton = (options = {}) => {
  const { callback, goHome = true } = options
  const { dispatch } = useUI()

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [USE_EFFECTS]
  useEffect(() => {
    const onBack = () => {
      callback?.(history)
      goHome && history.push(ROUTE_PATHS.DASHBOARD)
    }
    dispatch({ type: 'SET_WITH_HOME_BUTTON', payload: onBack })

    return () => {
      dispatch({ type: 'SET_WITH_HOME_BUTTON', payload: false })
    }
  }, [callback, dispatch, goHome, history])
}

export default useHomeButton
