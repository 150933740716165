import React from 'react'
import PropTypes from 'prop-types'
import { Form, Switch } from 'antd'
import {
  Row,
  Col,
  Input,
  Button,
  PhoneInput,
  TextArea,
  Text,
  Box
} from '@qonsoll/react-design'
import { AvatarUploader } from 'app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { LocationSelect } from 'domains/Location/components'
import { BuyingCaseTypeSelect } from 'domains/BuyingCaseType/components'

function UserForm(props) {
  const {
    initialValues,
    setFormLoading,
    loading,
    locationsData,
    buyingCaseTypes,
    form,
    diffImage,
    setDiffImage,
    ...rest
  } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [CLEAN_FUNCTIONS]
  const onKeyPress = (event) => {
    if (event.code === 'Enter' && !event.shiftKey && !loading) form.submit()
  }
  const onValuesChange = (data) => {
    if ('avatarURL' in data) {
      setDiffImage([...diffImage, data?.avatarURL])
    }
  }

  return (
    <Form
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}
      form={form}
      {...rest}>
      <Form.Item name="avatarURL" style={{ marginBottom: -24 }}>
        <AvatarUploader
          shape="user"
          imageUrl={initialValues?.avatarURL}
          size="large"
          setLoading={setFormLoading}
        />
      </Form.Item>
      {initialValues?.role === 'YHB' && (
        <Row negativeBlockMargin>
          <Col
            cw={12}
            style={{ alignItems: 'baseline' }}
            v="center"
            flexDirection="row">
            <Text style={{ marginRight: '12px' }}> {t('Visible profile')}</Text>
            <Form.Item name="isVisible" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col cw={[12, 6, 12, 6]} ml="auto" display="block">
            <Form.Item
              label={t('Location')}
              name="locationId"
              style={{
                width: '100%'
              }}>
              <LocationSelect
                locations={locationsData}
                style={{ whiteSpace: 'none' }}
              />
            </Form.Item>
          </Col>
          <Col cw={[12, 6, 12, 6]} ml="auto" display="block">
            <Form.Item
              label={t('Buying type')}
              name="buyingTypeId"
              style={{ width: '100%' }}
              rules={[
                { required: true, message: t('Please, select buying type') }
              ]}>
              <BuyingCaseTypeSelect buyingTypes={buyingCaseTypes} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Box>
        <Form.Item
          label={t('First name')}
          name="firstName"
          rules={[
            { required: true, message: t('Please, input first name') },
            {
              max: 75,
              message: t('First name should be less than 75 character')
            }
          ]}>
          <Input autoFocus placeholder={t('First name')} />
        </Form.Item>
        <Form.Item
          label={t('Last name')}
          name="secondName"
          rules={[
            { required: true, message: t('Please, input last name') },
            {
              max: 75,
              message: t('Second name should be less than 75 character')
            }
          ]}>
          <Input placeholder={t('Last name')} />
        </Form.Item>
        <Form.Item
          label={t('Phone')}
          name="phone"
          rules={[
            { required: true, message: t('Please, input phone number') }
          ]}>
          <PhoneInput country="no" />
        </Form.Item>
        {initialValues?.role === 'YHB' && (
          <>
            <Form.Item
              label={t('About me')}
              name="aboutMe"
              rules={[
                {
                  max: 2500,
                  message: 'Description should be less than 2500 character'
                }
              ]}>
              <TextArea
                placeholder={t('About me')}
                rows={4}
                onKeyPress={onKeyPress}
              />
            </Form.Item>
          </>
        )}
      </Box>
      <Button
        type="primary"
        htmlType="submit"
        block
        mt="auto"
        loading={loading}>
        {t('Save changes')}
      </Button>
    </Form>
  )
}

UserForm.propTypes = {
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  locationsData: PropTypes.array.isRequired,
  buyingCaseTypes: PropTypes.array.isRequired
}

export default UserForm
