import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import { Box, Button } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function BuyingCaseTypeForm(props) {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}
      {...props}>
      <Box>
        <Form.Item
          label={t('Buying case type')}
          name="name"
          rules={[
            {
              required: true,
              message: t('Please, enter buying case type name')
            },
            {
              max: 100,
              message: t('Name should be less than 100 character')
            }
          ]}>
          <Input placeholder={t('Name')} />
        </Form.Item>
      </Box>
      <Button block htmlType="submit" type="primary" mt="auto">
        {props.edit ? t('Save changes') : t('Create buying case type')}
      </Button>
    </Form>
  )
}

BuyingCaseTypeForm.propTypes = {
  edit: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default BuyingCaseTypeForm
